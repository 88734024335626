import { createAsyncThunk } from '@reduxjs/toolkit';
import { RegionsApi } from '../http';
import { IRegionsQuery } from '../models';

const regionsApi = new RegionsApi();

export const fetchRegionsCreate = createAsyncThunk(
  'regions/create',
  async (body: IRegionsQuery, { rejectWithValue }) => {
    try {
      return await regionsApi.createOne(body);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

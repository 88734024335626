import { createAsyncThunk } from '@reduxjs/toolkit';
import { AuthApi } from '../http';

const authApi = new AuthApi();

export const fetchMe = createAsyncThunk('auth/fetchMe', async (_, { rejectWithValue }) => {
  try {
    return await authApi.me();
  } catch (error) {
    return rejectWithValue(error);
  }
});

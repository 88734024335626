import { createAsyncThunk } from '@reduxjs/toolkit';
import { OpenInfoApi } from '../http';
import { IOpenInfo } from '../models';

const openInfoApi = new OpenInfoApi();

export const fetchOpenInfoUpdate = createAsyncThunk(
  'openInfo/update',
  async (body: IOpenInfo, { rejectWithValue }) => {
    try {
      return await openInfoApi.updateOne(body);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

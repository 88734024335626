import { FC, PropsWithChildren, useState } from 'react';
import { SnackbarContext } from '../contexts';
import { Alert, AlertProps, Box, Snackbar } from '@mui/material';

export const SnackbarProvider: FC<PropsWithChildren<any>> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState<string>('');
  const [alertProps, setAlertProps] = useState<Record<string, any>>({});

  const showMessage = (message: string, alertProps: AlertProps) => {
    setMessage(message);
    setAlertProps(alertProps);
    setIsOpen(true);
  };

  const handleClose = () => setIsOpen(false);

  return (
    <SnackbarContext.Provider value={{ showMessage }}>
      {children}
      <Snackbar
        open={isOpen}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={handleClose} {...alertProps}>
          <Box dangerouslySetInnerHTML={{ __html: message }} />
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  );
};

import { createSlice } from '@reduxjs/toolkit';
import { IReportByRegion, IReportByStatus, IReportByYear } from '../models';
import { forceFetchReportByRegion, fetchReportByStatus, forceFetchReportByYear } from '../thunks';

interface ReportInterface {
  byYear: {
    isLoading: boolean;
    result: IReportByYear[];
    error: any;
  };
  byRegion: {
    isLoading: boolean;
    result: IReportByRegion[];
    error: any;
  };
  byStatus: {
    isLoading: boolean;
    result: IReportByStatus | null;
    error: any;
  };
}

const initialState: ReportInterface = {
  byYear: {
    isLoading: false,
    result: [],
    error: null,
  },
  byRegion: {
    isLoading: false,
    result: [],
    error: null,
  },
  byStatus: {
    isLoading: false,
    result: null,
    error: null,
  },
};

export const reportSlice = createSlice({
  name: 'report',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(forceFetchReportByYear.pending, (state) => {
      state.byYear = {
        ...state.byYear,
        isLoading: true,
        result: [],
        error: null,
      };
    });
    builder.addCase(forceFetchReportByYear.fulfilled, (state, { payload }) => {
      state.byYear = {
        ...state.byYear,
        isLoading: false,
        result: payload.data,
        error: null,
      };
    });
    builder.addCase(forceFetchReportByYear.rejected, (state, { payload }) => {
      state.byYear = {
        ...state.byYear,
        isLoading: false,
        result: [],
        error: payload,
      };
    });
    builder.addCase(forceFetchReportByRegion.pending, (state) => {
      state.byRegion = {
        ...state.byRegion,
        isLoading: true,
        result: [],
        error: null,
      };
    });
    builder.addCase(forceFetchReportByRegion.fulfilled, (state, { payload }) => {
      state.byRegion = {
        ...state.byRegion,
        isLoading: false,
        result: payload.data,
        error: null,
      };
    });
    builder.addCase(forceFetchReportByRegion.rejected, (state, { payload }) => {
      state.byRegion = {
        ...state.byRegion,
        isLoading: false,
        result: [],
        error: payload,
      };
    });
    builder.addCase(fetchReportByStatus.pending, (state) => {
      state.byStatus = {
        ...state.byStatus,
        isLoading: true,
        result: null,
        error: null,
      };
    });
    builder.addCase(fetchReportByStatus.fulfilled, (state, { payload }) => {
      state.byStatus = {
        ...state.byStatus,
        isLoading: false,
        result: payload.data,
        error: null,
      };
    });
    builder.addCase(fetchReportByStatus.rejected, (state, { payload }) => {
      state.byStatus = {
        ...state.byStatus,
        isLoading: false,
        result: null,
        error: payload,
      };
    });
  },
});

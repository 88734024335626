import { AsyncThunk } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';
import { ChangeEvent, useEffect, useMemo } from 'react';
import { get } from '../../../common/utils';
import { useSnackbar } from '../../../common/hooks';
import { DEFAULT_LIMIT } from '../../../common/constants';
import { useAppDispatch, useAppSelector } from '../../../store';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

interface UseEntityListProps<T> {
  entity: string;
  stateKey?: string;
  thunk: AsyncThunk<any, any, any>;
  dependencies?: any[];
  numberFields?: string[];
  arrayFields?: string[];
  defaultParams?: T;
}

export const useEntityList = <T>({
  entity,
  thunk,
  stateKey = 'catalog',
  dependencies = [],
  numberFields = [],
  arrayFields = [],
  defaultParams = {} as any,
}: UseEntityListProps<T>) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { showMessage } = useSnackbar();
  const [searchParams] = useSearchParams();
  const { pathname, search } = useLocation();
  const state = useAppSelector((state) => get(state, entity, {}));

  const catalog = get(state, stateKey, {});
  const data = get(catalog, 'result.data', []);
  const total = get(catalog, 'result.total', 0);
  const isLoading = get(catalog, 'isLoading', false);
  const error = get(catalog, 'error', null);

  const defaultLimit = Number(get(defaultParams, 'limit', DEFAULT_LIMIT));
  const defaultPage = Number(get(defaultParams, 'page', 1));

  const filterParams = useMemo(() => Object.fromEntries(searchParams), [searchParams]);

  const limit = Number(get(filterParams, 'limit', defaultLimit));
  const page = Number(get(filterParams, 'page', defaultPage));
  const pagesCount = Math.ceil(total / limit);

  const handleFilterValueChange = (name: string, value: any) => {
    const newParams = { ...filterParams, [name]: value };
    navigate({ pathname, search: new URLSearchParams(newParams).toString() });
  };

  const handleChangePage = (e: ChangeEvent<any> | null, newPage: number) => {
    handleFilterValueChange('page', newPage);
  };

  const handlePayload = (params: Record<string, any>) => {
    const newParams = { ...params };

    return Object.fromEntries(
      Object.entries(newParams)
        .filter(([, value]) => !!value)
        .map(([key, value]) => {
          let newValue = value;
          if (value === 'true') newValue = true;
          else if (value === 'false') newValue = false;
          if (numberFields.includes(key)) newValue = Number(value);
          //else newValue = parseStringToNumber(value);

          if (arrayFields.includes(key)) {
            if (value.includes(',') && Array.isArray(value.split(','))) newValue = value.split(',');
            else newValue = [value];
          }

          return [key, newValue];
        }),
    );
  };

  useEffect(() => {
    if (!isLoading) {
      const payload = handlePayload(filterParams);
      dispatch(thunk({ ...defaultParams, ...payload, limit, page }))
        .unwrap()
        .catch((error: any) => {
          const code = get(error, 'code');
          const text = get(error, 'message');
          const message = code || text || 'error';
          showMessage(t(message), { variant: 'filled', severity: 'error' });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, ...dependencies]);

  return {
    catalog,
    data,
    total,
    isLoading,
    error,
    filterParams,
    limit,
    page,
    pagesCount,
    handleChangePage,
  };
};

import { createAsyncThunk } from '@reduxjs/toolkit';
import { IReportByYearQuery } from '../models';
import { ReportApi } from '../http';
import { get } from '../../../common/utils';

const reportApi = new ReportApi();

export const forceFetchReportByYear = createAsyncThunk(
  'report/byYear',
  async (body: IReportByYearQuery, { rejectWithValue }) => {
    try {
      const data = await reportApi.byYear(body);
      return { data };
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const fetchReportByYear = createAsyncThunk(
  'report/forceFetchByYear',
  async (body: IReportByYearQuery, { dispatch, getState }) => {
    const { result, isLoading } = get(getState(), 'report.byYear', {});
    if (result.length > 0 || isLoading) return;
    return await dispatch(forceFetchReportByYear(body));
  },
);

import { createAsyncThunk } from '@reduxjs/toolkit';
import { ManagementApi } from '../http';
import { IManagement } from '../models';

const managementApi = new ManagementApi();

export const fetchManagementRecord = createAsyncThunk(
  'management/record',
  async (body: { id: IManagement['id'] }, { rejectWithValue }) => {
    try {
      return await managementApi.findOne(body);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

import { createAsyncThunk } from '@reduxjs/toolkit';
import { NewsApi } from '../http';
import { INews } from '../models';

const newsApi = new NewsApi();

export const fetchNewsUpdate = createAsyncThunk(
  'news/update',
  async (body: INews, { rejectWithValue }) => {
    try {
      return await newsApi.updateOne(body);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

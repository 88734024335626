import { createAsyncThunk } from '@reduxjs/toolkit';
import { UserApi } from '../http';
import { IUser } from '../models';

const userApi = new UserApi();

export const fetchUserCreate = createAsyncThunk(
  'user/create',
  async (body: IUser, { rejectWithValue }) => {
    try {
      return await userApi.createOne(body);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

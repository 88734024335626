import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Stack, Card, CardContent, Grid, CardHeader } from '@mui/material';
import { useEntityForm } from '../../../entities/hooks';
import { PhoneFieldController, TextFieldController } from '../../../../components/inputs';
import {
  EMAIL_FIELD_FULL_NAME,
  EMAIL_FIELD_EMAIL,
  EMAIL_FIELD_PHONE,
  EMAIL_FIELD_PASSWORD,
  EMAIL_FIELD_PASSWORD_CONFIRM,
} from '../../constants';
import { IconClearAll, IconSave } from '../../../../common/icons';
import { IForm } from '../../../../common/models';
import { EmailSchema } from '../../schema';
import { IEmail } from '../../models';

export const EmailForms: FC<IForm<IEmail>> = ({ defaultValues, onValid, isLoading }) => {
  const { t } = useTranslation();
  const { control, onReset, onSubmit } = useEntityForm<IEmail>({
    schema: EmailSchema,
    defaultValues,
    onValid,
  });

  return (
    <Box onSubmit={onSubmit} component="form">
      <Stack direction="row" justifyContent="flex-end" spacing={1.5} mb={2.5}>
        <Button
          type="reset"
          variant="contained"
          color="secondary"
          startIcon={<IconClearAll />}
          disabled={isLoading}
          onClick={onReset}
        >
          {t('action.clear')}
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          startIcon={<IconSave />}
          disabled={isLoading}
        >
          {t('action.save')}
        </Button>
      </Stack>
      <Card>
        <CardHeader title={t('email.form.title')} />
        <CardContent>
          <Grid container>
            <Grid item xs={12} md={6} lg={4}>
              <Stack spacing={2.5}>
                <TextFieldController
                  control={control}
                  name={EMAIL_FIELD_FULL_NAME}
                  disabled={isLoading}
                  label={t('email.field.full.name')}
                  fullWidth
                />
                <TextFieldController
                  control={control}
                  name={EMAIL_FIELD_EMAIL}
                  disabled={isLoading}
                  label={t('email.field.email')}
                  type="email"
                  fullWidth
                />

                <PhoneFieldController
                  control={control}
                  name={EMAIL_FIELD_PHONE}
                  label={t('email.field.tel')}
                  fullWidth
                />
                <TextFieldController
                  control={control}
                  name={EMAIL_FIELD_PASSWORD}
                  disabled={isLoading}
                  label={t('users.field.password')}
                  type="password"
                  fullWidth
                />
                <TextFieldController
                  control={control}
                  name={EMAIL_FIELD_PASSWORD_CONFIRM}
                  disabled={isLoading}
                  label={t('users.field.password.confirm')}
                  type="password"
                  fullWidth
                />
              </Stack>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

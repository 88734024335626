import { FC, PropsWithChildren, useEffect, useState } from 'react';
import { DataLoader } from '../data';

interface PreloaderWrapperProps {
  isLoading?: boolean;
}

export const PreloaderWrapper: FC<PropsWithChildren<PreloaderWrapperProps>> = ({
  isLoading,
  children,
}) => {
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsReady(true);
    }, 300);
  }, []);

  if (!isReady || isLoading) {
    return <DataLoader display="flex" flex={2} alignItems="center" justifyContent="center" />;
  }

  return <>{children}</>;
};

import { createAsyncThunk } from '@reduxjs/toolkit';
import { RegionalAdministrationsApi } from '../http';
import { IRegionalAdministrationsQuery } from '../models';

const regionalAdministrationsApi = new RegionalAdministrationsApi();

export const fetchRegionalAdministrationsCreate = createAsyncThunk(
  'regionalAdministrations/create',
  async (body: IRegionalAdministrationsQuery, { rejectWithValue }) => {
    try {
      return await regionalAdministrationsApi.createOne(body);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

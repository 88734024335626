import { createAsyncThunk } from '@reduxjs/toolkit';
import { OpenInfoApi } from '../http';
import { IOpenInfo } from '../models';

const openInfoApi = new OpenInfoApi();

export const fetchOpenInfoRecord = createAsyncThunk(
  'openInfo/record',
  async (body: { id: IOpenInfo['id'] }, { rejectWithValue }) => {
    try {
      return await openInfoApi.findOne(body);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

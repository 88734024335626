import { createAsyncThunk } from '@reduxjs/toolkit';
import { ManagementApi } from '../http';
import { IManagementQuery } from '../models';

const managementApi = new ManagementApi();

export const fetchManagementCreate = createAsyncThunk(
  'management/create',
  async (body: IManagementQuery, { rejectWithValue }) => {
    try {
      return await managementApi.createOne(body);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

import { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useEntityCreate } from '../../../../features/entities/hooks';
import { NewsForms, fetchNewsCreate, INews, NEWS_INITIAL_VALUES } from '../../../../features/news';
import { CabinetContainer } from '../../../../components/cabinet-container';
import { ROUTE_NEWS } from '../../../../common/constants';
import { PageTitle } from '../../../../components/page-title';

export const NewsCreatePage: FC = () => {
  const { t } = useTranslation();
  const { isLoading, handleValid } = useEntityCreate<INews>({
    entity: 'news',
    thunk: fetchNewsCreate,
    nextPath: ROUTE_NEWS,
  });

  return (
    <>
      <Helmet>
        <title>{t('news.create')}</title>
      </Helmet>
      <CabinetContainer>
        <PageTitle title={t('news.create.title')} mb={3} />
        <NewsForms
          onValid={handleValid}
          isLoading={isLoading}
          defaultValues={NEWS_INITIAL_VALUES}
        />
      </CabinetContainer>
    </>
  );
};

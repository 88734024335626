import { RegionsActionDelete, RegionsActionEdit, RegionsActionView } from '../components';
import { Localize } from '../../../components/localize';
import { IColumn } from '../../../common/models';
import { IRegions } from '../models';
import { REGIONS_CODE, REGIONS_NAME } from '../constants';
import { Typography } from '@mui/material';

export const REGIONS_COLUMNS: IColumn[] = [
  {
    dataKey: REGIONS_NAME,
    label: 'regions.title',
    renderItem: ({ name_json }: IRegions) => <Localize data={name_json} />,
  },
  {
    dataKey: REGIONS_CODE,
    label: 'regions.code',
    renderItem: ({ code }: IRegions) => (
      <Typography component="div" variant="body2">
        {code ? code : '-'}
      </Typography>
    ),
  },
  {
    dataKey: '',
    label: 'action.edit',
    width: '1%',
    renderItem: ({ id }: IRegions) => <RegionsActionEdit id={id} />,
  },
  {
    dataKey: '',
    label: 'action.delete',
    width: '1%',
    renderItem: ({ id }: IRegions) => <RegionsActionDelete id={id} />,
  },
  {
    dataKey: '',
    label: 'action.view',
    width: '1%',
    renderItem: (data: IRegions) => <RegionsActionView data={data} />,
  },
];

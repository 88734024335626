import { createSlice } from '@reduxjs/toolkit';
import { IEmail } from '../models';
import { IData, ISlice } from '../../../common/models';
import {
  fetchEmailAll,
  fetchEmailCatalog,
  fetchEmailCreate,
  fetchEmailRecord,
  fetchEmailUpdate,
} from '../thunks';

interface ISliceWithAll extends ISlice<IData<IEmail[]>, IEmail> {
  all: {
    isLoading: boolean;
    result: IData<IEmail[]> | null;
    error: any;
  };
}

const initialState: ISliceWithAll = {
  catalog: {
    isLoading: false,
    result: null,
    error: null,
  },
  record: {
    isLoading: false,
    result: null,
    error: null,
  },
  create: {
    isLoading: false,
    error: null,
  },
  update: {
    isLoading: false,
    error: null,
  },
  all: {
    isLoading: false,
    result: null,
    error: null,
  },
};

export const emailSlice = createSlice({
  name: 'email',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchEmailCatalog.pending, (state) => {
      state.catalog = {
        ...state.catalog,
        isLoading: true,
        result: null,
        error: null,
      };
    });
    builder.addCase(fetchEmailCatalog.fulfilled, (state, { payload }) => {
      state.catalog = {
        ...state.catalog,
        isLoading: false,
        result: payload,
        error: null,
      };
    });
    builder.addCase(fetchEmailCatalog.rejected, (state, { payload }) => {
      state.catalog = {
        ...state.catalog,
        isLoading: false,
        result: null,
        error: payload,
      };
    });
    builder.addCase(fetchEmailRecord.pending, (state) => {
      state.record = {
        isLoading: true,
        result: null,
        error: null,
      };
    });
    builder.addCase(fetchEmailRecord.fulfilled, (state, { payload }) => {
      state.record = {
        ...state.record,
        isLoading: false,
        result: payload,
        error: null,
      };
    });
    builder.addCase(fetchEmailRecord.rejected, (state, { payload }) => {
      state.record = {
        ...state.record,
        isLoading: false,
        result: null,
        error: payload,
      };
    });
    builder.addCase(fetchEmailCreate.pending, (state) => {
      state.create = {
        ...state.create,
        isLoading: true,
        error: null,
      };
    });
    builder.addCase(fetchEmailCreate.fulfilled, (state) => {
      state.create = {
        ...state.create,
        isLoading: false,
        error: null,
      };
    });
    builder.addCase(fetchEmailCreate.rejected, (state, { payload }) => {
      state.create = {
        ...state.create,
        isLoading: false,
        error: payload,
      };
    });
    builder.addCase(fetchEmailUpdate.pending, (state) => {
      state.update = {
        ...state.update,
        isLoading: true,
        error: null,
      };
    });
    builder.addCase(fetchEmailUpdate.fulfilled, (state) => {
      state.update = {
        ...state.update,
        isLoading: false,
        error: null,
      };
    });
    builder.addCase(fetchEmailUpdate.rejected, (state, { payload }) => {
      state.update = {
        ...state.update,
        isLoading: false,
        error: payload,
      };
    });
    builder.addCase(fetchEmailAll.pending, (state) => {
      state.all = {
        ...state.all,
        isLoading: true,
        result: null,
        error: null,
      };
    });
    builder.addCase(fetchEmailAll.fulfilled, (state, { payload }) => {
      state.all = {
        ...state.all,
        isLoading: false,
        result: payload,
        error: null,
      };
    });
    builder.addCase(fetchEmailAll.rejected, (state, { payload }) => {
      state.all = {
        ...state.all,
        isLoading: false,
        result: null,
        error: payload,
      };
    });
  },
});

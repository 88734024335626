import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IEmail } from '../../models';
import { EmailPreview } from '../email-preview';
import { ActionButton } from '../../../../components/action-button';
import { DialogPreview } from '../../../../components/dialogs';
import { useDialog } from '../../../../common/hooks';
import { IconEye } from '../../../../common/icons';

interface EmailActionViewProps {
  data: IEmail;
}
export const EmailActionView: FC<EmailActionViewProps> = ({ data }) => {
  const { t } = useTranslation();
  const [openPreview] = useDialog(DialogPreview);

  const handlePreview = () => {
    openPreview({
      title: t('email.form.title'),
      component: <EmailPreview data={data} />,
    });
  };

  return <ActionButton onClick={handlePreview} title={t('action.view')} icon={<IconEye />} />;
};

import { createAsyncThunk } from '@reduxjs/toolkit';
import { UserApi } from '../http';
import { IUserQuery } from '../models';

const userApi = new UserApi();

export const fetchUserAll = createAsyncThunk(
  'user/all',
  async (body: IUserQuery, { rejectWithValue }) => {
    try {
      return await userApi.findAll(body);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

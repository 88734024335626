import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { IUser } from '../../models';
import {
  USER_FIELD_FIRST_NAME,
  USER_FIELD_LAST_NAME,
  USER_FIELD_MIDDLE_NAME,
  USER_FIELD_USER_NAME,
} from '../../constants';
import { RecordRow } from '../../../../components/record-row';
import { get } from '../../../../common/utils';

interface UsersPreviewProps {
  data: IUser;
}

export const UsersPreview: FC<UsersPreviewProps> = ({ data }) => {
  const { t } = useTranslation();

  return (
    <Stack spacing={1.5}>
      <RecordRow label={t('users.field.first.name')} value={get(data, USER_FIELD_FIRST_NAME)} />
      <RecordRow label={t('users.field.last.name')} value={get(data, USER_FIELD_LAST_NAME)} />
      <RecordRow label={t('users.field.middle.name')} value={get(data, USER_FIELD_MIDDLE_NAME)} />
      <RecordRow label={t('users.field.username')} value={get(data, USER_FIELD_USER_NAME)} />
    </Stack>
  );
};

import { ComponentType, createElement } from 'react';
import { Navigate } from 'react-router-dom';
import { BASE_AUTH_TOKEN, ROUTE_ADMIN } from '../../../common/constants';

export const withGuest = (ComposedComponent: ComponentType) => (props: any) => {
  const token = localStorage.getItem(BASE_AUTH_TOKEN);

  if (token) return createElement(Navigate, { to: ROUTE_ADMIN });

  return <ComposedComponent {...props} />;
};

import { createAsyncThunk } from '@reduxjs/toolkit';
import { RegionalAdministrationsApi } from '../http';
import { IRegionalAdministrations } from '../models';

const regionalAdministrationsApi = new RegionalAdministrationsApi();

export const fetchRegionalAdministrationsUpdate = createAsyncThunk(
  'regionalAdministrations/update',
  async (body: IRegionalAdministrations, { rejectWithValue }) => {
    try {
      return await regionalAdministrationsApi.updateOne(body);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

import { BaseApi } from '../../../common/axios';
import { IOpenInfo, IOpenInfoQuery } from '../models';
import { ENDPOINT_BASE_URL } from '../../../common/constants';
import {
  ENDPOINT_OPEN_INFO_FIND_ALL,
  ENDPOINT_OPEN_INFO_FIND_ONE,
  ENDPOINT_OPEN_INFO_CREATE_ONE,
  ENDPOINT_OPEN_INFO_DELETE_ONE,
  ENDPOINT_OPEN_INFO_UPDATE_ONE,
} from '../endpoints';

export class OpenInfoApi extends BaseApi {
  constructor() {
    super();
    this.baseUrl = ENDPOINT_BASE_URL;
  }

  public findAll(body: IOpenInfoQuery): Promise<IOpenInfo[]> {
    return this.httpClient
      .post(this.url(ENDPOINT_OPEN_INFO_FIND_ALL), body)
      .then((response) => response.data);
  }

  public findOne(body: { id: IOpenInfo['id'] }): Promise<IOpenInfo> {
    return this.httpClient
      .post(this.url(ENDPOINT_OPEN_INFO_FIND_ONE), body)
      .then((response) => response.data);
  }

  public createOne(body: IOpenInfoQuery): Promise<IOpenInfo> {
    return this.httpClient
      .post(this.url(ENDPOINT_OPEN_INFO_CREATE_ONE), body)
      .then((response) => response.data);
  }

  public removeOne(body: { id: IOpenInfo['id'] }): Promise<IOpenInfo> {
    return this.httpClient
      .post(this.url(ENDPOINT_OPEN_INFO_DELETE_ONE), body)
      .then((response) => response.data);
  }

  public updateOne(body: IOpenInfo): Promise<IOpenInfo> {
    return this.httpClient
      .post(this.url(ENDPOINT_OPEN_INFO_UPDATE_ONE), body)
      .then((response) => response.data);
  }
}

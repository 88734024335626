import { Box, styled } from '@mui/material';

export const StyledHeader = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3, 5),
  color: theme.palette.secondary.contrastText,
  backgroundColor: theme.palette.secondary.main,
  backgroundImage: 'url(/assets/img/bg-header.svg)',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(5, 7),
  },
  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(6, 9),
  },
}));

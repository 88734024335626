import { LOCALE_CR, LOCALE_EN, LOCALE_RU, LOCALE_UZ } from '../../../common/constants';
import { REGIONS_NAME, REGIONS_CODE } from './regions.constants';

export const REGIONS_INITIAL_VALUES = {
  [REGIONS_NAME]: {
    [LOCALE_UZ]: '',
    [LOCALE_CR]: '',
    [LOCALE_RU]: '',
    [LOCALE_EN]: '',
  },
  [REGIONS_CODE]: null,
};

import * as yup from 'yup';
import { FieldErrors, Mode, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { DefaultValues } from 'react-hook-form/dist/types/form';
import { useTranslation } from 'react-i18next';
import { DialogConfirm } from '../../../components/dialogs';
import { useDialog } from '../../../common/hooks';

interface UseEntityFormProps<T> {
  defaultValues?: DefaultValues<any>;
  schema?: yup.AnyObjectSchema;
  mode?: Mode;
  onValid?: (values: T) => void;
}

export const useEntityForm = <T>({
  schema = yup.object().shape({}),
  mode = 'onBlur',
  defaultValues = {},
  onValid: onExternalValid,
}: UseEntityFormProps<T>) => {
  const { t } = useTranslation();
  const [openConfirmation] = useDialog(DialogConfirm);

  const form = useForm({
    resolver: yupResolver(schema),
    shouldFocusError: true,
    mode,
    defaultValues,
  });

  const onValid = (values: T) => {
    console.log('values', values);
    openConfirmation({
      title: t('confirmation'),
      text: t('confirmation.text'),
      resolve: () => {
        if (onExternalValid) onExternalValid(values);
      },
    });
  };

  const onInvalid = (errors: FieldErrors<any>) => {
    console.log('onInvalid', errors);
    console.log(form.getValues());
  };

  const onReset = () => {
    form.reset(defaultValues);
  };

  const onSubmit = form.handleSubmit(onValid, onInvalid);

  return { ...form, onSubmit, onReset };
};

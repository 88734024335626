import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ILocalize } from '../../common/models';
import { get } from '../../common/utils';
import { LOCALE_CR, LOCALE_RU } from '../../common/constants';

interface LocalizeProps {
  data: ILocalize | string;
}

export const Localize: FC<LocalizeProps> = ({ data }) => {
  const { i18n } = useTranslation();
  const localizedRu = get(data, LOCALE_RU, null);
  const localizedDefault = get(data, LOCALE_CR, localizedRu);
  const localizedData = get(data, i18n.language, localizedDefault);
  return <>{localizedData}</>;
};

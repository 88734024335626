export const MSG_ERROR_REQUIRED_FIELD = 'error.field.required';
export const MSG_ERROR_INVALID_URL = 'error.field.invalid.url';
export const MSG_ERROR_TYPE_NUMBER = 'error.field.type.number';
export const MSG_ERROR_LENGTH_10 = 'error.field.length.10';
export const MSG_ERROR_TIN_LENGTH = 'error.field.tin.length';
export const MSG_ERROR_INVALID_EMAIL = 'error.field.invalid.email';
export const MSG_ERROR_PINFL_LENGTH = 'error.field.pinfl.length';
export const MSG_ERROR_TEL_LENGTH = 'error.field.tel.length';
export const MSG_ERROR_ROLE_LENGTH = 'error.field.role.length';
export const MSG_ERROR_MIN_1 = 'error.field.min.1';
export const MSG_ERROR_MAX_3 = 'error.field.max.1';

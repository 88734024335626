import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionButton } from '../../../../components/action-button';
import { IconSquareEditOutline } from '../../../../common/icons';
import { ROUTE_MANAGEMENT_UPDATE } from '../../../../common/constants';
import { url } from '../../../../common/utils';
import { IManagement } from '../../models';

interface ManagementActionEditProps {
  id: IManagement['id'];
}

export const ManagementActionEdit: FC<ManagementActionEditProps> = ({ id }) => {
  const { t } = useTranslation();

  return (
    <ActionButton
      to={url(ROUTE_MANAGEMENT_UPDATE, { id })}
      title={t('action.edit')}
      icon={<IconSquareEditOutline />}
    />
  );
};

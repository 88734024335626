import { createAsyncThunk } from '@reduxjs/toolkit';
import { RegionsApi } from '../http';
import { IRegions } from '../models';

const regionsApi = new RegionsApi();

export const fetchRegionsUpdate = createAsyncThunk(
  'regions/update',
  async (body: IRegions, { rejectWithValue }) => {
    try {
      return await regionsApi.updateOne(body);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

import { ChangeEvent, DragEvent } from 'react';
import { FileApi } from '../http';

const fileApi = new FileApi();

export const useFiles = () => {
  const handleUpload = async (files: FileList) => {
    const formData = new FormData();
    formData.append('file', files[0]);
    return await fileApi.uploadOne(formData);
  };

  const handleInputUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (!files) return;
    return await handleUpload(files);
  };

  const handleInputDrop = async (e: DragEvent<HTMLLabelElement>) => {
    e.preventDefault();
    const { files } = e.dataTransfer;
    if (!files) return;
    return await handleUpload(files);
  };

  return { handleUpload, handleInputUpload, handleInputDrop };
};

import * as yup from 'yup';
import {
  LOCALE_CR,
  LOCALE_EN,
  LOCALE_RU,
  LOCALE_UZ,
  MSG_ERROR_REQUIRED_FIELD,
  MSG_ERROR_TEL_LENGTH,
} from '../../../common/constants';
import {
  REGIONAL_ADMINISTRATIONS_ADDRESS,
  REGIONAL_ADMINISTRATIONS_DIRECTOR,
  REGIONAL_ADMINISTRATIONS_EMAIL,
  REGIONAL_ADMINISTRATIONS_END_TIME,
  REGIONAL_ADMINISTRATIONS_PHONE_NUMBER,
  REGIONAL_ADMINISTRATIONS_REGION_ID,
  REGIONAL_ADMINISTRATIONS_START_TIME,
} from '../constants';

const LocalizedFieldSchema = yup.object().shape({
  [LOCALE_CR]: yup.string().required(MSG_ERROR_REQUIRED_FIELD),
  [LOCALE_UZ]: yup.string(),
  [LOCALE_RU]: yup.string(),
  [LOCALE_EN]: yup.string(),
});

export const RegionalAdministrationsSchema = yup.object().shape({
  [REGIONAL_ADMINISTRATIONS_DIRECTOR]: LocalizedFieldSchema,
  [REGIONAL_ADMINISTRATIONS_REGION_ID]: yup.string().required(MSG_ERROR_REQUIRED_FIELD),
  [REGIONAL_ADMINISTRATIONS_START_TIME]: yup.string().required(MSG_ERROR_REQUIRED_FIELD),
  [REGIONAL_ADMINISTRATIONS_END_TIME]: yup.string().required(MSG_ERROR_REQUIRED_FIELD),
  [REGIONAL_ADMINISTRATIONS_ADDRESS]: LocalizedFieldSchema,
  [REGIONAL_ADMINISTRATIONS_EMAIL]: yup.string().required(MSG_ERROR_REQUIRED_FIELD),
  [REGIONAL_ADMINISTRATIONS_PHONE_NUMBER]: yup
    .string()
    .required()
    .test('length', MSG_ERROR_TEL_LENGTH, (value: any) => {
      if (!value) return true;
      return (value || '').replace(/[^+0-9]/g, '').length === 12;
    }),
});

import { IconExitToApp, IconSupport } from '../icons';
import { IMenuItem } from '../models';
import {
  ROUTE_EMAIL,
  ROUTE_FAQ,
  ROUTE_LOGOUT,
  ROUTE_MANAGEMENT,
  ROUTE_NEWS,
  ROUTE_OPEN_INFO,
  ROUTE_REGIONAL_ADMINISTRATIONS,
  ROUTE_REGIONS,
  ROUTE_USERS,
} from '../constants';

export const SIDEBAR_MENU: IMenuItem[] = [
  {
    path: ROUTE_NEWS,
    label: 'news',
    icon: <IconSupport />,
  },
  {
    path: ROUTE_EMAIL,
    label: 'email',
    icon: <IconSupport />,
  },
  {
    path: ROUTE_REGIONAL_ADMINISTRATIONS,
    label: 'regional.administrations',
    icon: <IconSupport />,
  },
  {
    path: ROUTE_REGIONS,
    label: 'regions',
    icon: <IconSupport />,
  },
  {
    path: ROUTE_MANAGEMENT,
    label: 'management',
    icon: <IconSupport />,
  },
  {
    path: ROUTE_FAQ,
    label: 'faq',
    icon: <IconSupport />,
  },
  {
    path: ROUTE_USERS,
    label: 'users',
    icon: <IconSupport />,
  },
  {
    path: ROUTE_OPEN_INFO,
    label: 'open.info',
    icon: <IconSupport />,
  },
  {
    path: ROUTE_LOGOUT,
    label: 'logout',
    icon: <IconExitToApp />,
  },
];

import { RouteObject, Outlet } from 'react-router-dom';
import {
  ROUTE_NEWS,
  ROUTE_NEWS_CREATE,
  ROUTE_NEWS_UPDATE,
  ROUTE_NEWS_VIEW,
} from '../../../common/constants';
import {
  NewsPage,
  NewsCreatePage,
  NewsUpdatePage,
  NewsViewPage,
} from '../../../pages/admin/news-page';

export const newsRoutes: RouteObject = {
  path: ROUTE_NEWS,
  element: <Outlet />,
  caseSensitive: true,
  children: [
    { index: true, element: <NewsPage />, caseSensitive: true },
    {
      path: ROUTE_NEWS_CREATE,
      element: <NewsCreatePage />,
      caseSensitive: true,
    },
    {
      path: ROUTE_NEWS_UPDATE,
      element: <NewsUpdatePage />,
      caseSensitive: true,
    },
    {
      path: ROUTE_NEWS_VIEW,
      element: <NewsViewPage />,
      caseSensitive: true,
    },
  ],
};

import * as yup from 'yup';
import {
  LOCALE_CR,
  LOCALE_EN,
  LOCALE_RU,
  LOCALE_UZ,
  MSG_ERROR_REQUIRED_FIELD,
} from '../../../common/constants';
import { OPEN_INFO_TITLE, OPEN_INFO_TYPE, OPEN_INFO_FILE_ID } from '../constants';

const LocalizedFieldSchema = yup.object().shape({
  [LOCALE_CR]: yup.string().required(MSG_ERROR_REQUIRED_FIELD),
  [LOCALE_UZ]: yup.string(),
  [LOCALE_RU]: yup.string(),
  [LOCALE_EN]: yup.string(),
});

export const OpenInfoSchema = yup.object().shape({
  [OPEN_INFO_TITLE]: LocalizedFieldSchema,
  [OPEN_INFO_TYPE]: yup.string(),
  [OPEN_INFO_FILE_ID]: yup.string().required(MSG_ERROR_REQUIRED_FIELD),
});

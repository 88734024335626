import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import { RecordRow } from '../../../../components/record-row';
import { FAQ_QUESTION, FAQ_ANSWER } from '../../constants';
import { LOCALE_CR } from '../../../../common/constants';
import { get } from '../../../../common/utils';
import { IFAQ } from '../../models';

interface FAQPreviewProps {
  data: IFAQ;
}

export const FAQPreview: FC<FAQPreviewProps> = ({ data }) => {
  const { t, i18n } = useTranslation();

  return (
    <Stack spacing={1.5}>
      <Typography variant="subtitle1" component="div">
        {get(data, [FAQ_QUESTION, i18n.language]) || get(data, [FAQ_QUESTION, LOCALE_CR])}
      </Typography>
      <RecordRow label={t('faq.field.answer')} value={get(data, FAQ_ANSWER)} />
    </Stack>
  );
};

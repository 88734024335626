import { IconExitToApp } from '../icons';
import { IMenuItem } from '../models';
import { ROUTE_LOGOUT } from '../constants';

export const ACCOUNT_MENU: IMenuItem[] = [
  // {
  //   path: ROUTE_SETTINGS_ACCOUNT,
  //   label: 'settings',
  //   icon: <IconGear />,
  // },
  {
    path: ROUTE_LOGOUT,
    label: 'logout',
    icon: <IconExitToApp />,
  },
];

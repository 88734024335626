import { createAsyncThunk } from '@reduxjs/toolkit';
import { FAQApi } from '../http';
import { IFAQQuery } from '../models';

const faqApi = new FAQApi();

export const fetchFAQCreate = createAsyncThunk(
  'faq/create',
  async (body: IFAQQuery, { rejectWithValue }) => {
    try {
      return await faqApi.createOne(body);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

import { Typography } from '@mui/material';
import { UsersActionDelete, UsersActionEdit, UsersActionView } from '../components';
import { IUser } from '../models';
import { USER_FIELD_LAST_NAME, USER_FIELD_USER_NAME } from '../constants';
import { IColumn } from '../../../common/models';

export const USER_COLUMNS: IColumn[] = [
  {
    dataKey: USER_FIELD_LAST_NAME,
    label: 'users.field.full.name',
    renderItem: ({ first_name, last_name, middle_name }: IUser) => (
      <Typography variant="body2">{[last_name, first_name, middle_name].join(' ')}</Typography>
    ),
  },
  {
    dataKey: USER_FIELD_USER_NAME,
    label: 'users.field.userame',
    renderItem: ({ username }: IUser) => (
      <Typography component="div" variant="body2">
        {username ?? '-'}
      </Typography>
    ),
  },
  {
    dataKey: '',
    label: 'action.edit',
    width: '1%',
    renderItem: ({ id }: IUser) => <UsersActionEdit id={id} />,
  },
  {
    dataKey: '',
    label: 'action.delete',
    width: '1%',
    renderItem: ({ id }: IUser) => <UsersActionDelete id={id} />,
  },
  {
    dataKey: '',
    label: 'action.view',
    width: '1%',
    renderItem: (data: IUser) => <UsersActionView data={data} />,
  },
];

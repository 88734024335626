import { FC } from 'react';
import {
  SelectFieldControllerProps,
  SelectFieldController,
} from '../../../../../components/inputs';
import { useTranslation } from 'react-i18next';

interface SelectOpenInfoControllerProps extends Omit<SelectFieldControllerProps, 'options'> {}

export const SelectOpenInfoController: FC<SelectOpenInfoControllerProps> = ({ type, ...props }) => {
  const { t } = useTranslation();
  const data: { type: string; label: string }[] = [
    {
      type: 'OPEN',
      label: 'open.info.open.data',
    },
    {
      type: 'DEPARTMENT',
      label: 'open.info.needed.docs',
    },
  ];

  const options = data.map(({ type, label }: any) => ({
    value: type,
    label: t(label),
  }));

  if (!options.length) {
    return <SelectFieldController {...props} options={[]} disabled />;
  }

  return <SelectFieldController {...props} options={options} />;
};

import {
  RegionalAdministrationsActionDelete,
  RegionalAdministrationsActionEdit,
  RegionalAdministrationsActionView,
} from '../components';
import { Localize } from '../../../components/localize';
import { IColumn } from '../../../common/models';
import { IRegionalAdministrations } from '../models';
import {
  REGIONAL_ADMINISTRATIONS_DIRECTOR,
  REGIONAL_ADMINISTRATIONS_EMAIL,
  REGIONAL_ADMINISTRATIONS_ADDRESS,
  REGIONAL_ADMINISTRATIONS_START_TIME,
} from '../constants';
import { Typography } from '@mui/material';

export const REGIONAL_ADMINISTRATIONS_COLUMNS: IColumn[] = [
  {
    dataKey: REGIONAL_ADMINISTRATIONS_DIRECTOR,
    label: 'regional.administrations.director',
    renderItem: ({ director_json }: IRegionalAdministrations) => (
      <Localize data={director_json ? director_json : '-'} />
    ),
  },
  {
    dataKey: REGIONAL_ADMINISTRATIONS_EMAIL,
    label: 'regional.administrations.email',
    renderItem: ({ email }: IRegionalAdministrations) => (
      <Typography component="div" variant="body2">
        {email ? email : '-'}
      </Typography>
    ),
  },
  {
    dataKey: REGIONAL_ADMINISTRATIONS_ADDRESS,
    label: 'regional.administrations.address',
    renderItem: ({ address_json }: IRegionalAdministrations) => (
      <Localize data={address_json ? address_json : '-'} />
    ),
  },
  {
    dataKey: REGIONAL_ADMINISTRATIONS_START_TIME,
    label: 'regional.administrations.working.hours',
    width: '8%',
    renderItem: ({ start_time, end_time }: IRegionalAdministrations) => (
      <Typography component="div" variant="body2" textAlign="center">
        {start_time ? start_time + '-' + end_time : '-'}
      </Typography>
    ),
  },
  {
    dataKey: '',
    label: 'action.edit',
    width: '1%',
    renderItem: ({ id }: IRegionalAdministrations) => <RegionalAdministrationsActionEdit id={id} />,
  },
  {
    dataKey: '',
    label: 'action.delete',
    width: '1%',
    renderItem: ({ id }: IRegionalAdministrations) => (
      <RegionalAdministrationsActionDelete id={id} />
    ),
  },
  {
    dataKey: '',
    label: 'action.view',
    width: '1%',
    renderItem: (data: IRegionalAdministrations) => (
      <RegionalAdministrationsActionView data={data} />
    ),
  },
];

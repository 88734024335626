import { AxiosRequestConfig } from 'axios';
import { ENDPOINT_BASE_URL } from '../constants';

export const axiosConfig: AxiosRequestConfig = {
  timeout: 30000,
  baseURL: ENDPOINT_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
};

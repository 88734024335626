import { Outlet, RouteObject } from 'react-router-dom';
import { ROUTE_USERS, ROUTE_USERS_CREATE, ROUTE_USERS_UPDATE } from '../../../common/constants';
import { UserCreatePage, UserPage, UserUpdatePage } from '../../../pages/admin/user';

export const userRoutes: RouteObject = {
  path: ROUTE_USERS,
  element: <Outlet />,
  caseSensitive: true,
  children: [
    { index: true, element: <UserPage />, caseSensitive: true },
    {
      path: ROUTE_USERS_CREATE,
      element: <UserCreatePage />,
      caseSensitive: true,
    },
    {
      path: ROUTE_USERS_UPDATE,
      element: <UserUpdatePage />,
      caseSensitive: true,
    },
  ],
};

import { createAsyncThunk } from '@reduxjs/toolkit';
import { OpenInfoApi } from '../http';
import { IOpenInfoQuery } from '../models';

const openInfoApi = new OpenInfoApi();

export const fetchOpenInfoAll = createAsyncThunk(
  'openInfo/all',
  async (body: IOpenInfoQuery, { rejectWithValue }) => {
    try {
      const data = await openInfoApi.findAll(body);
      return { data, page: 1, limit: 9999, total: data.length };
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

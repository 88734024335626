import { createAsyncThunk } from '@reduxjs/toolkit';
import { NewsApi } from '../http';
import { INewsQuery } from '../models';

const newsApi = new NewsApi();

export const fetchNewsAll = createAsyncThunk(
  'news/all',
  async (body: INewsQuery, { rejectWithValue }) => {
    try {
      const data = await newsApi.findAll(body);
      return { data, page: 1, limit: 9999, total: data.length };
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

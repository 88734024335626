import { BaseApi } from '../../../common/axios';
import { IRegions, IRegionsQuery } from '../models';
import { ENDPOINT_BASE_URL } from '../../../common/constants';
import {
  ENDPOINT_REGIONS_FIND_ALL,
  ENDPOINT_REGIONS_FIND_ONE,
  ENDPOINT_REGIONS_CREATE_ONE,
  ENDPOINT_REGIONS_DELETE_ONE,
  ENDPOINT_REGIONS_UPDATE_ONE,
} from '../endpoints';

export class RegionsApi extends BaseApi {
  constructor() {
    super();
    this.baseUrl = ENDPOINT_BASE_URL;
  }

  public findAll(body: IRegionsQuery): Promise<IRegions[]> {
    return this.httpClient
      .post(this.url(ENDPOINT_REGIONS_FIND_ALL), body)
      .then((response) => response.data);
  }

  public findOne(body: { id: IRegions['id'] }): Promise<IRegions> {
    return this.httpClient
      .post(this.url(ENDPOINT_REGIONS_FIND_ONE), body)
      .then((response) => response.data);
  }

  public createOne(body: IRegionsQuery): Promise<IRegions> {
    return this.httpClient
      .post(this.url(ENDPOINT_REGIONS_CREATE_ONE), body)
      .then((response) => response.data);
  }

  public removeOne(body: { id: IRegions['id'] }): Promise<IRegions> {
    return this.httpClient
      .post(this.url(ENDPOINT_REGIONS_DELETE_ONE), body)
      .then((response) => response.data);
  }

  public updateOne(body: IRegions): Promise<IRegions> {
    return this.httpClient
      .post(this.url(ENDPOINT_REGIONS_UPDATE_ONE), body)
      .then((response) => response.data);
  }
}

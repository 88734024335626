import { FC, PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { SidebarContext } from '../contexts';
import { BASE_SIDEBAR_COLLAPSED } from '../constants';

const IS_SIDEBAR_COLLAPSED = localStorage.getItem(BASE_SIDEBAR_COLLAPSED) === 'true';

export const SidebarProvider: FC<PropsWithChildren<any>> = ({ children }) => {
  const [collapsed, setCollapsed] = useState(IS_SIDEBAR_COLLAPSED);

  const handleCollapse = useCallback(() => {
    setCollapsed(true);
    localStorage.setItem(BASE_SIDEBAR_COLLAPSED, 'true');
  }, []);

  const handleExtend = useCallback(() => {
    setCollapsed(false);
    localStorage.setItem(BASE_SIDEBAR_COLLAPSED, 'false');
  }, []);

  const handleToggle = useCallback(() => {
    if (collapsed) handleExtend();
    else handleCollapse();
  }, [collapsed, handleCollapse, handleExtend]);

  useEffect(() => {
    if (window.innerWidth < 768) handleCollapse();
  }, [handleCollapse]);

  return (
    <SidebarContext.Provider value={{ collapsed, handleCollapse, handleExtend, handleToggle }}>
      {children}
    </SidebarContext.Provider>
  );
};

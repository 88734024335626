import { useEffect } from 'react';
import { AsyncThunk } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../store';
import { useSnackbar } from '../../../common/hooks';
import { get, replaceEmptyStrings, replaceNulls } from '../../../common/utils';

interface UseEntityUpdateProps {
  entity: string;
  recordThunk: AsyncThunk<any, any, any>;
  updateThunk: AsyncThunk<any, any, any>;
  nextPath?: string;
  paramKey?: string;
}

export const useEntityUpdate = <T>({
  entity,
  recordThunk,
  updateThunk,
  nextPath,
  paramKey = 'id',
}: UseEntityUpdateProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { showMessage } = useSnackbar();
  const { result, isLoading: isFetching } = useAppSelector((state) =>
    get(state, [entity, 'record'], {}),
  );
  const { isLoading: isUpdating } = useAppSelector((state) => get(state, [entity, 'update'], {}));

  const data = result ? replaceNulls(result) : {};
  const id = get(params, paramKey);

  const handleValid = (formValues: T) => {
    const payload = replaceEmptyStrings(formValues);
    dispatch(updateThunk(payload))
      .unwrap()
      .then(() => {
        if (nextPath) navigate(nextPath);
      })
      .catch((e: any) => {
        const errors = get(e, 'response.data');
        const message = get(errors, 'message', t('error'));
        showMessage(message, { variant: 'filled', severity: 'error' });
      });
  };

  useEffect(() => {
    dispatch(recordThunk({ id }));
  }, [dispatch, recordThunk, id]);

  return { isFetching, isUpdating, data, handleValid };
};

import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IUser } from '../../models';
import { ActionButton } from '../../../../components/action-button';
import { ROUTE_USERS_UPDATE } from '../../../../common/constants';
import { IconSquareEditOutline } from '../../../../common/icons';
import { url } from '../../../../common/utils';

interface UserActionEditProps {
  id: IUser['id'];
  disabled?: boolean;
}

export const UsersActionEdit: FC<UserActionEditProps> = ({ id, disabled = false }) => {
  const { t } = useTranslation();

  return (
    <ActionButton
      to={url(ROUTE_USERS_UPDATE, { id })}
      title={t('action.edit')}
      icon={<IconSquareEditOutline />}
      disabled={disabled}
    />
  );
};

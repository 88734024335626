import { createAsyncThunk } from '@reduxjs/toolkit';
import { NewsApi } from '../http';
import { INews } from '../models';

const newsApi = new NewsApi();

export const fetchNewsRecord = createAsyncThunk(
  'news/record',
  async (body: { id: INews['id'] }, { rejectWithValue }) => {
    try {
      return await newsApi.findOne(body);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import { RecordRow } from '../../../../components/record-row';
import { MANAGEMENT_OFFICER_NAME, MANAGEMENT_OFFICER_POSITION } from '../../constants';
import { LOCALE_CR } from '../../../../common/constants';
import { get } from '../../../../common/utils';
import { IManagement } from '../../models';

interface ManagementPreviewProps {
  data: IManagement;
}

export const ManagementPreview: FC<ManagementPreviewProps> = ({ data }) => {
  const { t, i18n } = useTranslation();

  return (
    <Stack spacing={1.5}>
      <Typography variant="subtitle1" component="div">
        {get(data, [MANAGEMENT_OFFICER_NAME, i18n.language]) ||
          get(data, [MANAGEMENT_OFFICER_NAME, LOCALE_CR])}
      </Typography>
      <RecordRow label={t('management.position')} value={get(data, MANAGEMENT_OFFICER_POSITION)} />
    </Stack>
  );
};

import { FC } from 'react';
import { TextFieldProps } from '@mui/material';
import {
  DatePicker,
  DatePickerProps,
  LocalizationProvider,
  PickerChangeHandlerContext,
} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Control, Controller } from 'react-hook-form';
import { isValid } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useDateFnsAdapterLocale } from '../../../common/hooks';
import { formatOriginDate } from '../../../common/utils';
import { FORMAT_DATE, FORMAT_ISO_DATE } from '../../../common/constants';

export interface DateFieldControllerProps
  extends Omit<DatePickerProps<Date>, 'onChange' | 'slotProps' | 'value'> {
  control: Control<any>;
  name: string;
  label?: TextFieldProps['label'];
  variant?: TextFieldProps['variant'];
  placeholder?: TextFieldProps['placeholder'];
  size?: TextFieldProps['size'];
  fullWidth?: TextFieldProps['fullWidth'];
  sx?: TextFieldProps['sx'];
}

export const DateFieldController: FC<DateFieldControllerProps> = ({
  control,
  name,
  label,
  variant,
  placeholder,
  size,
  disabled = false,
  disableFuture = true,
  disablePast = false,
  fullWidth = true,
  sx,
  views,
}) => {
  const { t } = useTranslation();
  const { adapterLocale } = useDateFnsAdapterLocale();

  const handleChange =
    (onChange: any, onBlur?: any) =>
    (date: Date | null, { validationError }: PickerChangeHandlerContext<any>) => {
      if (onChange) {
        if (date && isValid(date) && !validationError) {
          onChange(formatOriginDate(date, FORMAT_ISO_DATE));
        } else if (date === null) onChange(null);
      }
      if (onBlur) onBlur();
    };

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState }) => (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={adapterLocale}>
          <DatePicker
            {...field}
            onChange={handleChange(field.onChange, field.onBlur)}
            value={field.value ? new Date(field.value) : null}
            label={label}
            disabled={disabled}
            views={views}
            disableFuture={disableFuture}
            disablePast={disablePast}
            format={FORMAT_DATE}
            slotProps={{
              textField: {
                name,
                error: !!fieldState.error?.message,
                helperText: fieldState.error?.message ? t(fieldState.error.message) : null,
                variant,
                placeholder,
                size,
                disabled,
                fullWidth,
                sx,
              },
            }}
          />
        </LocalizationProvider>
      )}
    />
  );
};

import { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { get } from '../../../../common/utils';
import { Container } from '@mui/material';
import { fetchEmailRecord, fetchEmailUpdate } from '../../../../features/email';
import { useEntityUpdate } from '../../../../features/entities/hooks';
import { EmailForms } from '../../../../features/email';
import { PreloaderWrapper } from '../../../../components/preloader-wrapper';
import { PageTitle } from '../../../../components/page-title';
import { ROUTE_EMAIL } from '../../../../common/constants';
import { IEmail } from '../../../../features/email';

export const EmailUpdatePage: FC = () => {
  const { t } = useTranslation();
  const { isFetching, isUpdating, data, handleValid } = useEntityUpdate<IEmail>({
    entity: 'email',
    recordThunk: fetchEmailRecord,
    updateThunk: fetchEmailUpdate,
    nextPath: ROUTE_EMAIL,
  });

  return (
    <>
      <Helmet>
        <title>{t('email.update')}</title>
      </Helmet>
      <Container>
        <PageTitle title={t('email.update.title')} mb={3} />
        <PreloaderWrapper isLoading={isFetching}>
          <EmailForms
            onValid={handleValid}
            defaultValues={data}
            isLoading={isUpdating}
            key={get(data, 'id')}
          />
        </PreloaderWrapper>
      </Container>
    </>
  );
};

import { LOCALE_CR, LOCALE_EN, LOCALE_RU, LOCALE_UZ } from '../../../common/constants';
import {
  NEWS_FIELD_FILE,
  NEWS_FIELD_FILES,
  NEWS_FIELD_TITLE,
  NEWS_FIELD_TYPE,
  NEWS_FIELD_CONTENT,
  NEWS_FIELD_ADDITIONAL_FILES,
} from './news-field.constants';

export const NEWS_INITIAL_VALUES = {
  [NEWS_FIELD_TITLE]: {
    [LOCALE_UZ]: '',
    [LOCALE_CR]: '',
    [LOCALE_RU]: '',
    [LOCALE_EN]: '',
  },
  [NEWS_FIELD_CONTENT]: {
    [LOCALE_UZ]: '',
    [LOCALE_CR]: '',
    [LOCALE_RU]: '',
    [LOCALE_EN]: '',
  },
  [NEWS_FIELD_FILE]: null,
  [NEWS_FIELD_TYPE]: null,
  [NEWS_FIELD_FILES]: [],
  [NEWS_FIELD_ADDITIONAL_FILES]: null,
};

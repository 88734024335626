import { BaseApi } from '../../../common/axios';
import { IUser, IUserQuery } from '../models';
import {
  ENDPOINT_USER_CREATE_ONE,
  ENDPOINT_USER_DELETE_ONE,
  ENDPOINT_USER_FIND_ALL,
  ENDPOINT_USER_FIND_ONE,
  ENDPOINT_USER_UPDATE_ONE,
} from '../endpoints';
import { ENDPOINT_BASE_URL } from '../../../common/constants';

export class UserApi extends BaseApi {
  constructor() {
    super();
    this.baseUrl = ENDPOINT_BASE_URL;
  }

  public findAll(body: IUserQuery): Promise<IUser[]> {
    return this.httpClient
      .post(this.url(ENDPOINT_USER_FIND_ALL), body)
      .then((response) => response.data);
  }

  public findOne(body: { id: IUser['id'] }): Promise<IUser> {
    return this.httpClient
      .post(this.url(ENDPOINT_USER_FIND_ONE), body)
      .then((response) => response.data);
  }

  public createOne(body: IUser): Promise<IUser> {
    return this.httpClient
      .post(this.url(ENDPOINT_USER_CREATE_ONE), body)
      .then((response) => response.data);
  }

  public updateOne(body: IUser): Promise<IUser> {
    return this.httpClient
      .post(this.url(ENDPOINT_USER_UPDATE_ONE), body)
      .then((response) => response.data);
  }

  public deleteOne(body: { id: IUser['id'] }): Promise<IUser> {
    return this.httpClient
      .post(this.url(ENDPOINT_USER_DELETE_ONE), body)
      .then((response) => response.data);
  }
}

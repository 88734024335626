import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IManagement } from '../../models';
import { ManagementPreview } from '../management-preview';
import { ActionButton } from '../../../../components/action-button';
import { DialogPreview } from '../../../../components/dialogs';
import { useDialog } from '../../../../common/hooks';
import { IconEye } from '../../../../common/icons';

interface ManagementActionViewProps {
  data: IManagement;
}
export const ManagementActionView: FC<ManagementActionViewProps> = ({ data }) => {
  const { t } = useTranslation();
  const [openPreview] = useDialog(DialogPreview);

  const handlePreview = () => {
    openPreview({
      title: t('management.form.title'),
      component: <ManagementPreview data={data} />,
    });
  };

  return <ActionButton onClick={handlePreview} title={t('action.view')} icon={<IconEye />} />;
};

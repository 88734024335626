import { createAsyncThunk } from '@reduxjs/toolkit';
import { NewsApi } from '../http';

const newsApi = new NewsApi();

export const fetchNewsDelete = createAsyncThunk(
  'news/delete',
  async (id: string, { rejectWithValue }) => {
    try {
      return await newsApi.removeOne({ id });
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

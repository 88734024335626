import { createAsyncThunk } from '@reduxjs/toolkit';
import { EmailApi } from '../http';
import { IEmail } from '../models';

const emailApi = new EmailApi();

export const fetchEmailRecord = createAsyncThunk(
  'email/record',
  async (body: { id: IEmail['id'] }, { rejectWithValue }) => {
    try {
      return await emailApi.findOne(body);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

import { createAsyncThunk } from '@reduxjs/toolkit';
import { EmailApi } from '../http';
import { IEmailQuery } from '../models';

const emailApi = new EmailApi();

export const fetchEmailAll = createAsyncThunk(
  'email/all',
  async (body: IEmailQuery, { rejectWithValue }) => {
    try {
      const data = await emailApi.findAll(body);
      return { data, page: 1, limit: 9999, total: data.length };
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

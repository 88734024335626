import { Button, darken, styled } from '@mui/material';
import { OverrideProps } from '@mui/material/OverridableComponent';
import { ButtonTypeMap } from '@mui/material/Button/Button';

export const StyledDropdown = styled(Button)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: theme.spacing(1.25, 2),
  fontSize: 14,
  color: darken(theme.palette.common.white, 0.2),
  borderRadius: 4,
  '&:hover, &.MuiSelected': {
    color: theme.palette.common.white,
    backgroundColor: '#2C3344',
  },
  '& svg': {
    '&:first-of-type': {
      marginRight: theme.spacing(2),
    },
    '&:last-of-type': {
      marginLeft: 'auto',
    },
  },
})) as OverrideProps<ButtonTypeMap<any, any>, any>;

export const StyledDropdownButton = styled(Button)(({ theme }) => ({
  display: 'block',
  alignItems: 'center',
  justifyContent: 'flex-start',
  width: '100%',
  padding: theme.spacing(1.25, 2, 1.25, 7),
  fontSize: 14,
  color: darken(theme.palette.common.white, 0.2),
  borderRadius: 4,
  '&:hover, &.MuiSelected': {
    color: theme.palette.common.white,
    backgroundColor: '#2C3344',
  },
  '& svg': {
    '&:first-of-type': {
      marginRight: theme.spacing(2),
    },
  },
})) as OverrideProps<ButtonTypeMap<any, any>, any>;

import {
  USER_FIELD_FIRST_NAME,
  USER_FIELD_LAST_NAME,
  USER_FIELD_MIDDLE_NAME,
  USER_FIELD_USER_NAME,
  USER_FIELD_PASSWORD,
} from './user-field.constants';

export const USER_INITIAL_VALUES = {
  [USER_FIELD_FIRST_NAME]: '',
  [USER_FIELD_LAST_NAME]: '',
  [USER_FIELD_MIDDLE_NAME]: '',
  [USER_FIELD_USER_NAME]: '',
  [USER_FIELD_PASSWORD]: '',
};

import { RouteObject, Outlet } from 'react-router-dom';
import {
  ROUTE_REGIONS,
  ROUTE_REGIONS_CREATE,
  ROUTE_REGIONS_UPDATE,
} from '../../../common/constants';
import {
  RegionsPage,
  RegionsCreatePage,
  RegionsUpdatePage,
} from '../../../pages/admin/regions-page';

export const regionsRoutes: RouteObject = {
  path: ROUTE_REGIONS,
  element: <Outlet />,
  caseSensitive: true,
  children: [
    { index: true, element: <RegionsPage />, caseSensitive: true },
    {
      path: ROUTE_REGIONS_CREATE,
      element: <RegionsCreatePage />,
      caseSensitive: true,
    },
    {
      path: ROUTE_REGIONS_UPDATE,
      element: <RegionsUpdatePage />,
      caseSensitive: true,
    },
  ],
};

import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IOpenInfo } from '../../models';
import { OpenInfoPreview } from '../open-info-preview';
import { ActionButton } from '../../../../components/action-button';
import { DialogPreview } from '../../../../components/dialogs';
import { useDialog } from '../../../../common/hooks';
import { IconEye } from '../../../../common/icons';

interface OpenInfoActionViewProps {
  data: IOpenInfo;
}
export const OpenInfoActionView: FC<OpenInfoActionViewProps> = ({ data }) => {
  const { t } = useTranslation();
  const [openPreview] = useDialog(DialogPreview);

  const handlePreview = () => {
    openPreview({
      title: t('open.info'),
      component: <OpenInfoPreview data={data} />,
    });
  };

  return <ActionButton onClick={handlePreview} title={t('action.view')} icon={<IconEye />} />;
};

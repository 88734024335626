import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogBase, DialogProps } from '../dialog-base';
import { Button, Stack, Typography } from '@mui/material';
import { IconCheck } from '../../../common/icons';

interface DialogConfirmProps extends DialogProps {
  text?: string;
  resolve?: () => void;
  reject?: () => void;
}

export const DialogConfirm: FC<DialogConfirmProps> = ({
  title,
  text,
  resolve,
  reject,
  popDialog,
  ...props
}) => {
  const { t } = useTranslation();
  const dialogTitle = title || t('confirmation');

  const handleClose = useCallback(() => {
    if (popDialog) popDialog();
  }, [popDialog]);

  const handleResolve = useCallback(() => {
    if (resolve) resolve();
    handleClose();
  }, [resolve, handleClose]);

  const handleReject = useCallback(() => {
    if (reject) reject();
    handleClose();
  }, [reject, handleClose]);

  return (
    <DialogBase
      {...{ ...props, popDialog: handleReject }}
      maxWidth="sm"
      title={dialogTitle}
      icon={<IconCheck color="success" sx={{ fontSize: 32 }} />}
    >
      {text && (
        <Typography variant="body2" mb={3}>
          {text}
        </Typography>
      )}
      <Stack direction="row" alignItems="center" spacing={1.5}>
        <Button onClick={handleReject} variant="contained" color="snow" fullWidth>
          {t('action.cancel')}
        </Button>
        <Button onClick={handleResolve} variant="contained" color="success" fullWidth>
          {t('action.confirm')}
        </Button>
      </Stack>
    </DialogBase>
  );
};

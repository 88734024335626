import { createAsyncThunk } from '@reduxjs/toolkit';
import { OpenInfoApi } from '../http';
import { IOpenInfoQuery } from '../models';

const openInfoApi = new OpenInfoApi();

export const fetchOpenInfoCreate = createAsyncThunk(
  'openInfo/create',
  async (body: IOpenInfoQuery, { rejectWithValue }) => {
    try {
      return await openInfoApi.createOne(body);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

import { createAsyncThunk } from '@reduxjs/toolkit';
import { NewsApi } from '../http';
import { INewsQuery } from '../models';

const newsApi = new NewsApi();

export const fetchNewsCreate = createAsyncThunk(
  'news/create',
  async (body: INewsQuery, { rejectWithValue }) => {
    try {
      return await newsApi.createOne(body);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

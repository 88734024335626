import { RouteObject, Outlet } from 'react-router-dom';
import {
  ROUTE_OPEN_INFO,
  ROUTE_OPEN_INFO_CREATE,
  ROUTE_OPEN_INFO_UPDATE,
} from '../../../common/constants';
import {
  OpenInfoPage,
  OpenInfoCreatePage,
  OpenInfoUpdatePage,
} from '../../../pages/admin/open-info-page';

export const openInfoRoutes: RouteObject = {
  path: ROUTE_OPEN_INFO,
  element: <Outlet />,
  caseSensitive: true,
  children: [
    { index: true, element: <OpenInfoPage />, caseSensitive: true },
    {
      path: ROUTE_OPEN_INFO_CREATE,
      element: <OpenInfoCreatePage />,
      caseSensitive: true,
    },
    {
      path: ROUTE_OPEN_INFO_UPDATE,
      element: <OpenInfoUpdatePage />,
      caseSensitive: true,
    },
  ],
};

import { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { get } from '../../../../common/utils';
import { Container } from '@mui/material';
import { fetchUserRecord, fetchUserUpdate } from '../../../../features/users/thunks';
import { useEntityUpdate } from '../../../../features/entities/hooks';
import { UserForm } from '../../../../features/users/components';
import { PreloaderWrapper } from '../../../../components/preloader-wrapper';
import { PageTitle } from '../../../../components/page-title';
import { ROUTE_USERS } from '../../../../common/constants';
import { IUser } from '../../../../features/users/models';

export const UserUpdatePage: FC = () => {
  const { t } = useTranslation();
  const { isFetching, isUpdating, data, handleValid } = useEntityUpdate<IUser>({
    entity: 'user',
    recordThunk: fetchUserRecord,
    updateThunk: fetchUserUpdate,
    nextPath: ROUTE_USERS,
  });

  return (
    <>
      <Helmet>
        <title>{t('user.update')}</title>
      </Helmet>
      <Container>
        <PageTitle title={t('user.update.title')} mb={3} />
        <PreloaderWrapper isLoading={isFetching}>
          <UserForm
            onValid={handleValid}
            defaultValues={data}
            isLoading={isUpdating}
            key={get(data, 'id')}
          />
        </PreloaderWrapper>
      </Container>
    </>
  );
};

import { ChangeEvent, ComponentType, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableProps,
  TableRow,
  Typography,
} from '@mui/material';
import { DataLoader } from '../data-loader';
import { DataAnnunciator } from '../data-annunciator';
import { IColumn } from '../../../common/models';
import { get } from '../../../common/utils';

export interface DataTableProps extends Omit<TableProps, 'onSelect'> {
  data: any[];
  columns?: IColumn[];
  isLoading?: boolean;
  showIndex?: boolean;
  page?: number;
  selectable?: boolean;
  selectedIndexes?: number[];
  onSelect?: (indexes: number[]) => void;
}

export const DataTable: ComponentType<DataTableProps> = ({
  data = [],
  columns: dataColumns = [],
  isLoading = false,
  showIndex = false,
  page = 1,
  selectable = false,
  selectedIndexes = [],
  onSelect,
  ...props
}) => {
  const { t } = useTranslation();
  const [columns, setColumns] = useState<IColumn[]>();

  const isDataEmpty = !data || !data.length;
  const isColumnsEmpty = !columns || !columns.length;
  let colSpan = columns?.length ? columns?.length : 1;

  const handleSelect = (idx: number) => (e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (onSelect) {
      onSelect(checked ? [...selectedIndexes, idx] : selectedIndexes.filter((i) => i !== idx));
    }
  };

  const handleSelectAll = (e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (onSelect) {
      onSelect(checked ? data.map((_, idx) => idx) : []);
    }
  };

  useEffect(() => {
    const newColumns = [...dataColumns];
    if (showIndex) {
      newColumns.unshift({
        dataKey: 'index',
        label: '№',
        width: '1%',
      });
    }
    if (selectable) {
      newColumns.unshift({
        dataKey: 'select',
        label: 'action.select',
      });
    }
    setColumns(newColumns);
  }, [dataColumns, showIndex, selectable]);

  const tableHead = () => {
    if (isColumnsEmpty) return null;

    let cols = columns.map(({ dataKey, label, width }, idx) => {
      if (dataKey === 'select') {
        return (
          <TableCell width="1%" key={`select-${idx}`}>
            <Checkbox
              onChange={handleSelectAll}
              checked={selectedIndexes.length > 0 && selectedIndexes.length === data.length}
            />
          </TableCell>
        );
      }
      return (
        <TableCell width={width} key={idx}>
          {label && (
            <Typography variant="body2" color="grey.700">
              {t(label)}
            </Typography>
          )}
        </TableCell>
      );
    });

    return (
      <TableHead>
        <TableRow>{cols}</TableRow>
      </TableHead>
    );
  };

  const tableBody = () => {
    if (isDataEmpty || isColumnsEmpty) return null;

    const rows = data.map((item, idx) => {
      const cols = columns.map(({ dataKey, renderItem }, colx) => {
        if (dataKey === 'select') {
          return (
            <TableCell key={`select-${idx}`}>
              <Checkbox onChange={handleSelect(idx)} checked={selectedIndexes.includes(idx)} />
            </TableCell>
          );
        } else if (dataKey === 'index') {
          return (
            <TableCell key={`number-${idx}`}>
              <Typography variant="caption" color="text.secondary">
                {idx + 1 + data.length * (page - 1)}
              </Typography>
            </TableCell>
          );
        } else if (renderItem) {
          return <TableCell key={colx}>{renderItem(item, idx)}</TableCell>;
        }
        return (
          <TableCell key={colx}>
            <Typography variant="body2">{get(item, dataKey, null)}</Typography>
          </TableCell>
        );
      });

      return <TableRow key={idx}>{cols}</TableRow>;
    });

    return <TableBody>{rows}</TableBody>;
  };

  if (isLoading) {
    return (
      <Table>
        {tableHead()}
        <TableBody>
          <TableRow>
            <TableCell colSpan={colSpan}>
              <DataLoader display="flex" alignItems="center" justifyContent="center" />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  }

  if (isDataEmpty) {
    return (
      <Table>
        {tableHead()}
        <TableBody>
          <TableRow>
            <TableCell colSpan={colSpan}>
              <DataAnnunciator title={t('nothing.found')} textAlign="center" />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  }

  return (
    <Table {...props}>
      {tableHead()}
      {tableBody()}
    </Table>
  );
};

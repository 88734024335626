import { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useEntityCreate } from '../../../../features/entities/hooks';
import { USER_INITIAL_VALUES } from '../../../../features/users/constants';
import { fetchUserCreate } from '../../../../features/users/thunks';
import { UserForm } from '../../../../features/users/components';
import { IUser } from '../../../../features/users/models';
import { CabinetContainer } from '../../../../components/cabinet-container';
import { PageTitle } from '../../../../components/page-title';
import { ROUTE_USERS } from '../../../../common/constants';
import sha1 from 'sha1';

export const UserCreatePage: FC = () => {
  const { t } = useTranslation();
  const { isLoading, handleValid } = useEntityCreate<IUser>({
    entity: 'user',
    thunk: fetchUserCreate,
    nextPath: ROUTE_USERS,
  });

  const handleSubmit = (fieldValues: IUser) => {
    const { password } = fieldValues;

    handleValid({
      ...fieldValues,
      password: sha1(password),
    });
  };

  return (
    <>
      <Helmet>
        <title>{t('user.create')}</title>
      </Helmet>
      <CabinetContainer>
        <PageTitle title={t('user.create.title')} mb={3} />
        <UserForm
          onValid={handleSubmit}
          isLoading={isLoading}
          defaultValues={USER_INITIAL_VALUES}
        />
      </CabinetContainer>
    </>
  );
};

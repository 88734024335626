import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Stack, Card, CardContent, Grid, CardHeader } from '@mui/material';
import { useEntityForm } from '../../../entities/hooks';
import { PhoneFieldController, TextFieldController } from '../../../../components/inputs';
import { LOCALE_CR, LOCALE_UZ, LOCALE_RU } from '../../../../common/constants';
import {
  REGIONAL_ADMINISTRATIONS_DIRECTOR,
  REGIONAL_ADMINISTRATIONS_REGION_ID,
  REGIONAL_ADMINISTRATIONS_START_TIME,
  REGIONAL_ADMINISTRATIONS_END_TIME,
  REGIONAL_ADMINISTRATIONS_ADDRESS,
  REGIONAL_ADMINISTRATIONS_EMAIL,
  REGIONAL_ADMINISTRATIONS_PHONE_NUMBER,
} from '../../constants';
import { IconClearAll, IconSave } from '../../../../common/icons';
import { IForm } from '../../../../common/models';
import { IRegionalAdministrations } from '../../models';
import { RegionalAdministrationsSchema } from '../../schema/regional-administrations.schema';
import { SelectRegionalAdministrationsController } from '../input';

export const RegionalAdministrationsForms: FC<IForm<IRegionalAdministrations>> = ({
  defaultValues,
  onValid,
  isLoading,
}) => {
  const { t } = useTranslation();
  const { control, onReset, onSubmit } = useEntityForm<IRegionalAdministrations>({
    schema: RegionalAdministrationsSchema,
    defaultValues,
    onValid,
  });

  return (
    <Box onSubmit={onSubmit} component="form">
      <Stack direction="row" justifyContent="flex-end" spacing={1.5} mb={2.5}>
        <Button
          type="reset"
          variant="contained"
          color="secondary"
          startIcon={<IconClearAll />}
          disabled={isLoading}
          onClick={onReset}
        >
          {t('action.clear')}
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          startIcon={<IconSave />}
          disabled={isLoading}
        >
          {t('action.save')}
        </Button>
      </Stack>
      <Card>
        <CardHeader title={t('regional.administrations.form.title')} />
        <CardContent>
          <Grid container spacing={2.5}>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <SelectRegionalAdministrationsController
                control={control}
                name={REGIONAL_ADMINISTRATIONS_REGION_ID}
                label={t('regional.administrations.field.region')}
                disabled={isLoading}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <TextFieldController
                control={control}
                name={REGIONAL_ADMINISTRATIONS_EMAIL}
                disabled={isLoading}
                label={t('regional.administrations.field.email')}
                type="email"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <PhoneFieldController
                control={control}
                name={REGIONAL_ADMINISTRATIONS_PHONE_NUMBER}
                label={t('regional.administrations.field.tel')}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <TextFieldController
                control={control}
                name={[REGIONAL_ADMINISTRATIONS_DIRECTOR, LOCALE_CR].join('.')}
                disabled={isLoading}
                label={t('regional.administrations.field.director.cr')}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <TextFieldController
                control={control}
                name={[REGIONAL_ADMINISTRATIONS_DIRECTOR, LOCALE_UZ].join('.')}
                disabled={isLoading}
                label={t('regional.administrations.field.director.uz')}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <TextFieldController
                control={control}
                name={[REGIONAL_ADMINISTRATIONS_DIRECTOR, LOCALE_RU].join('.')}
                disabled={isLoading}
                label={t('regional.administrations.field.director.ru')}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <TextFieldController
                control={control}
                name={[REGIONAL_ADMINISTRATIONS_ADDRESS, LOCALE_CR].join('.')}
                disabled={isLoading}
                label={t('regional.administrations.field.address.cr')}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <TextFieldController
                control={control}
                name={[REGIONAL_ADMINISTRATIONS_ADDRESS, LOCALE_UZ].join('.')}
                disabled={isLoading}
                label={t('regional.administrations.field.address.uz')}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <TextFieldController
                control={control}
                name={[REGIONAL_ADMINISTRATIONS_ADDRESS, LOCALE_RU].join('.')}
                disabled={isLoading}
                label={t('regional.administrations.field.address.ru')}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextFieldController
                control={control}
                name={REGIONAL_ADMINISTRATIONS_START_TIME}
                label={t('regional.administrations.field.start.time')}
                type="time"
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextFieldController
                control={control}
                name={REGIONAL_ADMINISTRATIONS_END_TIME}
                label={t('regional.administrations.field.end.time')}
                type="time"
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

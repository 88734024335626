import { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { fetchNewsRecord, fetchNewsUpdate, INews } from '../../../../features/news';
import { useEntityUpdate } from '../../../../features/entities/hooks';
import { PreloaderWrapper } from '../../../../components/preloader-wrapper';
import { PageTitle } from '../../../../components/page-title';
import { CabinetContainer } from '../../../../components/cabinet-container';
import { ENDPOINT_BASE_URL, ROUTE_NEWS } from '../../../../common/constants';
import { Box, Card, CardContent, ImageListItem, Typography } from '@mui/material';
import { get } from '../../../../common/utils';

export const NewsViewPage: FC = () => {
  const { t, i18n } = useTranslation();
  const { isFetching, data } = useEntityUpdate<INews>({
    entity: 'news',
    recordThunk: fetchNewsRecord,
    updateThunk: fetchNewsUpdate,
    nextPath: ROUTE_NEWS,
  });

  const { files } = data;

  const content = get(data, ['content_json', i18n.language]) || '';
  const title = get(data, ['title_json', i18n.language]) || '';

  return (
    <>
      <Helmet>
        <title>{t('news.view')}</title>
      </Helmet>
      <CabinetContainer>
        <PageTitle title={t('news.view.title')} mb={3} />
        <PreloaderWrapper isLoading={isFetching}>
          <Box component={Card} elevation={0} border="1px solid" borderColor="divider" py={2}>
            <CardContent>
              <Typography component="h1" variant="subtitle1" mb={3}>
                {title}
              </Typography>
              {files?.map((i: any, idx: number) => (
                <ImageListItem key={idx} sx={{ marginY: 2 }}>
                  <img
                    src={`${ENDPOINT_BASE_URL}/images/${i?.saved_name}`}
                    alt="alt"
                    style={{ width: '50%' }}
                    loading="lazy"
                  />
                </ImageListItem>
              ))}
              <div
                dangerouslySetInnerHTML={{
                  __html: content,
                }}
              />
            </CardContent>
          </Box>
        </PreloaderWrapper>
      </CabinetContainer>
    </>
  );
};

import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionButton } from '../../../../components/action-button';
import { IconSquareEditOutline } from '../../../../common/icons';
import { ROUTE_NEWS_UPDATE } from '../../../../common/constants';
import { url } from '../../../../common/utils';
import { INews } from '../../models';

interface NewsActionEditProps {
  id: INews['id'];
}

export const NewsActionEdit: FC<NewsActionEditProps> = ({ id }) => {
  const { t } = useTranslation();

  return (
    <ActionButton
      to={url(ROUTE_NEWS_UPDATE, { id })}
      title={t('action.edit')}
      icon={<IconSquareEditOutline />}
    />
  );
};

import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionButton } from '../../../../components/action-button';
import { IconSquareEditOutline } from '../../../../common/icons';
import { ROUTE_REGIONS_UPDATE } from '../../../../common/constants';
import { url } from '../../../../common/utils';
import { IRegions } from '../../models';

interface RegionsActionEditProps {
  id: IRegions['id'];
}

export const RegionsActionEdit: FC<RegionsActionEditProps> = ({ id }) => {
  const { t } = useTranslation();

  return (
    <ActionButton
      to={url(ROUTE_REGIONS_UPDATE, { id })}
      title={t('action.edit')}
      icon={<IconSquareEditOutline />}
    />
  );
};

import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { DialogBase, DialogProps } from '../dialog-base';
import { IconWaveEye } from '../../../common/icons';

interface DialogDeleteConfirmProps extends DialogProps {
  component: ReactNode;
}

export const DialogPreview: FC<DialogDeleteConfirmProps> = ({ title, component, ...props }) => {
  const { t } = useTranslation();
  const dialogTitle = title || t('preview');

  return (
    <DialogBase
      {...props}
      maxWidth="sm"
      title={dialogTitle}
      icon={<IconWaveEye sx={{ fontSize: 48 }} />}
    >
      <Box p={2.5} border="2px solid" borderColor="primary.main" borderRadius={1.5}>
        {component}
      </Box>
    </DialogBase>
  );
};

import { differenceInYears, format as formatFns, isValid } from 'date-fns';
import {
  FORMAT_DATE,
  FORMAT_DATE_TIME,
  FORMAT_ISO_DATE,
  FORMAT_ISO_DATE_TIME,
  FORMAT_TIME,
} from '../constants';
import { IOption } from '../models';

export const formatOriginDate = (date: Date, format: string) => {
  return formatFns(date, format);
};

export const formatDate = (date: string, format: string, defaultValue: string = '') => {
  if (!date || !isValid(new Date(date))) return defaultValue;
  return formatOriginDate(new Date(date), format);
};

export const formatDateClient = (date: string, defaultValue: string = '') => {
  return formatDate(date, FORMAT_DATE, defaultValue);
};

export const formatDateClientAndTime = (date: string, defaultValue: string = '') => {
  return formatDate(date, FORMAT_DATE_TIME, defaultValue);
};

export const formatTimeClient = (date: string, defaultValue: string = '') => {
  return formatDate(date, FORMAT_TIME, defaultValue);
};

export const formatDateServer = (date: string, defaultValue: string = '') => {
  return formatDate(date, FORMAT_ISO_DATE, defaultValue);
};

export const formatDateServerAndTime = (date: string) => {
  return formatDate(date, FORMAT_ISO_DATE_TIME);
};

export const getYearOptions = (start: number = 2023): IOption[] => {
  const options = [start];
  const diff = differenceInYears(new Date(), new Date(`01.01.${start}`));
  for (let i = 1; i <= diff; i++) {
    options.push(start + i);
  }
  return options.map((year) => ({
    label: String(year),
    value: year,
  }));
};

import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionButton } from '../../../../components/action-button';
import { useEntityDelete } from '../../../entities/hooks';
import { fetchNewsDelete } from '../../thunks';
import { IconDeleteCanOutline } from '../../../../common/icons';
import { ROUTE_NEWS } from '../../../../common/constants';
import { INews } from '../../models';

interface NewsActionDeleteProps {
  id: INews['id'];
}

export const NewsActionDelete: FC<NewsActionDeleteProps> = ({ id }) => {
  const { t } = useTranslation();
  const { handleDelete } = useEntityDelete({
    thunk: fetchNewsDelete,
    nextPath: ROUTE_NEWS,
    confirmationText: t('confirmation.delete.text'),
  });

  return (
    <ActionButton
      onClick={() => handleDelete(id)}
      title={t('action.delete')}
      icon={<IconDeleteCanOutline />}
    />
  );
};

import { createAsyncThunk } from '@reduxjs/toolkit';
import { OpenInfoApi } from '../http';

const openInfoApi = new OpenInfoApi();

export const fetchOpenInfoDelete = createAsyncThunk(
  'openInfo/delete',
  async (id: string, { rejectWithValue }) => {
    try {
      return await openInfoApi.removeOne({ id });
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

import { LOCALE_CR, LOCALE_EN, LOCALE_RU, LOCALE_UZ } from '../../../common/constants';
import { MANAGEMENT_OFFICER_NAME, MANAGEMENT_OFFICER_POSITION } from './management.constants';

export const MANAGEMENT_INITIAL_VALUES = {
  [MANAGEMENT_OFFICER_NAME]: {
    [LOCALE_UZ]: '',
    [LOCALE_CR]: '',
    [LOCALE_RU]: '',
    [LOCALE_EN]: '',
  },
  [MANAGEMENT_OFFICER_POSITION]: {
    [LOCALE_UZ]: '',
    [LOCALE_CR]: '',
    [LOCALE_RU]: '',
    [LOCALE_EN]: '',
  },
};

import { BaseApi } from '../../../common/axios';
import { ENDPOINT_AUTH_URL } from '../../../common/constants';
import { ENDPOINT_AUTH_LOGIN, ENDPOINT_AUTH_ME } from '../endpoints';
import { ILogin, ILoginQuery, IMe } from '../models';

export class AuthApi extends BaseApi {
  constructor() {
    super();
    this.baseUrl = ENDPOINT_AUTH_URL;
  }

  public login(body: ILoginQuery): Promise<ILogin> {
    return this.httpClient
      .post(this.url(ENDPOINT_AUTH_LOGIN), body)
      .then((response) => response.data);
  }

  public me(): Promise<IMe> {
    return this.httpClient.get(this.url(ENDPOINT_AUTH_ME)).then((response) => response.data);
  }
}

import { createAsyncThunk } from '@reduxjs/toolkit';
import { RegionalAdministrationsApi } from '../http';

const regionalAdministrationsApi = new RegionalAdministrationsApi();

export const fetchRegionalAdministrationsDelete = createAsyncThunk(
  'regionalAdministrations/delete',
  async (id: string, { rejectWithValue }) => {
    try {
      return await regionalAdministrationsApi.removeOne({ id });
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

import { BaseApi } from '../../../common/axios';
import {
  IReportByStatusQuery,
  IReportByStatus,
  IReportByRegion,
  IReportByYear,
  IReportByYearQuery,
} from '../models';
import { ENDPOINT_BASE_URL } from '../../../common/constants';
import {
  ENDPOINT_REPORT_BY_REGION,
  ENDPOINT_REPORT_BY_STATUS,
  ENDPOINT_REPORT_BY_YEAR,
} from '../endpoints';

export class ReportApi extends BaseApi {
  constructor() {
    super();
    this.baseUrl = ENDPOINT_BASE_URL;
  }

  public byStatus(body: IReportByStatusQuery): Promise<IReportByStatus> {
    return this.httpClient
      .post(this.url(ENDPOINT_REPORT_BY_STATUS), body)
      .then((response) => response.data);
  }

  public byRegion(): Promise<IReportByRegion[]> {
    return this.httpClient
      .post(this.url(ENDPOINT_REPORT_BY_REGION))
      .then((response) => response.data);
  }

  public byYear(body: IReportByYearQuery): Promise<IReportByYear[]> {
    return this.httpClient
      .post(this.url(ENDPOINT_REPORT_BY_YEAR), body)
      .then((response) => response.data);
  }
}

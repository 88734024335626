import { createAsyncThunk } from '@reduxjs/toolkit';
import { FAQApi } from '../http';

const faqApi = new FAQApi();

export const fetchFAQDelete = createAsyncThunk(
  'faq/delete',
  async (id: string, { rejectWithValue }) => {
    try {
      return await faqApi.removeOne({ id });
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

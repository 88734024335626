import { FC } from 'react';
import { Box, Card } from '@mui/material';
import { DataTable, DataTableProps } from './DataTable';

export const DataTableCard: FC<DataTableProps> = (props) => {
  return (
    <Box component={Card} elevation={0} border="1px solid" borderColor="divider">
      <DataTable {...props} />
    </Box>
  );
};

import * as yup from 'yup';
import {
  LOCALE_CR,
  LOCALE_EN,
  LOCALE_RU,
  LOCALE_UZ,
  MSG_ERROR_REQUIRED_FIELD,
} from '../../../common/constants';
import { REGIONS_NAME, REGIONS_CODE } from '../constants';

const LocalizedFieldSchema = yup.object().shape({
  [LOCALE_CR]: yup.string().required(MSG_ERROR_REQUIRED_FIELD),
  [LOCALE_UZ]: yup.string(),
  [LOCALE_RU]: yup.string(),
  [LOCALE_EN]: yup.string(),
});

export const RegionsSchema = yup.object().shape({
  [REGIONS_NAME]: LocalizedFieldSchema,
  [REGIONS_CODE]: yup.string().required(MSG_ERROR_REQUIRED_FIELD),
});

import { createSlice } from '@reduxjs/toolkit';
import { IRegionalAdministrations } from '../models';
import { IData, ISlice } from '../../../common/models';
import {
  fetchRegionalAdministrationsAll,
  fetchRegionalAdministrationsCatalog,
  fetchRegionalAdministrationsCreate,
  fetchRegionalAdministrationsRecord,
  fetchRegionalAdministrationsUpdate,
} from '../thunks';

interface ISliceWithAll
  extends ISlice<IData<IRegionalAdministrations[]>, IRegionalAdministrations> {
  all: {
    isLoading: boolean;
    result: IData<IRegionalAdministrations[]> | null;
    error: any;
  };
}

const initialState: ISliceWithAll = {
  catalog: {
    isLoading: false,
    result: null,
    error: null,
  },
  record: {
    isLoading: false,
    result: null,
    error: null,
  },
  create: {
    isLoading: false,
    error: null,
  },
  update: {
    isLoading: false,
    error: null,
  },
  all: {
    isLoading: false,
    result: null,
    error: null,
  },
};

export const regionalAdministrationsSlice = createSlice({
  name: 'regionalAdministrations',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchRegionalAdministrationsCatalog.pending, (state) => {
      state.catalog = {
        ...state.catalog,
        isLoading: true,
        result: null,
        error: null,
      };
    });
    builder.addCase(fetchRegionalAdministrationsCatalog.fulfilled, (state, { payload }) => {
      state.catalog = {
        ...state.catalog,
        isLoading: false,
        result: payload,
        error: null,
      };
    });
    builder.addCase(fetchRegionalAdministrationsCatalog.rejected, (state, { payload }) => {
      state.catalog = {
        ...state.catalog,
        isLoading: false,
        result: null,
        error: payload,
      };
    });
    builder.addCase(fetchRegionalAdministrationsRecord.pending, (state) => {
      state.record = {
        isLoading: true,
        result: null,
        error: null,
      };
    });
    builder.addCase(fetchRegionalAdministrationsRecord.fulfilled, (state, { payload }) => {
      state.record = {
        ...state.record,
        isLoading: false,
        result: payload,
        error: null,
      };
    });
    builder.addCase(fetchRegionalAdministrationsRecord.rejected, (state, { payload }) => {
      state.record = {
        ...state.record,
        isLoading: false,
        result: null,
        error: payload,
      };
    });
    builder.addCase(fetchRegionalAdministrationsCreate.pending, (state) => {
      state.create = {
        ...state.create,
        isLoading: true,
        error: null,
      };
    });
    builder.addCase(fetchRegionalAdministrationsCreate.fulfilled, (state) => {
      state.create = {
        ...state.create,
        isLoading: false,
        error: null,
      };
    });
    builder.addCase(fetchRegionalAdministrationsCreate.rejected, (state, { payload }) => {
      state.create = {
        ...state.create,
        isLoading: false,
        error: payload,
      };
    });
    builder.addCase(fetchRegionalAdministrationsUpdate.pending, (state) => {
      state.update = {
        ...state.update,
        isLoading: true,
        error: null,
      };
    });
    builder.addCase(fetchRegionalAdministrationsUpdate.fulfilled, (state) => {
      state.update = {
        ...state.update,
        isLoading: false,
        error: null,
      };
    });
    builder.addCase(fetchRegionalAdministrationsUpdate.rejected, (state, { payload }) => {
      state.update = {
        ...state.update,
        isLoading: false,
        error: payload,
      };
    });
    builder.addCase(fetchRegionalAdministrationsAll.pending, (state) => {
      state.all = {
        ...state.all,
        isLoading: true,
        result: null,
        error: null,
      };
    });
    builder.addCase(fetchRegionalAdministrationsAll.fulfilled, (state, { payload }) => {
      state.all = {
        ...state.all,
        isLoading: false,
        result: payload,
        error: null,
      };
    });
    builder.addCase(fetchRegionalAdministrationsAll.rejected, (state, { payload }) => {
      state.all = {
        ...state.all,
        isLoading: false,
        result: null,
        error: payload,
      };
    });
  },
});

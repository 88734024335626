import { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useEntityCreate } from '../../../../features/entities/hooks';
import { FAQForms, fetchFAQCreate, IFAQ, FAQ_INITIAL_VALUES } from '../../../../features/faq';
import { CabinetContainer } from '../../../../components/cabinet-container';
import { ROUTE_FAQ } from '../../../../common/constants';
import { PageTitle } from '../../../../components/page-title';

export const FAQCreatePage: FC = () => {
  const { t } = useTranslation();
  const { isLoading, handleValid } = useEntityCreate<IFAQ>({
    entity: 'faq',
    thunk: fetchFAQCreate,
    nextPath: ROUTE_FAQ,
  });

  return (
    <>
      <Helmet>
        <title>{t('faq.create')}</title>
      </Helmet>
      <CabinetContainer>
        <PageTitle title={t('faq.create.title')} mb={3} />
        <FAQForms onValid={handleValid} isLoading={isLoading} defaultValues={FAQ_INITIAL_VALUES} />
      </CabinetContainer>
    </>
  );
};

import { createSlice } from '@reduxjs/toolkit';
import { IData, ISlice } from '../../../common/models';
import { IAccount } from '../models';
import { fetchAccountUpdatePassword } from '../thunks';
import { fetchAccountGetUser, fetchAccountUpdate } from '../thunks';

const initialState: ISlice<IData<IAccount[]>, IAccount> = {
  catalog: {
    isLoading: false,
    result: null,
    error: null,
  },
  record: {
    isLoading: false,
    result: null,
    error: null,
  },
  create: {
    isLoading: false,
    error: null,
  },
  update: {
    isLoading: false,
    error: null,
  },
};

export const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAccountGetUser.pending, (state) => {
      state.record = {
        isLoading: true,
        result: null,
        error: null,
      };
    });
    builder.addCase(fetchAccountGetUser.fulfilled, (state, { payload }) => {
      state.record = {
        ...state.record,
        isLoading: false,
        result: payload,
      };
    });
    builder.addCase(fetchAccountGetUser.rejected, (state, { payload }) => {
      state.record = {
        ...state.record,
        isLoading: false,
        error: payload,
      };
    });
    builder.addCase(fetchAccountUpdate.pending, (state) => {
      state.update = {
        ...state.update,
        isLoading: true,
        error: null,
      };
    });
    builder.addCase(fetchAccountUpdate.fulfilled, (state) => {
      state.update = {
        ...state.update,
        isLoading: false,
        error: null,
      };
    });
    builder.addCase(fetchAccountUpdate.rejected, (state, { payload }) => {
      state.update = {
        ...state.update,
        isLoading: false,
        error: payload,
      };
    });
    builder.addCase(fetchAccountUpdatePassword.pending, (state) => {
      state.update = {
        ...state.update,
        isLoading: true,
        error: null,
      };
    });
    builder.addCase(fetchAccountUpdatePassword.fulfilled, (state) => {
      state.update = {
        ...state.update,
        isLoading: false,
        error: null,
      };
    });
    builder.addCase(fetchAccountUpdatePassword.rejected, (state, { payload }) => {
      state.update = {
        ...state.update,
        isLoading: false,
        error: payload,
      };
    });
  },
});

import { createAsyncThunk } from '@reduxjs/toolkit';
import { AccountApi } from '../http/account-api';

const accountApi = new AccountApi();

export const fetchAccountGetUser = createAsyncThunk(
  'account/getUser',
  async (_, { rejectWithValue }) => {
    try {
      return await accountApi.getUser();
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

import { BaseApi } from '../../../common/axios';
import { IFile } from '../models';
import { ENDPOINT_FILE_URL } from '../../../common/constants';
import { ENDPOINT_FILE_UPLOAD } from '../endpoints';

export class FileApi extends BaseApi {
  constructor() {
    super();
    this.baseUrl = ENDPOINT_FILE_URL;
  }

  public uploadOne(body: FormData): Promise<IFile> {
    return this.httpClient
      .post(this.url(ENDPOINT_FILE_UPLOAD), body)
      .then((response) => response.data);
  }
}

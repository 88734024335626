import { FC, PropsWithChildren } from 'react';
import { Box, BoxProps } from '@mui/material';

export const CabinetContainer: FC<PropsWithChildren<BoxProps>> = ({ children, ...props }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      flex={2}
      width="100%"
      py={{ xs: 4, md: 5, lg: 6 }}
      px={{ xs: 5, md: 7, lg: 9 }}
      {...props}
    >
      {children}
    </Box>
  );
};

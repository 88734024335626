import { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import {
  RegionalAdministrationsForms,
  fetchRegionalAdministrationsRecord,
  fetchRegionalAdministrationsUpdate,
  IRegionalAdministrations,
} from '../../../../features/regional-administrations';
import { useEntityUpdate } from '../../../../features/entities/hooks';
import { PreloaderWrapper } from '../../../../components/preloader-wrapper';
import { PageTitle } from '../../../../components/page-title';
import { CabinetContainer } from '../../../../components/cabinet-container';
import { ROUTE_REGIONAL_ADMINISTRATIONS } from '../../../../common/constants';
import { get } from '../../../../common/utils';

export const RegionalAdministrationsUpdatePage: FC = () => {
  const { t } = useTranslation();
  const { isFetching, isUpdating, data, handleValid } = useEntityUpdate<IRegionalAdministrations>({
    entity: 'regionalAdministrations',
    recordThunk: fetchRegionalAdministrationsRecord,
    updateThunk: fetchRegionalAdministrationsUpdate,
    nextPath: ROUTE_REGIONAL_ADMINISTRATIONS,
  });

  const defaultValues = { ...data };

  return (
    <>
      <Helmet>
        <title>{t('regional.administrations.update')}</title>
      </Helmet>
      <CabinetContainer>
        <PageTitle title={t('regional.administrations.update.title')} mb={3} />
        <PreloaderWrapper isLoading={isFetching}>
          <RegionalAdministrationsForms
            onValid={handleValid}
            defaultValues={defaultValues}
            isLoading={isUpdating}
            key={get(data, 'id')}
          />
        </PreloaderWrapper>
      </CabinetContainer>
    </>
  );
};

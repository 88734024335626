import { FC } from 'react';
import { InputAdornment } from '@mui/material';
import { NumericFieldController, NumericFieldControllerProps } from '../numeric-filed-controller';

export const PhoneFieldController: FC<Omit<NumericFieldControllerProps, 'format'>> = (props) => {
  return (
    <NumericFieldController
      {...props}
      InputProps={{
        startAdornment: <InputAdornment position="start">+</InputAdornment>,
      }}
      type="tel"
    />
  );
};

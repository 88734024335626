import { ChangeEvent, FC, SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Stack,
  Tab,
  Tabs,
  Card,
  CardContent,
  Grid,
  TextField,
  ImageListItem,
} from '@mui/material';
import { get } from 'react-hook-form';
import { useEntityForm } from '../../../entities/hooks';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { DateFieldController, TextFieldController } from '../../../../components/inputs';
import { LOCALE_CR, LOCALE_UZ, LOCALE_RU, ENDPOINT_BASE_URL } from '../../../../common/constants';
import {
  NEWS_FIELD_CONTENT,
  NEWS_FIELD_FILE,
  NEWS_FIELD_FILES,
  NEWS_FIELD_TITLE,
  NEWS_FIELD_TYPE,
  NEWS_PUBLISH_DATE,
} from '../../constants';
import { useFiles } from '../../../files';
import { IconClearAll, IconSave } from '../../../../common/icons';
import { useSnackbar } from '../../../../common/hooks';
import { IForm } from '../../../../common/models';
import { NewsSchema } from '../../schema';
import { INews } from '../../models';
import { SelectNewsController } from '../input';

export const NewsForms: FC<IForm<INews>> = ({ defaultValues, onValid, isLoading }) => {
  const { t } = useTranslation();
  const { showMessage } = useSnackbar();
  const { handleInputUpload } = useFiles();
  const [activeLang, setActiveLang] = useState(LOCALE_CR);
  const [files, setFiles] = useState<any>(defaultValues?.files);
  const { control, onReset, onSubmit, getValues, setValue } = useEntityForm<INews>({
    schema: NewsSchema,
    defaultValues,
    onValid,
  });

  const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
    handleInputUpload(e)
      .then((res: any) => {
        setValue(NEWS_FIELD_FILE, res?.id);
        setValue(NEWS_FIELD_FILES, [...files, res]);
        setFiles([...files, res]);
      })
      .catch((e) => {
        const message = get(e, 'response.data.message', t('error'));
        showMessage(message, { variant: 'filled', severity: 'error' });
      });
  };
  const handleDeleteUploadImage = (i: any) => {
    const newFiles = files.filter((file: any) => file !== i);
    setValue(NEWS_FIELD_FILES, newFiles);
    setFiles(newFiles);
  };

  const handleChangeTab = (e: SyntheticEvent, newValue: string) => {
    setActiveLang(newValue);
  };

  const steps: { label: string; language: string }[] = [
    {
      label: t('news.cr'),
      language: LOCALE_CR,
    },
    {
      label: t('news.uz'),
      language: LOCALE_UZ,
    },
    {
      label: t('news.ru'),
      language: LOCALE_RU,
    },
    // {
    //   label: t('news.en'),
    //   language: LOCALE_EN,
    // },
  ];

  return (
    <Box onSubmit={onSubmit} component="form">
      <Stack direction="row" justifyContent="space-between" spacing={1.5} mb={2.5}>
        <Tabs
          onChange={handleChangeTab}
          value={activeLang}
          variant="scrollable"
          scrollButtons="auto"
        >
          {steps.map(({ label, language }, idx) => (
            <Tab label={label} value={language} key={idx} />
          ))}
        </Tabs>
        <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1.5}>
          <Button
            type="reset"
            variant="contained"
            color="secondary"
            startIcon={<IconClearAll />}
            disabled={isLoading}
            onClick={onReset}
          >
            {t('action.clear')}
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            startIcon={<IconSave />}
            disabled={isLoading}
          >
            {t('action.save')}
          </Button>
        </Stack>
      </Stack>
      <Grid container spacing={2}>
        <Grid item xs={9}>
          <Card>
            <CardContent>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <TextFieldController
                    control={control}
                    name={[NEWS_FIELD_TITLE, activeLang].join('.')}
                    disabled={isLoading}
                    label={t('news.field.title')}
                    key={activeLang}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      '& .ck-editor__editable_inline': {
                        minHeight: 400,
                      },
                    }}
                  >
                    <CKEditor
                      control={control}
                      data={getValues([NEWS_FIELD_CONTENT, activeLang].join('.'))}
                      editor={ClassicEditor}
                      placeholder={t('news.field.content')}
                      key={activeLang}
                      config={{
                        toolbar: [
                          'bold',
                          'italic',
                          'link',
                          'bulletedList',
                          'numberedList',
                          'img',
                          'alignItems',
                        ],
                      }}
                      onChange={(event: any, editor: any) => {
                        const data = editor.getData();
                        setValue([NEWS_FIELD_CONTENT, activeLang].join('.'), data);
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Box component={Grid} item xs={3}>
          <Box component={Card} p={3}>
            <Stack spacing={4}>
              <SelectNewsController
                control={control}
                name={NEWS_FIELD_TYPE}
                label={t('news.field.type')}
                disabled={isLoading}
                fullWidth
              />
              <DateFieldController
                control={control}
                name={NEWS_PUBLISH_DATE}
                disabled={isLoading}
                label={t('news.field.publish.date')}
                fullWidth
              />
              <TextField
                onChange={handleFileUpload}
                label={t('news.field.files')}
                type="file"
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                fullWidth
              />
              {files?.map((i: any, idx: number) => (
                <ImageListItem
                  key={idx}
                  onClick={() => handleDeleteUploadImage(i)}
                  sx={{ cursor: 'pointer' }}
                >
                  <img
                    src={`${ENDPOINT_BASE_URL}/images/${i?.saved_name}`}
                    alt="alt"
                    loading="lazy"
                  />
                </ImageListItem>
              ))}
            </Stack>
          </Box>
        </Box>
      </Grid>
    </Box>
  );
};

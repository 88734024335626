import { FC, PropsWithChildren, useState } from 'react';
import { PageMetricContext } from '../contexts';
import { IPageMetric } from '../models';

export const PageMetricProvider: FC<PropsWithChildren<any>> = ({ children }) => {
  const [pageMetric, setPageMetric] = useState<IPageMetric>({});

  return (
    <PageMetricContext.Provider value={{ pageMetric, setPageMetric }}>
      {children}
    </PageMetricContext.Provider>
  );
};

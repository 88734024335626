import { createAsyncThunk } from '@reduxjs/toolkit';
import { UserApi } from '../http';
import { IUserQuery } from '../models';

const userApi = new UserApi();

export const fetchUserCatalog = createAsyncThunk(
  'user/catalog',
  async (body: IUserQuery, { rejectWithValue }) => {
    try {
      const data = await userApi.findAll(body);
      return { data, page: 1, limit: 9999, total: data.length };
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

import { generatePath } from 'react-router';
import { RouteObject } from 'react-router-dom';

export const url = (url: string, params?: any) => {
  return generatePath(url, params);
};

export const localizeRoutes = (routes: RouteObject[], locale: string = '/') => {
  const localizedRoutes: any = routes.map((route) => {
    const newRoute = { ...route };
    if (route.path) {
      newRoute.path = `/${locale}${route.path}`;
    }
    if (route.children) {
      newRoute.children = localizeRoutes(route.children, locale);
    }
    return newRoute;
  });

  return localizedRoutes;
};

export const replaceObjectKeys = (obj: any, str: string) => {
  for (const key in obj) {
    if (obj[key]) {
      str = str.replace(obj[key], `:${key}`);
    }
  }
  return str;
};

import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IRegions } from '../../models';
import { RegionsPreview } from '../regions-preview';
import { ActionButton } from '../../../../components/action-button';
import { DialogPreview } from '../../../../components/dialogs';
import { useDialog } from '../../../../common/hooks';
import { IconEye } from '../../../../common/icons';

interface RegionsActionViewProps {
  data: IRegions;
}
export const RegionsActionView: FC<RegionsActionViewProps> = ({ data }) => {
  const { t } = useTranslation();
  const [openPreview] = useDialog(DialogPreview);

  const handlePreview = () => {
    openPreview({
      title: t('regions.form.title'),
      component: <RegionsPreview data={data} />,
    });
  };

  return <ActionButton onClick={handlePreview} title={t('action.view')} icon={<IconEye />} />;
};

import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IUser } from '../../models';
import { UsersPreview } from '../users-preview';
import { DialogPreview } from '../../../../components/dialogs';
import { useDialog } from '../../../../common/hooks';
import { IconEye } from '../../../../common/icons';
import { ActionButton } from '../../../../components/action-button';

interface UserActionViewProps {
  data: IUser;
}

export const UsersActionView: FC<UserActionViewProps> = ({ data }) => {
  const { t } = useTranslation();
  const [openPreview] = useDialog(DialogPreview);

  const handlePreview = () => {
    openPreview({
      title: t('users.form.title'),
      component: <UsersPreview data={data} />,
    });
  };

  return <ActionButton onClick={handlePreview} title={t('action.view')} icon={<IconEye />} />;
};

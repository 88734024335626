import { createAsyncThunk } from '@reduxjs/toolkit';
import { ReportApi } from '../http';
import { IReportByStatusQuery } from '../models';

const reportApi = new ReportApi();

export const fetchReportByStatus = createAsyncThunk(
  'report/byStatus',
  async (body: IReportByStatusQuery, { rejectWithValue }) => {
    try {
      const data = await reportApi.byStatus(body);
      return { data };
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

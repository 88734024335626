import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionButton } from '../../../../components/action-button';
import { IconSquareEditOutline } from '../../../../common/icons';
import { ROUTE_EMAIL_UPDATE } from '../../../../common/constants';
import { url } from '../../../../common/utils';
import { IEmail } from '../../models';

interface EmailActionEditProps {
  id: IEmail['id'];
}

export const EmailActionEdit: FC<EmailActionEditProps> = ({ id }) => {
  const { t } = useTranslation();

  return (
    <ActionButton
      to={url(ROUTE_EMAIL_UPDATE, { id })}
      title={t('action.edit')}
      icon={<IconSquareEditOutline />}
    />
  );
};

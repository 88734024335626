import { FC } from 'react';
import { Localize } from '../../../../../components/localize';
import { useEntityList } from '../../../../entities/hooks';
import { fetchRegionsCatalog, IRegions } from '../../../../regions';
import {
  SelectFieldControllerProps,
  SelectFieldController,
} from '../../../../../components/inputs';

interface SelectRegionalAdministrationsControllerProps
  extends Omit<SelectFieldControllerProps, 'options'> {}

export const SelectRegionalAdministrationsController: FC<
  SelectRegionalAdministrationsControllerProps
> = ({ type, ...props }) => {
  const { data, isLoading } = useEntityList({
    entity: 'regions',
    thunk: fetchRegionsCatalog,
    defaultParams: { limit: 14 },
  });

  const options = data.map(({ id, name_json }: IRegions) => ({
    value: id,
    label: <Localize data={name_json ? name_json : '-'} />,
  }));
  console.log(options);
  if (!options.length || isLoading) {
    return <SelectFieldController {...props} options={[]} disabled />;
  }

  return <SelectFieldController {...props} options={options} />;
};

import { createAsyncThunk } from '@reduxjs/toolkit';
import { FAQApi } from '../http';
import { IFAQQuery } from '../models';

const faqApi = new FAQApi();

export const fetchFAQCatalog = createAsyncThunk(
  'faq/catalog',
  async (body: IFAQQuery, { rejectWithValue }) => {
    try {
      const data = await faqApi.findAll(body);
      return { data, page: 1, limit: 9999, total: data.length };
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

import { createSlice } from '@reduxjs/toolkit';
import { INews } from '../models';
import { IData, ISlice } from '../../../common/models';
import {
  fetchNewsAll,
  fetchNewsCatalog,
  fetchNewsCreate,
  fetchNewsRecord,
  fetchNewsUpdate,
} from '../thunks';

interface ISliceWithAll extends ISlice<IData<INews[]>, INews> {
  all: {
    isLoading: boolean;
    result: IData<INews[]> | null;
    error: any;
  };
}

const initialState: ISliceWithAll = {
  catalog: {
    isLoading: false,
    result: null,
    error: null,
  },
  record: {
    isLoading: false,
    result: null,
    error: null,
  },
  create: {
    isLoading: false,
    error: null,
  },
  update: {
    isLoading: false,
    error: null,
  },
  all: {
    isLoading: false,
    result: null,
    error: null,
  },
};

export const newsSlice = createSlice({
  name: 'news',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchNewsCatalog.pending, (state) => {
      state.catalog = {
        ...state.catalog,
        isLoading: true,
        result: null,
        error: null,
      };
    });
    builder.addCase(fetchNewsCatalog.fulfilled, (state, { payload }) => {
      state.catalog = {
        ...state.catalog,
        isLoading: false,
        result: payload,
        error: null,
      };
    });
    builder.addCase(fetchNewsCatalog.rejected, (state, { payload }) => {
      state.catalog = {
        ...state.catalog,
        isLoading: false,
        result: null,
        error: payload,
      };
    });
    builder.addCase(fetchNewsRecord.pending, (state) => {
      state.record = {
        isLoading: true,
        result: null,
        error: null,
      };
    });
    builder.addCase(fetchNewsRecord.fulfilled, (state, { payload }) => {
      state.record = {
        ...state.record,
        isLoading: false,
        result: payload,
        error: null,
      };
    });
    builder.addCase(fetchNewsRecord.rejected, (state, { payload }) => {
      state.record = {
        ...state.record,
        isLoading: false,
        result: null,
        error: payload,
      };
    });
    builder.addCase(fetchNewsCreate.pending, (state) => {
      state.create = {
        ...state.create,
        isLoading: true,
        error: null,
      };
    });
    builder.addCase(fetchNewsCreate.fulfilled, (state) => {
      state.create = {
        ...state.create,
        isLoading: false,
        error: null,
      };
    });
    builder.addCase(fetchNewsCreate.rejected, (state, { payload }) => {
      state.create = {
        ...state.create,
        isLoading: false,
        error: payload,
      };
    });
    builder.addCase(fetchNewsUpdate.pending, (state) => {
      state.update = {
        ...state.update,
        isLoading: true,
        error: null,
      };
    });
    builder.addCase(fetchNewsUpdate.fulfilled, (state) => {
      state.update = {
        ...state.update,
        isLoading: false,
        error: null,
      };
    });
    builder.addCase(fetchNewsUpdate.rejected, (state, { payload }) => {
      state.update = {
        ...state.update,
        isLoading: false,
        error: payload,
      };
    });
    builder.addCase(fetchNewsAll.pending, (state) => {
      state.all = {
        ...state.all,
        isLoading: true,
        result: null,
        error: null,
      };
    });
    builder.addCase(fetchNewsAll.fulfilled, (state, { payload }) => {
      state.all = {
        ...state.all,
        isLoading: false,
        result: payload,
        error: null,
      };
    });
    builder.addCase(fetchNewsAll.rejected, (state, { payload }) => {
      state.all = {
        ...state.all,
        isLoading: false,
        result: null,
        error: payload,
      };
    });
  },
});

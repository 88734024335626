import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
} from '@mui/material';
import { useEntityList } from '../../../../features/entities/hooks';
import { OPEN_INFO_COLUMNS, fetchOpenInfoCatalog } from '../../../../features/open-info';
import { DataPagination, DataTableCard } from '../../../../components/data';
import { CabinetContainer } from '../../../../components/cabinet-container';
import { PageTitle } from '../../../../components/page-title';
import { DEFAULT_LIMIT, ROUTE_OPEN_INFO_CREATE } from '../../../../common/constants';

export const OpenInfoPage: FC = () => {
  const { t } = useTranslation();
  const [type, setType] = useState<string>('OPEN');
  const { data, isLoading, page, pagesCount, handleChangePage } = useEntityList({
    entity: 'openInfo',
    thunk: fetchOpenInfoCatalog,
    defaultParams: { limit: DEFAULT_LIMIT, type },
    dependencies: [type],
  });

  const handleChange = (event: SelectChangeEvent) => {
    setType(event.target.value);
  };

  return (
    <>
      <Helmet>
        <title>{t('open.info')}</title>
      </Helmet>
      <CabinetContainer>
        <PageTitle title={t('open.info.list.title')} mb={3} />
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          alignItems={{ xs: 'flex-start', sm: 'center' }}
          justifyContent={{ xs: 'flex-start', sm: 'flex-end' }}
          spacing={2}
          mb={4}
        >
          <Box>
            <FormControl fullWidth>
              <InputLabel>{t('open.info.field.type')}</InputLabel>
              <Select value={type} label={t('open.info.field.type')} onChange={handleChange}>
                <MenuItem value={'OPEN'}>{t('open.info.open.data')}</MenuItem>
                <MenuItem value={'DEPARTMENT'}>{t('open.info.needed.docs')}</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Button component={Link} to={ROUTE_OPEN_INFO_CREATE} color="primary" variant="contained">
            {t('open.info.create')}
          </Button>
        </Stack>
        <DataTableCard
          data={data}
          columns={OPEN_INFO_COLUMNS}
          isLoading={isLoading}
          page={page}
          showIndex
        />
      </CabinetContainer>
      <DataPagination count={pagesCount} page={page} onChange={handleChangePage} mt={5} container />
    </>
  );
};

import React, { FC } from 'react';
import { Typography } from '@mui/material';

interface PageTitleProps {
  title: string;
  mb?: number;
}

export const PageTitle: FC<PageTitleProps> = ({ title, mb = 0 }) => {
  return (
    <Typography component="h1" variant="subtitle1" mb={mb}>
      {title}
    </Typography>
  );
};

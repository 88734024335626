import { FC } from 'react';
import { Box, BoxProps, CircularProgress } from '@mui/material';

export const DataLoader: FC<BoxProps> = (props) => {
  return (
    <Box {...props}>
      <CircularProgress color="info" />
    </Box>
  );
};

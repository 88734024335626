import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import { RecordRow } from '../../../../components/record-row';
import {
  REGIONAL_ADMINISTRATIONS_DIRECTOR,
  REGIONAL_ADMINISTRATIONS_START_TIME,
} from '../../constants';
import { LOCALE_CR } from '../../../../common/constants';
import { get } from '../../../../common/utils';
import { IRegionalAdministrations } from '../../models';

interface RegionalAdministrationsPreviewProps {
  data: IRegionalAdministrations;
}

export const RegionalAdministrationsPreview: FC<RegionalAdministrationsPreviewProps> = ({
  data,
}) => {
  const { t, i18n } = useTranslation();

  return (
    <Stack spacing={1.5}>
      <Typography variant="subtitle1" component="div">
        {get(data, [REGIONAL_ADMINISTRATIONS_DIRECTOR, i18n.language]) ||
          get(data, [REGIONAL_ADMINISTRATIONS_DIRECTOR, LOCALE_CR])}
      </Typography>
      <RecordRow
        label={t('regional.administrations.field.start.time')}
        value={get(data, REGIONAL_ADMINISTRATIONS_START_TIME)}
      />
    </Stack>
  );
};

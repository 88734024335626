import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { Stack } from '@mui/material';
import { HeaderDefault } from '../components/header';
import { useSidebar } from '../common/hooks';
import { Sidebar } from '../components/sidebar';
import { withAuthorized } from '../features/auth/hocs';

export const LayoutCabinet: FC = withAuthorized(() => {
  const { collapsed } = useSidebar();

  return (
    <Stack direction="row" flex={2} position="relative" maxWidth="100%">
      <Sidebar />
      <Stack
        component="main"
        flex={2}
        maxWidth={{ xs: '100%', lg: collapsed ? '100%' : 'calc(100% - 280px)' }}
      >
        <HeaderDefault />
        <Outlet />
      </Stack>
    </Stack>
  );
});

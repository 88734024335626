import { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useEntityCreate } from '../../../../features/entities/hooks';
import {
  RegionsForms,
  fetchRegionsCreate,
  IRegions,
  REGIONS_INITIAL_VALUES,
} from '../../../../features/regions';
import { CabinetContainer } from '../../../../components/cabinet-container';
import { ROUTE_REGIONS } from '../../../../common/constants';
import { PageTitle } from '../../../../components/page-title';

export const RegionsCreatePage: FC = () => {
  const { t } = useTranslation();
  const { isLoading, handleValid } = useEntityCreate<IRegions>({
    entity: 'regions',
    thunk: fetchRegionsCreate,
    nextPath: ROUTE_REGIONS,
  });

  return (
    <>
      <Helmet>
        <title>{t('regions.create')}</title>
      </Helmet>
      <CabinetContainer>
        <PageTitle title={t('regions.create.title')} mb={3} />
        <RegionsForms
          onValid={handleValid}
          isLoading={isLoading}
          defaultValues={REGIONS_INITIAL_VALUES}
        />
      </CabinetContainer>
    </>
  );
};

import { TypographyOptions } from '@mui/material/styles/createTypography';

export const typography: TypographyOptions = {
  fontFamily: ['Inter', 'Roboto', '"Helvetica"', 'Arial', 'sans-serif'].join(','),
  fontSize: 14,
  h1: {
    fontSize: 32,
    fontWeight: 700,
    lineHeight: 1.25,
  },
  h2: {
    fontSize: 28,
    fontWeight: 700,
    lineHeight: 1.25,
  },
  h3: {
    fontSize: 24,
    fontWeight: 700,
    lineHeight: 1.25,
  },
  h4: {
    fontSize: 20,
    fontWeight: 700,
    lineHeight: 1.33,
  },
  h5: {
    fontSize: 18,
    fontWeight: 700,
    lineHeight: 1.375,
  },
  h6: {
    fontSize: 16,
    fontWeight: 700,
    lineHeight: 1.42,
  },
  subtitle1: {
    fontSize: 24,
    fontWeight: 600,
    lineHeight: 1.25,
  },
  subtitle2: {
    fontSize: 22,
    fontWeight: 600,
    lineHeight: 1.33,
  },
  subtitle3: {
    fontSize: 20,
    fontWeight: 600,
    lineHeight: 1.33,
  },
  subtitle4: {
    fontSize: 18,
    fontWeight: 600,
    lineHeight: 1.375,
  },
  body1: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: 1.5,
  },
  body2: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 1.5,
  },
  caption: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 1.5,
  },
  overline: {
    fontSize: 11,
    fontWeight: 400,
    lineHeight: 1.5,
  },
  button: {
    fontSize: 15,
    fontWeight: 500,
    lineHeight: 1.75,
    textTransform: 'none',
  },
};

export default typography;

import { FC, useEffect } from 'react';
import { useLogout } from '../../features/auth/hooks';
import { LogoutProcess } from '../../features/auth/components';

export const LogoutPage: FC = () => {
  const { handleLogout } = useLogout();

  useEffect(() => {
    handleLogout();
  }, [handleLogout]);

  return <LogoutProcess />;
};

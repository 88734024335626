import * as yup from 'yup';
import { MSG_ERROR_REQUIRED_FIELD, MSG_ERROR_TEL_LENGTH } from '../../../common/constants';
import {
  EMAIL_FIELD_FULL_NAME,
  EMAIL_FIELD_EMAIL,
  EMAIL_FIELD_PHONE,
  EMAIL_FIELD_PASSWORD,
  EMAIL_FIELD_PASSWORD_CONFIRM,
} from '../constants';

export const EmailSchema = yup.object().shape({
  [EMAIL_FIELD_FULL_NAME]: yup.string().required(MSG_ERROR_REQUIRED_FIELD),
  [EMAIL_FIELD_EMAIL]: yup.string().required(MSG_ERROR_REQUIRED_FIELD),
  [EMAIL_FIELD_PHONE]: yup
    .string()
    .required()
    .test('length', MSG_ERROR_TEL_LENGTH, (value: any) => {
      if (!value) return true;
      return (value || '').replace(/[^+0-9]/g, '').length === 12;
    }),
  [EMAIL_FIELD_PASSWORD]: yup
    .string()
    .required(MSG_ERROR_REQUIRED_FIELD)
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#_$%^&*])(?=.{8,})/,
      'error.password.not.valid',
    ),
  [EMAIL_FIELD_PASSWORD_CONFIRM]: yup
    .string()
    .required(MSG_ERROR_REQUIRED_FIELD)
    .oneOf([yup.ref(EMAIL_FIELD_PASSWORD), null], 'error.password.not.matched'),
});

import { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import {
  RegionsForms,
  fetchRegionsRecord,
  fetchRegionsUpdate,
  IRegions,
} from '../../../../features/regions';
import { useEntityUpdate } from '../../../../features/entities/hooks';
import { PreloaderWrapper } from '../../../../components/preloader-wrapper';
import { PageTitle } from '../../../../components/page-title';
import { CabinetContainer } from '../../../../components/cabinet-container';
import { ROUTE_REGIONS } from '../../../../common/constants';
import { get } from '../../../../common/utils';

export const RegionsUpdatePage: FC = () => {
  const { t } = useTranslation();
  const { isFetching, isUpdating, data, handleValid } = useEntityUpdate<IRegions>({
    entity: 'regions',
    recordThunk: fetchRegionsRecord,
    updateThunk: fetchRegionsUpdate,
    nextPath: ROUTE_REGIONS,
  });

  const defaultValues = { ...data };

  return (
    <>
      <Helmet>
        <title>{t('regions.update')}</title>
      </Helmet>
      <CabinetContainer>
        <PageTitle title={t('regions.update.title')} mb={3} />
        <PreloaderWrapper isLoading={isFetching}>
          <RegionsForms
            onValid={handleValid}
            defaultValues={defaultValues}
            isLoading={isUpdating}
            key={get(data, 'id')}
          />
        </PreloaderWrapper>
      </CabinetContainer>
    </>
  );
};

import * as yup from 'yup';
import {
  LOCALE_CR,
  LOCALE_EN,
  LOCALE_RU,
  LOCALE_UZ,
  MSG_ERROR_REQUIRED_FIELD,
} from '../../../common/constants';
import { MANAGEMENT_OFFICER_NAME, MANAGEMENT_OFFICER_POSITION } from '../constants';

const LocalizedFieldSchema = yup.object().shape({
  [LOCALE_CR]: yup.string().required(MSG_ERROR_REQUIRED_FIELD),
  [LOCALE_UZ]: yup.string(),
  [LOCALE_RU]: yup.string(),
  [LOCALE_EN]: yup.string(),
});

export const ManagementSchema = yup.object().shape({
  [MANAGEMENT_OFFICER_NAME]: LocalizedFieldSchema,
  [MANAGEMENT_OFFICER_POSITION]: LocalizedFieldSchema,
});

import { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Box, Card, CardContent, Typography } from '@mui/material';
import { Logo } from '../../common/logo';
import { LoginForm } from '../../features/auth/components';

export const HomePage: FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('welcome')}</title>
      </Helmet>
      <Box display="flex" flex={2} alignItems="center" justifyContent="center">
        <Box component={Card} py={2} px={3}>
          <CardContent>
            <Box mb={4}>
              <Logo width={46} height={66} />
            </Box>
            <Typography variant="h1">{t('login')}</Typography>
            <LoginForm mt={4} />
          </CardContent>
        </Box>
      </Box>
    </>
  );
};

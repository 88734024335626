import { createAsyncThunk } from '@reduxjs/toolkit';
import { UserApi } from '../http';

const userApi = new UserApi();

export const fetchUserDelete = createAsyncThunk(
  'user/delete',
  async (id: string, { rejectWithValue }) => {
    try {
      return await userApi.deleteOne({ id });
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

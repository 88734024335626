import { ComponentType } from 'react';
import { Box, BoxProps, Typography } from '@mui/material';

interface AnnunciatorProps extends Omit<BoxProps, 'title'> {
  title?: string;
  text?: string;
}

export const DataAnnunciator: ComponentType<AnnunciatorProps> = ({ title, text, ...props }) => {
  return (
    <Box {...props}>
      {title && (
        <Typography variant="subtitle2" component="div" mb={0.5} color="primary.main">
          {title}
        </Typography>
      )}
      {text && (
        <Typography variant="body2" component="p" color="primary.light" mb={0}>
          {text}
        </Typography>
      )}
    </Box>
  );
};

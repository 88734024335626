import { FC, useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import { MainNavButton } from './main-nav-button';
import { MainNavDropdown } from './main-nav-dropdown';
import { IMenuItem } from '../../common/models';
import { SIDEBAR_MENU } from '../../common/menu';

export const MainNav: FC = () => {
  const [menu, setMenu] = useState<any>([]);

  const renderMenu = (items: IMenuItem[]) => {
    return items.map(({ children, ...linkProps }, idx) => {
      if (children) {
        return <MainNavDropdown {...linkProps} children={children} key={idx} />;
      }
      return <MainNavButton {...linkProps} key={idx} />;
    });
  };

  useEffect(() => {
    setMenu(renderMenu(SIDEBAR_MENU));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Stack spacing={0.25}>{menu}</Stack>;
};

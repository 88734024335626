import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import { RecordRow } from '../../../../components/record-row';
import { REGIONS_NAME, REGIONS_CODE } from '../../constants';
import { LOCALE_CR } from '../../../../common/constants';
import { get } from '../../../../common/utils';
import { IRegions } from '../../models';

interface RegionsPreviewProps {
  data: IRegions;
}

export const RegionsPreview: FC<RegionsPreviewProps> = ({ data }) => {
  const { t, i18n } = useTranslation();

  return (
    <Stack spacing={1.5}>
      <Typography variant="subtitle1" component="div">
        {get(data, [REGIONS_NAME, i18n.language]) || get(data, [REGIONS_NAME, LOCALE_CR])}
      </Typography>
      <RecordRow label={t('regions.field.code')} value={get(data, REGIONS_CODE)} />
    </Stack>
  );
};

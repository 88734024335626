import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionButton } from '../../../../components/action-button';
import { IconSquareEditOutline } from '../../../../common/icons';
import { ROUTE_REGIONAL_ADMINISTRATIONS_UPDATE } from '../../../../common/constants';
import { url } from '../../../../common/utils';
import { IRegionalAdministrations } from '../../models';

interface RegionalAdministrationsActionEditProps {
  id: IRegionalAdministrations['id'];
}

export const RegionalAdministrationsActionEdit: FC<RegionalAdministrationsActionEditProps> = ({
  id,
}) => {
  const { t } = useTranslation();

  return (
    <ActionButton
      to={url(ROUTE_REGIONAL_ADMINISTRATIONS_UPDATE, { id })}
      title={t('action.edit')}
      icon={<IconSquareEditOutline />}
    />
  );
};

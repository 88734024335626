import { createAsyncThunk } from '@reduxjs/toolkit';
import { FAQApi } from '../http';
import { IFAQ } from '../models';

const faqApi = new FAQApi();

export const fetchFAQUpdate = createAsyncThunk(
  'faq/update',
  async (body: IFAQ, { rejectWithValue }) => {
    try {
      return await faqApi.updateOne(body);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

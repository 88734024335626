import { LOCALE_CR, LOCALE_EN, LOCALE_RU, LOCALE_UZ } from '../../../common/constants';
import {
  OPEN_INFO_TITLE,
  OPEN_INFO_TYPE,
  OPEN_INFO_FILES,
  OPEN_INFO_FILE_ID,
} from './open-info.constants';

export const OPEN_INFO_INITIAL_VALUES = {
  [OPEN_INFO_TITLE]: {
    [LOCALE_UZ]: '',
    [LOCALE_CR]: '',
    [LOCALE_RU]: '',
    [LOCALE_EN]: '',
  },
  [OPEN_INFO_TYPE]: '',
  [OPEN_INFO_FILES]: { saved_name: '' },
  [OPEN_INFO_FILE_ID]: '',
};

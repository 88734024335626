import { ComponentType } from 'react';
import { useMe } from '../hooks';
import { ScreenOnBoarding } from '../../../components/screens';

export const withAuthorized = (ComposedComponent: ComponentType) => (props: any) => {
  const { isAuthorized, isLoading } = useMe();

  if (isLoading) return <ScreenOnBoarding />;

  if (!isAuthorized) return null;

  return <ComposedComponent {...props} />;
};

import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  BoxProps,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Stack,
} from '@mui/material';
import { useLogin } from '../../hooks';
import { AUTH_FIELD_PASSWORD, AUTH_FIELD_USER_NAME } from '../../constants';
import { TextFieldController } from '../../../../components/inputs';
import { IconEye, IconEyeOff } from '../../../../common/icons';

export const LoginForm: FC<BoxProps> = (props) => {
  const { t } = useTranslation();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const { control, isLoading, handleLogin } = useLogin();

  return (
    <Box width={320} {...props} onSubmit={handleLogin} component="form">
      <Stack spacing={3} mb={3}>
        <TextFieldController
          control={control}
          name={AUTH_FIELD_USER_NAME}
          placeholder={t('login.field.login')}
          InputLabelProps={{ shrink: true }}
          disabled={isLoading}
          fullWidth
        />
        <TextFieldController
          control={control}
          name={AUTH_FIELD_PASSWORD}
          placeholder={t('login.field.password')}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            endAdornment: (
              <IconButton onClick={() => setPasswordVisible((prev) => !prev)}>
                {passwordVisible ? <IconEyeOff /> : <IconEye />}
              </IconButton>
            ),
          }}
          disabled={isLoading}
          type={passwordVisible ? 'text' : 'password'}
          fullWidth
        />
        <Stack direction="row" justifyContent="space-between">
          <FormControlLabel
            label={t('login.remember.me')}
            componentsProps={{ typography: { variant: 'body2', color: 'text.secondary' } }}
            control={<Checkbox disabled={isLoading} />}
          />
        </Stack>
      </Stack>
      <Button
        variant="contained"
        color="primary"
        size="large"
        type="submit"
        disabled={isLoading}
        fullWidth
      >
        {t('login.submit')}
      </Button>
    </Box>
  );
};

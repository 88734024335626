import { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useEntityCreate } from '../../../../features/entities/hooks';
import {
  RegionalAdministrationsForms,
  fetchRegionalAdministrationsCreate,
  IRegionalAdministrations,
  REGIONAL_ADMINISTRATIONS_INITIAL_VALUES,
} from '../../../../features/regional-administrations';
import { CabinetContainer } from '../../../../components/cabinet-container';
import { ROUTE_REGIONAL_ADMINISTRATIONS } from '../../../../common/constants';
import { PageTitle } from '../../../../components/page-title';

export const RegionalAdministrationsCreatePage: FC = () => {
  const { t } = useTranslation();
  const { isLoading, handleValid } = useEntityCreate<IRegionalAdministrations>({
    entity: 'regional.administrations',
    thunk: fetchRegionalAdministrationsCreate,
    nextPath: ROUTE_REGIONAL_ADMINISTRATIONS,
  });

  return (
    <>
      <Helmet>
        <title>{t('regional.administrations.create')}</title>
      </Helmet>
      <CabinetContainer>
        <PageTitle title={t('regional.administrations.create.title')} mb={3} />
        <RegionalAdministrationsForms
          onValid={handleValid}
          isLoading={isLoading}
          defaultValues={REGIONAL_ADMINISTRATIONS_INITIAL_VALUES}
        />
      </CabinetContainer>
    </>
  );
};

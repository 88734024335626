import { ImageListItem } from '@mui/material';
import { NewsActionDelete, NewsActionEdit, NewsActionView } from '../components';
import { Localize } from '../../../components/localize';
import { NEWS_FIELD_FILE, NEWS_FIELD_TITLE } from '../constants';
import { IColumn } from '../../../common/models';
import { INews } from '../models';
import { ENDPOINT_BASE_URL } from '../../../common/constants';

export const NEWS_COLUMNS: IColumn[] = [
  {
    dataKey: NEWS_FIELD_FILE,
    label: 'news.image',
    width: '10%',
    renderItem: ({ files }: INews) => {
      return files?.map((i: any, idx: number) => {
        if (idx < 3) {
          return (
            <ImageListItem key={idx} sx={{ marginBottom: 1 }}>
              <img
                src={i?.saved_name ? `${ENDPOINT_BASE_URL}/images/${i?.saved_name}` : '#'}
                alt="alt"
                loading="lazy"
              />
            </ImageListItem>
          );
        }
        return null;
      });
    },
  },
  {
    dataKey: NEWS_FIELD_TITLE,
    label: 'news.title',
    renderItem: ({ title_json }: INews) => <Localize data={title_json} />,
  },
  {
    dataKey: '',
    label: 'action.edit',
    width: '1%',
    renderItem: ({ id }: INews) => <NewsActionEdit id={id} />,
  },
  {
    dataKey: '',
    label: 'action.delete',
    width: '1%',
    renderItem: ({ id }: INews) => <NewsActionDelete id={id} />,
  },
  {
    dataKey: '',
    label: 'action.view',
    width: '1%',
    renderItem: ({ id }: INews) => <NewsActionView id={id} />,
  },
];

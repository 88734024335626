import { FAQActionDelete, FAQActionEdit, FAQActionView } from '../components';
import { Localize } from '../../../components/localize';
import { IColumn } from '../../../common/models';
import { IFAQ } from '../models';
import { FAQ_ANSWER, FAQ_QUESTION } from '../constants';

export const FAQ_COLUMNS: IColumn[] = [
  {
    dataKey: FAQ_QUESTION,
    label: 'faq.question',
    renderItem: ({ question_json }: IFAQ) => <Localize data={question_json} />,
  },
  {
    dataKey: FAQ_ANSWER,
    label: 'faq.answer',
    renderItem: ({ answer_json }: IFAQ) => <Localize data={answer_json} />,
  },
  {
    dataKey: '',
    label: 'action.edit',
    width: '1%',
    renderItem: ({ id }: IFAQ) => <FAQActionEdit id={id} />,
  },
  {
    dataKey: '',
    label: 'action.delete',
    width: '1%',
    renderItem: ({ id }: IFAQ) => <FAQActionDelete id={id} />,
  },
  {
    dataKey: '',
    label: 'action.view',
    width: '1%',
    renderItem: (data: IFAQ) => <FAQActionView data={data} />,
  },
];

import { RouteObject, Outlet } from 'react-router-dom';
import { ROUTE_EMAIL, ROUTE_EMAIL_CREATE, ROUTE_EMAIL_UPDATE } from '../../../common/constants';
import { EmailPage, EmailCreatePage, EmailUpdatePage } from '../../../pages/admin/email-page';

export const emailRoutes: RouteObject = {
  path: ROUTE_EMAIL,
  element: <Outlet />,
  caseSensitive: true,
  children: [
    { index: true, element: <EmailPage />, caseSensitive: true },
    {
      path: ROUTE_EMAIL_CREATE,
      element: <EmailCreatePage />,
      caseSensitive: true,
    },
    {
      path: ROUTE_EMAIL_UPDATE,
      element: <EmailUpdatePage />,
      caseSensitive: true,
    },
  ],
};

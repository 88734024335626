import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionButton } from '../../../../components/action-button';
import { IconSquareEditOutline } from '../../../../common/icons';
import { ROUTE_FAQ_UPDATE } from '../../../../common/constants';
import { url } from '../../../../common/utils';
import { IFAQ } from '../../models';

interface FAQActionEditProps {
  id: IFAQ['id'];
}

export const FAQActionEdit: FC<FAQActionEditProps> = ({ id }) => {
  const { t } = useTranslation();

  return (
    <ActionButton
      to={url(ROUTE_FAQ_UPDATE, { id })}
      title={t('action.edit')}
      icon={<IconSquareEditOutline />}
    />
  );
};

import { EmailActionDelete, EmailActionEdit, EmailActionView } from '../components';
import { IColumn } from '../../../common/models';
import { IEmail } from '../models';
import { EMAIL_FIELD_FULL_NAME, EMAIL_FIELD_EMAIL, EMAIL_FIELD_PHONE } from '../constants';
import { Typography } from '@mui/material';

export const EMAIL_COLUMNS: IColumn[] = [
  {
    dataKey: EMAIL_FIELD_EMAIL,
    label: 'email',
    renderItem: ({ email }: IEmail) => (
      <Typography component="div" variant="body2">
        {email ? email : '-'}
      </Typography>
    ),
  },
  {
    dataKey: EMAIL_FIELD_FULL_NAME,
    label: 'full.name',
    renderItem: ({ full_name }: IEmail) => (
      <Typography component="div" variant="body2">
        {full_name ? full_name : '-'}
      </Typography>
    ),
  },
  {
    dataKey: EMAIL_FIELD_PHONE,
    label: 'phone',
    renderItem: ({ phone_number }: IEmail) => (
      <Typography component="div" variant="body2">
        {phone_number ? phone_number : '-'}
      </Typography>
    ),
  },
  {
    dataKey: '',
    label: 'action.edit',
    width: '1%',
    renderItem: ({ id }: IEmail) => <EmailActionEdit id={id} />,
  },
  {
    dataKey: '',
    label: 'action.delete',
    width: '1%',
    renderItem: ({ id }: IEmail) => <EmailActionDelete id={id} />,
  },
  {
    dataKey: '',
    label: 'action.view',
    width: '1%',
    renderItem: (data: IEmail) => <EmailActionView data={data} />,
  },
];

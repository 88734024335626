import { Theme } from '@mui/material';
import { Components } from '@mui/material/styles/components';

export const components: Components<Omit<Theme, 'components'>> = {
  MuiCssBaseline: {
    styleOverrides: {
      html: {
        minHeight: '100vh',
        height: '100%',
      },
      body: {
        minWidth: 1140,
        minHeight: '100vh',
        height: '100%',
      },
      img: {
        maxWidth: '100%',
        verticalAlign: 'middle',
      },
      a: {
        '&:not([role="button"]):not(.MuiButtonBase-root)': {
          color: 'rgb(79, 70, 229)',
          textDecoration: 'underline',
          '&:hover': {
            textDecoration: 'none',
          },
        },
      },
      '#root': {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
      },
    },
  },
  MuiContainer: {
    defaultProps: {
      maxWidth: 'xxl',
    },
    styleOverrides: {
      root: ({ theme }) => ({
        [theme.breakpoints.up('sm')]: {
          paddingLeft: theme.spacing(4),
          paddingRight: theme.spacing(4),
        },
      }),
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: ({ theme }) => ({
        ...theme.typography.body2,
        '&.Mui-disabled': {
          backgroundColor: theme.palette.action.disabledBackground,
        },
      }),
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: ({ theme }) => ({
        backgroundColor: theme.palette.common.white,
      }),
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: ({ theme }) => ({
        ...theme.typography.body2,
      }),
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        borderRadius: 28,
        '&.MuiButton-containedSnow': {
          boxShadow: '0 0 0 1px #DBE0EA inset',
        },
      },
    },
  },
  MuiCard: {
    styleOverrides: {
      root: {
        borderRadius: 16,
      },
    },
  },
  MuiCardHeader: {
    styleOverrides: {
      root: ({ theme }) => ({
        padding: theme.spacing(3, 3, 0),
        '&:last-of-type': {
          paddingBottom: theme.spacing(3),
        },
      }),
      title: {
        fontWeight: 600,
      },
    },
  },
  MuiCardContent: {
    styleOverrides: {
      root: ({ theme }) => ({
        padding: theme.spacing(3),
        '&:last-of-type': {
          paddingBottom: theme.spacing(3),
        },
      }),
    },
  },
  MuiSvgIcon: {
    styleOverrides: {
      fontSizeSmall: {
        fontSize: 16,
      },
    },
  },
  MuiTableHead: {
    styleOverrides: {
      root: ({ theme }) => ({
        '& th': {
          textAlign: 'center',
          borderBottom: `1px solid ${theme.palette.divider} !important`,
          backgroundColor: '#F8F8FE',
          '& p': {
            fontWeight: 500,
          },
        },
      }),
    },
  },
  MuiTableRow: {
    styleOverrides: {
      root: {
        '&:first-of-type': {
          '& th, & td, ': {
            borderTop: 0,
          },
        },
        '&:last-of-type': {
          '& th, & td, ': {
            borderBottom: 0,
          },
        },
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: ({ theme }) => ({
        //verticalAlign: 'top',
        border: '1px solid',
        borderColor: theme.palette.divider,
        '&:first-of-type': {
          borderLeft: 0,
        },
        '&:last-of-type': {
          borderRight: 0,
        },
      }),
    },
  },
  MuiDialog: {
    styleOverrides: {
      root: {
        '& .MuiBackdrop-root': {
          backgroundColor: 'rgba(15, 23, 42, 0.4)',
          backdropFilter: 'blur(8px)',
        },
      },
      paper: {
        borderRadius: 12,
        boxShadow: '0 20px 24px -4px rgba(15, 23, 42, 0.1), 0 8px 8px -4px rgba(15, 23, 42, 0.04)',
        backgroundColor: '#FBFBFB',
      },
    },
  },
  MuiTabs: {
    styleOverrides: {
      root: {
        borderBottom: '1px solid #DBE0EA',
      },
    },
  },
};

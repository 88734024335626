import { createAsyncThunk } from '@reduxjs/toolkit';
import { RegionsApi } from '../http';
import { IRegions } from '../models';

const regionsApi = new RegionsApi();

export const fetchRegionsRecord = createAsyncThunk(
  'regions/record',
  async (body: { id: IRegions['id'] }, { rejectWithValue }) => {
    try {
      return await regionsApi.findOne(body);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { Collapse, Stack } from '@mui/material';
import { IconChevronDown, IconChevronUp } from '../../../common/icons';
import { IMenuItem } from '../../../common/models';
import { StyledDropdown, StyledDropdownButton } from './styled';

export const MainNavDropdown: FC<IMenuItem> = ({ label, path, icon, children }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const isPathEqual =
    pathname === path ||
    !!children?.some((child) => child.path === pathname) ||
    pathname.includes(path);

  return (
    <>
      <StyledDropdown
        component={Link}
        to={path}
        variant="text"
        color="inherit"
        className={isPathEqual ? 'MuiSelected' : null}
      >
        {icon}
        {t(label)}
        {isPathEqual ? <IconChevronUp fontSize="small" /> : <IconChevronDown fontSize="small" />}
      </StyledDropdown>
      {children && (
        <Collapse in={isPathEqual}>
          <Stack spacing={0.25}>
            {children.map(({ path, label }, idx) => (
              <StyledDropdownButton
                component={Link}
                to={path}
                variant="text"
                color="inherit"
                className={pathname === path ? 'MuiSelected' : null}
                key={idx}
              >
                {t(label)}
              </StyledDropdownButton>
            ))}
          </Stack>
        </Collapse>
      )}
    </>
  );
};

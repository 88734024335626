import { Palette } from '@mui/material/styles/createPalette';

export const darkPalette: Partial<Palette> = {
  mode: 'dark',
  text: {
    primary: '#0D0E0E',
    secondary: '#7A7E89',
    disabled: 'rgb(149, 156, 169)',
  },
  common: {
    black: 'rgb(17, 24, 39)',
    white: 'rgb(255, 255, 255)',
  },
  primary: {
    light: '#3B6CF8',
    main: '#3B6CF8',
    dark: '#3B6CF8',
    contrastText: '#ffffff',
  },
  secondary: {
    light: '#5F749C',
    main: '#2C3344',
    dark: '#0F172A',
    contrastText: '#ffffff',
  },
  info: {
    light: '#d9aee0',
    main: '#9C27B0',
    dark: '#9C27B0',
    contrastText: '#ffffff',
  },
  success: {
    light: '#EAF8EB',
    main: '#30B537',
    dark: '#30B537',
    contrastText: '#ffffff',
  },
  warning: {
    light: '#FEF8E9',
    main: '#F4BB28',
    dark: '#F4BB28',
    contrastText: '#ffffff',
  },
  error: {
    light: '#FCEBEB',
    main: '#E03C32',
    dark: '#E03C32',
    contrastText: '#ffffff',
  },
  snow: {
    light: '#ffffff',
    main: '#ffffff',
    dark: '#ffffff',
    contrastText: '#0D0E0E',
  },
  action: {
    active: 'rgb(17, 24, 39)',
    hover: 'rgba(17, 24, 39, 0.04)',
    hoverOpacity: 0.04,
    selected: 'rgba(17, 24, 39, 0.08)',
    selectedOpacity: 0.08,
    disabled: 'rgba(17, 24, 39, 0.24)',
    disabledOpacity: 0.24,
    disabledBackground: '#f5f5f5',
    focus: 'rgba(17, 24, 39, 0.12)',
    focusOpacity: 0.12,
    activatedOpacity: 0.12,
  },
  background: {
    paper: '#FFFFFF',
    default: '#F1F5F9',
  },
  grey: {
    50: '#e5e6e8',
    100: '#bec1c5',
    200: '#92979f',
    300: '#666d78',
    400: '#464e5b',
    500: '#252f3e',
    600: '#212a38',
    700: '#1b2330',
    800: '#161d28',
    900: '#0d121b',
    A100: '#5d8eff',
    A200: '#2a6aff',
    A400: '#004af6',
    A700: '#0042dd',
  },
};

import { FC } from 'react';
import { Avatar, Box, Stack, Typography } from '@mui/material';
import { IUserVisa } from '../../models';

interface UserVisaProps extends IUserVisa {
  hideNameOnMobile?: boolean;
}

export const UserVisa: FC<UserVisaProps> = ({ title, caption, src, hideNameOnMobile }) => {
  return (
    <Stack direction="row" alignItems="center">
      <Avatar src={src} />
      <Box display={{ xs: hideNameOnMobile ? 'none' : 'block', md: 'block' }} ml={1}>
        {title && (
          <Typography component="div" variant="body1" fontWeight={500} lineHeight={1.1875} mb={0.5}>
            {title}
          </Typography>
        )}
        {caption && (
          <Typography component="div" variant="caption" color="secondary.light" lineHeight={1.25}>
            {caption}
          </Typography>
        )}
      </Box>
    </Stack>
  );
};

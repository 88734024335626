import * as yup from 'yup';
import { MSG_ERROR_REQUIRED_FIELD } from '../../../common/constants';
import {
  USER_FIELD_FIRST_NAME,
  USER_FIELD_LAST_NAME,
  USER_FIELD_MIDDLE_NAME,
  USER_FIELD_USER_NAME,
  USER_FIELD_PASSWORD,
} from '../constants';

export const UserSchema = yup.object().shape({
  [USER_FIELD_LAST_NAME]: yup.string().required(MSG_ERROR_REQUIRED_FIELD),
  [USER_FIELD_FIRST_NAME]: yup.string().required(MSG_ERROR_REQUIRED_FIELD),
  [USER_FIELD_MIDDLE_NAME]: yup.string(),
  [USER_FIELD_USER_NAME]: yup.string().required(MSG_ERROR_REQUIRED_FIELD),
  [USER_FIELD_PASSWORD]: yup.string(),
});

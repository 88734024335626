import { createSlice } from '@reduxjs/toolkit';
import { IOpenInfo } from '../models';
import { IData, ISlice } from '../../../common/models';
import {
  fetchOpenInfoAll,
  fetchOpenInfoCatalog,
  fetchOpenInfoCreate,
  fetchOpenInfoRecord,
  fetchOpenInfoUpdate,
} from '../thunks';

interface ISliceWithAll extends ISlice<IData<IOpenInfo[]>, IOpenInfo> {
  all: {
    isLoading: boolean;
    result: IData<IOpenInfo[]> | null;
    error: any;
  };
}

const initialState: ISliceWithAll = {
  catalog: {
    isLoading: false,
    result: null,
    error: null,
  },
  record: {
    isLoading: false,
    result: null,
    error: null,
  },
  create: {
    isLoading: false,
    error: null,
  },
  update: {
    isLoading: false,
    error: null,
  },
  all: {
    isLoading: false,
    result: null,
    error: null,
  },
};

export const openInfoSlice = createSlice({
  name: 'openInfo',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchOpenInfoCatalog.pending, (state) => {
      state.catalog = {
        ...state.catalog,
        isLoading: true,
        result: null,
        error: null,
      };
    });
    builder.addCase(fetchOpenInfoCatalog.fulfilled, (state, { payload }) => {
      state.catalog = {
        ...state.catalog,
        isLoading: false,
        result: payload,
        error: null,
      };
    });
    builder.addCase(fetchOpenInfoCatalog.rejected, (state, { payload }) => {
      state.catalog = {
        ...state.catalog,
        isLoading: false,
        result: null,
        error: payload,
      };
    });
    builder.addCase(fetchOpenInfoRecord.pending, (state) => {
      state.record = {
        isLoading: true,
        result: null,
        error: null,
      };
    });
    builder.addCase(fetchOpenInfoRecord.fulfilled, (state, { payload }) => {
      state.record = {
        ...state.record,
        isLoading: false,
        result: payload,
        error: null,
      };
    });
    builder.addCase(fetchOpenInfoRecord.rejected, (state, { payload }) => {
      state.record = {
        ...state.record,
        isLoading: false,
        result: null,
        error: payload,
      };
    });
    builder.addCase(fetchOpenInfoCreate.pending, (state) => {
      state.create = {
        ...state.create,
        isLoading: true,
        error: null,
      };
    });
    builder.addCase(fetchOpenInfoCreate.fulfilled, (state) => {
      state.create = {
        ...state.create,
        isLoading: false,
        error: null,
      };
    });
    builder.addCase(fetchOpenInfoCreate.rejected, (state, { payload }) => {
      state.create = {
        ...state.create,
        isLoading: false,
        error: payload,
      };
    });
    builder.addCase(fetchOpenInfoUpdate.pending, (state) => {
      state.update = {
        ...state.update,
        isLoading: true,
        error: null,
      };
    });
    builder.addCase(fetchOpenInfoUpdate.fulfilled, (state) => {
      state.update = {
        ...state.update,
        isLoading: false,
        error: null,
      };
    });
    builder.addCase(fetchOpenInfoUpdate.rejected, (state, { payload }) => {
      state.update = {
        ...state.update,
        isLoading: false,
        error: payload,
      };
    });
    builder.addCase(fetchOpenInfoAll.pending, (state) => {
      state.all = {
        ...state.all,
        isLoading: true,
        result: null,
        error: null,
      };
    });
    builder.addCase(fetchOpenInfoAll.fulfilled, (state, { payload }) => {
      state.all = {
        ...state.all,
        isLoading: false,
        result: payload,
        error: null,
      };
    });
    builder.addCase(fetchOpenInfoAll.rejected, (state, { payload }) => {
      state.all = {
        ...state.all,
        isLoading: false,
        result: null,
        error: payload,
      };
    });
  },
});

import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useEntityDelete } from '../../../entities/hooks';
import { ActionButton } from '../../../../components/action-button';
import { fetchUserDelete } from '../../thunks';
import { IconDeleteCanOutline } from '../../../../common/icons';
import { IUser } from '../../models';
import { ROUTE_USERS } from '../../../../common/constants';

interface UserActionDeleteProps {
  id: IUser['id'];
  disabled?: boolean;
}

export const UsersActionDelete: FC<UserActionDeleteProps> = ({ id, disabled }) => {
  const { t } = useTranslation();
  const { handleDelete } = useEntityDelete({
    thunk: fetchUserDelete,
    nextPath: ROUTE_USERS,
    confirmationText: t('confirmation.delete.text'),
  });

  return (
    <ActionButton
      onClick={() => handleDelete(id)}
      title={t('action.delete')}
      icon={<IconDeleteCanOutline />}
      disabled={disabled}
    />
  );
};

import { FC } from 'react';
import { Box, BoxProps, Container, Pagination, PaginationProps, Stack } from '@mui/material';

interface DataPaginationProps extends PaginationProps {
  container?: boolean;
  mt?: BoxProps['mt'];
  mb?: BoxProps['mb'];
}

export const DataPagination: FC<DataPaginationProps> = ({
  container = false,
  mt,
  mb,
  ...props
}) => {
  if (!props.count || props.count <= 1) return null;
  return (
    <Box py={3.5} borderTop="1px solid" borderColor="divider" mt={mt} mb={mb}>
      <Stack component={container ? Container : Box} direction="row" justifyContent="flex-end">
        <Pagination {...props} />
      </Stack>
    </Box>
  );
};

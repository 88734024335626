import { createAsyncThunk } from '@reduxjs/toolkit';
import { ManagementApi } from '../http';
import { IManagementQuery } from '../models';

const managementApi = new ManagementApi();

export const fetchManagementCatalog = createAsyncThunk(
  'management/catalog',
  async (body: IManagementQuery, { rejectWithValue }) => {
    try {
      const data = await managementApi.findAll(body);
      return { data, page: 1, limit: 9999, total: data.length };
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

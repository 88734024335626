import { FC, FocusEvent } from 'react';
import { Control } from 'react-hook-form/dist/types/form';
import { Controller, Noop } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import { TextField, TextFieldProps } from '@mui/material';

export type NumericFieldControllerProps = NumericFormatProps<TextFieldProps> & {
  control: Control<any>;
  name: string;
};

export const NumericFieldController: FC<NumericFieldControllerProps> = ({
  control,
  name,
  ...props
}) => {
  const { t } = useTranslation();

  const handleBlur = (onBlur?: Noop) => (e: FocusEvent<HTMLInputElement>) => {
    if (onBlur) onBlur();
    if (props.onBlur) props.onBlur(e);
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { ref, onChange, onBlur, value, ...fieldProps },
        fieldState: { error },
      }) => (
        <NumericFormat
          {...{ ...props, ...fieldProps }}
          onBlur={handleBlur(onBlur)}
          inputRef={ref}
          name={name}
          value={value || ''}
          decimalScale={2}
          error={!!error?.message}
          helperText={error?.message ? t(error.message) : null}
          customInput={TextField}
          allowNegative={false}
          allowLeadingZeros
          onValueChange={({ formattedValue }) =>
            onChange({ target: { name, value: formattedValue } })
          }
        />
      )}
    />
  );
};

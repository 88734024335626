import { createBreakpoints } from '@mui/system';

export const breakpoints = {
  values: {
    xs: 0,
    sm: 520,
    md: 768,
    lg: 992,
    xl: 1280,
    xxl: 1470,
  },
};

export const themeBreakpoints = createBreakpoints(breakpoints);

import { createSlice } from '@reduxjs/toolkit';
import { IManagement } from '../models';
import { IData, ISlice } from '../../../common/models';
import {
  fetchManagementAll,
  fetchManagementCatalog,
  fetchManagementCreate,
  fetchManagementRecord,
  fetchManagementUpdate,
} from '../thunks';

interface ISliceWithAll extends ISlice<IData<IManagement[]>, IManagement> {
  all: {
    isLoading: boolean;
    result: IData<IManagement[]> | null;
    error: any;
  };
}

const initialState: ISliceWithAll = {
  catalog: {
    isLoading: false,
    result: null,
    error: null,
  },
  record: {
    isLoading: false,
    result: null,
    error: null,
  },
  create: {
    isLoading: false,
    error: null,
  },
  update: {
    isLoading: false,
    error: null,
  },
  all: {
    isLoading: false,
    result: null,
    error: null,
  },
};

export const managementSlice = createSlice({
  name: 'management',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchManagementCatalog.pending, (state) => {
      state.catalog = {
        ...state.catalog,
        isLoading: true,
        result: null,
        error: null,
      };
    });
    builder.addCase(fetchManagementCatalog.fulfilled, (state, { payload }) => {
      state.catalog = {
        ...state.catalog,
        isLoading: false,
        result: payload,
        error: null,
      };
    });
    builder.addCase(fetchManagementCatalog.rejected, (state, { payload }) => {
      state.catalog = {
        ...state.catalog,
        isLoading: false,
        result: null,
        error: payload,
      };
    });
    builder.addCase(fetchManagementRecord.pending, (state) => {
      state.record = {
        isLoading: true,
        result: null,
        error: null,
      };
    });
    builder.addCase(fetchManagementRecord.fulfilled, (state, { payload }) => {
      state.record = {
        ...state.record,
        isLoading: false,
        result: payload,
        error: null,
      };
    });
    builder.addCase(fetchManagementRecord.rejected, (state, { payload }) => {
      state.record = {
        ...state.record,
        isLoading: false,
        result: null,
        error: payload,
      };
    });
    builder.addCase(fetchManagementCreate.pending, (state) => {
      state.create = {
        ...state.create,
        isLoading: true,
        error: null,
      };
    });
    builder.addCase(fetchManagementCreate.fulfilled, (state) => {
      state.create = {
        ...state.create,
        isLoading: false,
        error: null,
      };
    });
    builder.addCase(fetchManagementCreate.rejected, (state, { payload }) => {
      state.create = {
        ...state.create,
        isLoading: false,
        error: payload,
      };
    });
    builder.addCase(fetchManagementUpdate.pending, (state) => {
      state.update = {
        ...state.update,
        isLoading: true,
        error: null,
      };
    });
    builder.addCase(fetchManagementUpdate.fulfilled, (state) => {
      state.update = {
        ...state.update,
        isLoading: false,
        error: null,
      };
    });
    builder.addCase(fetchManagementUpdate.rejected, (state, { payload }) => {
      state.update = {
        ...state.update,
        isLoading: false,
        error: payload,
      };
    });
    builder.addCase(fetchManagementAll.pending, (state) => {
      state.all = {
        ...state.all,
        isLoading: true,
        result: null,
        error: null,
      };
    });
    builder.addCase(fetchManagementAll.fulfilled, (state, { payload }) => {
      state.all = {
        ...state.all,
        isLoading: false,
        result: payload,
        error: null,
      };
    });
    builder.addCase(fetchManagementAll.rejected, (state, { payload }) => {
      state.all = {
        ...state.all,
        isLoading: false,
        result: null,
        error: payload,
      };
    });
  },
});

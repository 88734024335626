import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Button, Stack } from '@mui/material';
import { useEntityList } from '../../../../features/entities/hooks';
import { NEWS_COLUMNS, fetchNewsCatalog } from '../../../../features/news';
import { DataPagination, DataTableCard } from '../../../../components/data';
import { CabinetContainer } from '../../../../components/cabinet-container';
import { PageTitle } from '../../../../components/page-title';
import { DEFAULT_LIMIT, ROUTE_NEWS_CREATE } from '../../../../common/constants';

export const NewsPage: FC = () => {
  const { t } = useTranslation();
  const { data, isLoading, page, pagesCount, handleChangePage } = useEntityList({
    entity: 'news',
    thunk: fetchNewsCatalog,
    defaultParams: { limit: DEFAULT_LIMIT },
  });

  return (
    <>
      <Helmet>
        <title>{t('news')}</title>
      </Helmet>
      <CabinetContainer>
        <PageTitle title={t('news.list.title')} mb={3} />
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          alignItems={{ xs: 'flex-start', sm: 'center' }}
          justifyContent={{ xs: 'flex-start', sm: 'flex-end' }}
          spacing={2}
          mb={4}
        >
          <Button component={Link} to={ROUTE_NEWS_CREATE} color="primary" variant="contained">
            {t('news.create')}
          </Button>
        </Stack>
        <DataTableCard
          data={data}
          columns={NEWS_COLUMNS}
          isLoading={isLoading}
          page={page}
          showIndex
        />
      </CabinetContainer>
      <DataPagination count={pagesCount} page={page} onChange={handleChangePage} mt={5} container />
    </>
  );
};

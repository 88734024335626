import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';
import { ReactComponent as LogoSvg } from './assets/logo.svg';

interface LogoProps extends SvgIconProps {
  width?: number;
  height?: number;
}

export const Logo: FC<LogoProps> = ({ width = 88, height = 118, ...props }) => {
  return (
    <SvgIcon
      component={LogoSvg}
      viewBox="0 0 88 118"
      sx={{ width, height, verticalAlign: 'middle' }}
      {...props}
    />
  );
};

import { BaseApi } from '../../../common/axios';
import { IRegionalAdministrations, IRegionalAdministrationsQuery } from '../models';
import { ENDPOINT_BASE_URL } from '../../../common/constants';
import {
  ENDPOINT_REGIONAL_ADMINISTRATIONS_FIND_ALL,
  ENDPOINT_REGIONAL_ADMINISTRATIONS_FIND_ONE,
  ENDPOINT_REGIONAL_ADMINISTRATIONS_CREATE_ONE,
  ENDPOINT_REGIONAL_ADMINISTRATIONS_DELETE_ONE,
  ENDPOINT_REGIONAL_ADMINISTRATIONS_UPDATE_ONE,
} from '../endpoints';

export class RegionalAdministrationsApi extends BaseApi {
  constructor() {
    super();
    this.baseUrl = ENDPOINT_BASE_URL;
  }

  public findAll(body: IRegionalAdministrationsQuery): Promise<IRegionalAdministrations[]> {
    return this.httpClient
      .post(this.url(ENDPOINT_REGIONAL_ADMINISTRATIONS_FIND_ALL), body)
      .then((response) => response.data);
  }

  public findOne(body: { id: IRegionalAdministrations['id'] }): Promise<IRegionalAdministrations> {
    return this.httpClient
      .post(this.url(ENDPOINT_REGIONAL_ADMINISTRATIONS_FIND_ONE), body)
      .then((response) => response.data);
  }

  public createOne(body: IRegionalAdministrationsQuery): Promise<IRegionalAdministrations> {
    return this.httpClient
      .post(this.url(ENDPOINT_REGIONAL_ADMINISTRATIONS_CREATE_ONE), body)
      .then((response) => response.data);
  }

  public removeOne(body: {
    id: IRegionalAdministrations['id'];
  }): Promise<IRegionalAdministrations> {
    return this.httpClient
      .post(this.url(ENDPOINT_REGIONAL_ADMINISTRATIONS_DELETE_ONE), body)
      .then((response) => response.data);
  }

  public updateOne(body: IRegionalAdministrations): Promise<IRegionalAdministrations> {
    return this.httpClient
      .post(this.url(ENDPOINT_REGIONAL_ADMINISTRATIONS_UPDATE_ONE), body)
      .then((response) => response.data);
  }
}

import { createSlice } from '@reduxjs/toolkit';
import { IUser } from '../models';
import { IData, ISlice } from '../../../common/models';
import { fetchUserCatalog, fetchUserCreate, fetchUserRecord, fetchUserUpdate } from '../thunks';

interface ISliceWithAll extends ISlice<IData<IUser[]>, IUser> {
  all: {
    isLoading: boolean;
    result: IData<IUser[]> | null;
    error: any;
  };
}

const initialState: ISliceWithAll = {
  catalog: {
    isLoading: false,
    result: null,
    error: null,
  },
  record: {
    isLoading: false,
    result: null,
    error: null,
  },
  create: {
    isLoading: false,
    error: null,
  },
  update: {
    isLoading: false,
    error: null,
  },
  all: {
    isLoading: false,
    result: null,
    error: null,
  },
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUserCatalog.pending, (state) => {
      state.catalog = {
        ...state.catalog,
        isLoading: true,
        error: null,
      };
    });
    builder.addCase(fetchUserCatalog.fulfilled, (state, { payload }) => {
      state.catalog = {
        ...state.catalog,
        isLoading: false,
        result: payload,
      };
    });
    builder.addCase(fetchUserCatalog.rejected, (state, { payload }) => {
      state.catalog = {
        ...state.catalog,
        isLoading: false,
        error: payload,
      };
    });
    builder.addCase(fetchUserRecord.pending, (state) => {
      state.record = {
        isLoading: true,
        result: null,
        error: null,
      };
    });
    builder.addCase(fetchUserRecord.fulfilled, (state, { payload }) => {
      state.record = {
        ...state.record,
        isLoading: false,
        result: payload,
      };
    });
    builder.addCase(fetchUserRecord.rejected, (state, { payload }) => {
      state.record = {
        ...state.record,
        isLoading: false,
        error: payload,
      };
    });
    builder.addCase(fetchUserCreate.pending, (state) => {
      state.create = {
        ...state.create,
        isLoading: true,
        error: null,
      };
    });
    builder.addCase(fetchUserCreate.fulfilled, (state) => {
      state.create = {
        ...state.create,
        isLoading: false,
      };
    });
    builder.addCase(fetchUserCreate.rejected, (state, { payload }) => {
      state.create = {
        ...state.create,
        isLoading: false,
        error: payload,
      };
    });
    builder.addCase(fetchUserUpdate.pending, (state) => {
      state.update = {
        ...state.update,
        isLoading: true,
        error: null,
      };
    });
    builder.addCase(fetchUserUpdate.fulfilled, (state) => {
      state.update = {
        ...state.update,
        isLoading: false,
      };
    });
    builder.addCase(fetchUserUpdate.rejected, (state, { payload }) => {
      state.update = {
        ...state.update,
        isLoading: false,
        error: payload,
      };
    });
  },
});

import { createAsyncThunk } from '@reduxjs/toolkit';
import { ManagementApi } from '../http';

const managementApi = new ManagementApi();

export const fetchManagementDelete = createAsyncThunk(
  'management/delete',
  async (id: string, { rejectWithValue }) => {
    try {
      return await managementApi.removeOne({ id });
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

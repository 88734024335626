import { StrictMode, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { App } from './App';
import {
  DialogProvider,
  PageMetricProvider,
  SidebarProvider,
  SnackbarProvider,
} from './common/providers';
import { ThemeProvider } from './common/theme';
import { store } from './store';
import './i18next';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <StrictMode>
    <Suspense fallback="">
      <Provider store={store}>
        <BrowserRouter>
          <ThemeProvider>
            <HelmetProvider context={{}}>
              <SnackbarProvider>
                <DialogProvider>
                  <SidebarProvider>
                    <PageMetricProvider>
                      <App />
                    </PageMetricProvider>
                  </SidebarProvider>
                </DialogProvider>
              </SnackbarProvider>
            </HelmetProvider>
          </ThemeProvider>
        </BrowserRouter>
      </Provider>
    </Suspense>
  </StrictMode>,
);

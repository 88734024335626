import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import { RecordRow } from '../../../../components/record-row';
import { EMAIL_FIELD_FULL_NAME, EMAIL_FIELD_EMAIL, EMAIL_FIELD_PHONE } from '../../constants';
import { get } from '../../../../common/utils';
import { IEmail } from '../../models';

interface EmailPreviewProps {
  data: IEmail;
}

export const EmailPreview: FC<EmailPreviewProps> = ({ data }) => {
  const { t } = useTranslation();

  return (
    <Stack spacing={1.5}>
      <Typography variant="subtitle1" component="div">
        {EMAIL_FIELD_FULL_NAME}
      </Typography>
      <RecordRow label={t('email.field.answer')} value={get(data, EMAIL_FIELD_EMAIL)} />
      <RecordRow label={t('email.field.answer')} value={get(data, EMAIL_FIELD_PHONE)} />
    </Stack>
  );
};

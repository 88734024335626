import { ChangeEvent, FC } from 'react';
import { Control } from 'react-hook-form/dist/types/form';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MenuItem, TextField, TextFieldProps } from '@mui/material';
import { IOption } from '../../../common/models';

export type SelectFieldControllerProps = Omit<TextFieldProps, 'select' | 'onChange'> & {
  control: Control<any>;
  name: string;
  options?: IOption[];
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
};

export const SelectFieldController: FC<SelectFieldControllerProps> = ({
  control,
  name,
  options = [],
  onChange: onCustomChange,
  ...props
}) => {
  const { t } = useTranslation();

  const handleChange = (onChange: any) => (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e);
    if (onCustomChange) onCustomChange(e);
  };

  const renderOptions = () => {
    if (!options.length || !options) {
      return <MenuItem value="" key="empty"></MenuItem>;
    }

    return options.map(({ label, value }) => (
      <MenuItem value={value} key={value}>
        {label}
      </MenuItem>
    ));
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, onChange, value, ...fieldProps }, fieldState: { error } }) => (
        <TextField
          {...{ ...props, ...fieldProps }}
          onChange={handleChange(onChange)}
          value={!options.length && value ? '' : value || ''}
          error={!!error?.message}
          helperText={error?.message ? t(error.message) : null}
          autoComplete="new-password"
          inputRef={ref}
          select
        >
          {renderOptions()}
        </TextField>
      )}
    />
  );
};

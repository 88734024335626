export const get = (obj: any, path: string | number | any[], defaultValue: any = null): any => {
  if (path === null || path === undefined || obj === null || obj === undefined) {
    return defaultValue;
  }

  let fullPath;
  let result = obj;

  if (Array.isArray(path)) {
    fullPath = path.join('.');
  } else if (typeof path === 'number') {
    fullPath = path.toString();
  } else {
    fullPath = path;
  }

  const keys = fullPath.split('.');

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    if (result === null || result === undefined || result[key] === undefined) {
      return defaultValue;
    }
    result = result[key];
  }

  return result;
};

export const debounce = (func: any, wait: number, immediate?: boolean) => {
  let timeout: any;
  return function (this: any) {
    const context: any = this;
    const args = arguments;
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};

export const isEqual = (value: any, other: any): boolean => {
  if (value === other) {
    return true;
  }
  if (value instanceof Date && other instanceof Date) {
    return value.getTime() === other.getTime();
  }
  if (!value || !other || (typeof value !== 'object' && typeof other !== 'object')) {
    // eslint-disable-next-line no-self-compare
    return value !== value && other !== other;
  }
  if (value.prototype !== other.prototype) {
    return false;
  }
  const keys = Object.keys(value);
  if (keys.length !== Object.keys(other).length) {
    return false;
  }
  return keys.every((k) => isEqual(value[k], other[k]));
};

export const capitalize = (str: string): string => {
  return (str || '').toString().charAt(0).toUpperCase() + str.slice(1);
};

export const parseStringToNumber = (value: string) => {
  const parsedValue = Number(value);

  if (isNaN(parsedValue)) {
    return value;
  }

  return parsedValue;
};

// cut string to 30 characters and paste ... at the end if length greater than 30
export const cutString = (str: string, length: number = 30): string => {
  if (str.length > length) {
    return str.slice(0, length) + '...';
  }

  return str;
};

// removes html tags from string
export const stripHtml = (html: string) => {
  const tmp = document.createElement('DIV');
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || '';
};

import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionButton } from '../../../../components/action-button';
import { useEntityDelete } from '../../../entities/hooks';
import { fetchRegionalAdministrationsDelete } from '../../thunks';
import { IconDeleteCanOutline } from '../../../../common/icons';
import { ROUTE_REGIONAL_ADMINISTRATIONS } from '../../../../common/constants';
import { IRegionalAdministrations } from '../../models';

interface RegionalAdministrationsActionDeleteProps {
  id: IRegionalAdministrations['id'];
}

export const RegionalAdministrationsActionDelete: FC<RegionalAdministrationsActionDeleteProps> = ({
  id,
}) => {
  const { t } = useTranslation();
  const { handleDelete } = useEntityDelete({
    thunk: fetchRegionalAdministrationsDelete,
    nextPath: ROUTE_REGIONAL_ADMINISTRATIONS,
    confirmationText: t('confirmation.delete.text'),
  });

  return (
    <ActionButton
      onClick={() => handleDelete(id)}
      title={t('action.delete')}
      icon={<IconDeleteCanOutline />}
    />
  );
};

import { createAsyncThunk } from '@reduxjs/toolkit';
import { RegionalAdministrationsApi } from '../http';
import { IRegionalAdministrationsQuery } from '../models';

const regionalAdministrationsApi = new RegionalAdministrationsApi();

export const fetchRegionalAdministrationsAll = createAsyncThunk(
  'regionalAdministrations/all',
  async (body: IRegionalAdministrationsQuery, { rejectWithValue }) => {
    try {
      const data = await regionalAdministrationsApi.findAll(body);
      return { data, page: 1, limit: 9999, total: data.length };
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

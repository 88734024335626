import { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useEntityCreate } from '../../../../features/entities/hooks';
import {
  ManagementForms,
  fetchManagementCreate,
  IManagement,
  MANAGEMENT_INITIAL_VALUES,
} from '../../../../features/management';
import { CabinetContainer } from '../../../../components/cabinet-container';
import { ROUTE_MANAGEMENT } from '../../../../common/constants';
import { PageTitle } from '../../../../components/page-title';

export const ManagementCreatePage: FC = () => {
  const { t } = useTranslation();
  const { isLoading, handleValid } = useEntityCreate<IManagement>({
    entity: 'management',
    thunk: fetchManagementCreate,
    nextPath: ROUTE_MANAGEMENT,
  });

  return (
    <>
      <Helmet>
        <title>{t('management.create')}</title>
      </Helmet>
      <CabinetContainer>
        <PageTitle title={t('management.create.title')} mb={3} />
        <ManagementForms
          onValid={handleValid}
          isLoading={isLoading}
          defaultValues={MANAGEMENT_INITIAL_VALUES}
        />
      </CabinetContainer>
    </>
  );
};

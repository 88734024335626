import { BaseApi } from '../../../common/axios';
import { IEmail, IEmailQuery } from '../models';
import { ENDPOINT_BASE_URL } from '../../../common/constants';
import {
  ENDPOINT_EMAIL_FIND_ALL,
  ENDPOINT_EMAIL_FIND_ONE,
  ENDPOINT_EMAIL_CREATE_ONE,
  ENDPOINT_EMAIL_DELETE_ONE,
  ENDPOINT_EMAIL_UPDATE_ONE,
} from '../endpoints';

export class EmailApi extends BaseApi {
  constructor() {
    super();
    this.baseUrl = ENDPOINT_BASE_URL;
  }

  public findAll(body: IEmailQuery): Promise<IEmail[]> {
    return this.httpClient
      .post(this.url(ENDPOINT_EMAIL_FIND_ALL), body)
      .then((response) => response.data);
  }

  public findOne(body: { id: IEmail['id'] }): Promise<IEmail> {
    return this.httpClient
      .post(this.url(ENDPOINT_EMAIL_FIND_ONE), body)
      .then((response) => response.data);
  }

  public createOne(body: IEmailQuery): Promise<IEmail> {
    return this.httpClient
      .post(this.url(ENDPOINT_EMAIL_CREATE_ONE), body)
      .then((response) => response.data);
  }

  public removeOne(body: { id: IEmail['id'] }): Promise<IEmail> {
    return this.httpClient
      .post(this.url(ENDPOINT_EMAIL_DELETE_ONE), body)
      .then((response) => response.data);
  }

  public updateOne(body: IEmail): Promise<IEmail> {
    return this.httpClient
      .post(this.url(ENDPOINT_EMAIL_UPDATE_ONE), body)
      .then((response) => response.data);
  }
}

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from '../../../common/hooks';
import { setBearerToken } from '../../../common/axios';
import { get } from '../../../common/utils';
import { AuthApi } from '../http';
import { ILoginError, ILoginQuery } from '../models';
import { LoginSchema } from '../schema';
import { AUTH_FIELD_PASSWORD, AUTH_FIELD_USER_NAME } from '../constants';
import { ROUTE_ADMIN } from '../../../common/constants';

const loginApi = new AuthApi();

export const useLogin = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { showMessage } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const { control, handleSubmit, setError } = useForm({
    defaultValues: {
      [AUTH_FIELD_USER_NAME]: '',
      [AUTH_FIELD_PASSWORD]: '',
    },
    resolver: yupResolver(LoginSchema),
    mode: 'onBlur',
  });

  const handleErrors = (errors: ILoginError) => {
    const status = get(errors, 'response.status');
    const message = get(errors, 'response.data.message');
    if (message) {
      showMessage(t(message), { severity: 'error' });
    }
    if (status === 422) {
      setError(AUTH_FIELD_PASSWORD, { type: 'manual', message: t('login.error.credentials') });
    }
  };

  const onSubmit = ({ password, ...values }: ILoginQuery) => {
    setIsLoading(true);
    loginApi
      .login({
        ...values,
        [AUTH_FIELD_PASSWORD]: password,
      })
      .then(({ access_token }) => {
        setBearerToken(access_token);
        navigate(ROUTE_ADMIN);
      })
      .catch(handleErrors)
      .finally(() => setIsLoading(false));
  };

  const handleLogin = handleSubmit(onSubmit);

  return { control, isLoading, handleSubmit, handleLogin, onSubmit };
};

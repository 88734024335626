import { ChangeEvent, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useEntityForm } from '../../../entities/hooks';
import { TextFieldController } from '../../../../components/inputs';
import { Box, Stack, Button, Card, CardHeader, CardContent, Grid, TextField } from '@mui/material';
import { LOCALE_CR, LOCALE_UZ, LOCALE_RU } from '../../../../common/constants';
import {
  OPEN_INFO_TITLE,
  OPEN_INFO_FILES,
  OPEN_INFO_TYPE,
  OPEN_INFO_FILE_ID,
} from '../../constants';
import { IconClearAll, IconSave } from '../../../../common/icons';
import { IForm } from '../../../../common/models';
import { OpenInfoSchema } from '../../schema';
import { IOpenInfo } from '../../models';
import { SelectOpenInfoController } from '../input';
import { useSnackbar } from '../../../../common/hooks';
import { useFiles } from '../../../files';
import { get } from '../../../../common/utils';
import WarningSnackbarComponent from '../../../../components/warning-snockbar/WarningSnockbarComponent';

export const OpenInfoForms: FC<IForm<IOpenInfo>> = ({ defaultValues, onValid, isLoading }) => {
  const { t } = useTranslation();
  const [fileTypeError, setFileTypeError] = useState<boolean>(false);
  const { showMessage } = useSnackbar();
  const { handleInputUpload } = useFiles();
  const { control, onReset, onSubmit, getValues, setValue } = useEntityForm<IOpenInfo>({
    schema: OpenInfoSchema,
    defaultValues,
    onValid,
  });

  const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const fileObj = e.target.files && e.target.files[0];
    if (
      fileObj?.type === 'application/pdf' ||
      fileObj?.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
      fileObj?.type === 'application/msword' ||
      fileObj?.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
      fileObj?.type === 'application/vnd.ms-excel' ||
      fileObj?.type === 'text/csv' ||
      fileObj?.type === 'image/jpeg' ||
      fileObj?.type === 'image/png'
    ) {
      handleInputUpload(e)
        .then((res: any) => {
          setValue(OPEN_INFO_FILE_ID, res?.id);
          console.log(getValues(OPEN_INFO_FILE_ID));
          setValue(OPEN_INFO_FILES, res);
        })
        .catch((e) => {
          const message = get(e, 'response.data.message', t('error'));
          showMessage(message, { variant: 'filled', severity: 'error' });
        });
    } else {
      setFileTypeError(true);
      e.target.files = null;
      e.target.value = '';
    }
  };

  return (
    <Box onSubmit={onSubmit} component="form">
      <WarningSnackbarComponent
        state={fileTypeError}
        setState={setFileTypeError}
        label={
          'Ҳужжат тури jpg, jpeg, doc, docx, rar, zip, pdf ва ҳажми 100Мб дан ошмаслиги лозим!'
        }
      />
      <Stack direction="row" justifyContent="flex-end" spacing={1.5} mb={2.5}>
        <Button
          type="reset"
          variant="contained"
          color="secondary"
          startIcon={<IconClearAll />}
          disabled={isLoading}
          onClick={onReset}
        >
          {t('action.clear')}
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          startIcon={<IconSave />}
          disabled={isLoading}
        >
          {t('action.save')}
        </Button>
      </Stack>
      <Card>
        <CardHeader title={t('open.info.form')} />
        <CardContent>
          <Grid container spacing={2.5}>
            <Grid item xs={12} md={6} lg={3}>
              <TextFieldController
                control={control}
                name={[OPEN_INFO_TITLE, LOCALE_CR].join('.')}
                disabled={isLoading}
                label={t('open.info.field.name.cr')}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <TextFieldController
                control={control}
                name={[OPEN_INFO_TITLE, LOCALE_UZ].join('.')}
                disabled={isLoading}
                label={t('open.info.field.name.uz')}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <TextFieldController
                control={control}
                name={[OPEN_INFO_TITLE, LOCALE_RU].join('.')}
                disabled={isLoading}
                label={t('open.info.field.name.ru')}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <SelectOpenInfoController
                control={control}
                name={OPEN_INFO_TYPE}
                label={t('open.info.field.type')}
                disabled={isLoading}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <TextField
                onChange={handleFileUpload}
                label={t('open.info.field.files')}
                type="file"
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                fullWidth
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

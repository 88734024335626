import { BaseApi } from '../../../common/axios';
import { IManagement, IManagementQuery } from '../models';
import { ENDPOINT_BASE_URL } from '../../../common/constants';
import {
  ENDPOINT_MANAGEMENT_FIND_ALL,
  ENDPOINT_MANAGEMENT_FIND_ONE,
  ENDPOINT_MANAGEMENT_CREATE_ONE,
  ENDPOINT_MANAGEMENT_DELETE_ONE,
  ENDPOINT_MANAGEMENT_UPDATE_ONE,
} from '../endpoints';

export class ManagementApi extends BaseApi {
  constructor() {
    super();
    this.baseUrl = ENDPOINT_BASE_URL;
  }

  public findAll(body: IManagementQuery): Promise<IManagement[]> {
    return this.httpClient
      .post(this.url(ENDPOINT_MANAGEMENT_FIND_ALL), body)
      .then((response) => response.data);
  }

  public findOne(body: { id: IManagement['id'] }): Promise<IManagement> {
    return this.httpClient
      .post(this.url(ENDPOINT_MANAGEMENT_FIND_ONE), body)
      .then((response) => response.data);
  }

  public createOne(body: IManagementQuery): Promise<IManagement> {
    return this.httpClient
      .post(this.url(ENDPOINT_MANAGEMENT_CREATE_ONE), body)
      .then((response) => response.data);
  }

  public removeOne(body: { id: IManagement['id'] }): Promise<IManagement> {
    return this.httpClient
      .post(this.url(ENDPOINT_MANAGEMENT_DELETE_ONE), body)
      .then((response) => response.data);
  }

  public updateOne(body: IManagement): Promise<IManagement> {
    return this.httpClient
      .post(this.url(ENDPOINT_MANAGEMENT_UPDATE_ONE), body)
      .then((response) => response.data);
  }
}

import { RouteObject, Outlet } from 'react-router-dom';
import { ROUTE_FAQ, ROUTE_FAQ_CREATE, ROUTE_FAQ_UPDATE } from '../../../common/constants';
import { FAQPage, FAQCreatePage, FAQUpdatePage } from '../../../pages/admin/faq-page';

export const faqRoutes: RouteObject = {
  path: ROUTE_FAQ,
  element: <Outlet />,
  caseSensitive: true,
  children: [
    { index: true, element: <FAQPage />, caseSensitive: true },
    {
      path: ROUTE_FAQ_CREATE,
      element: <FAQCreatePage />,
      caseSensitive: true,
    },
    {
      path: ROUTE_FAQ_UPDATE,
      element: <FAQUpdatePage />,
      caseSensitive: true,
    },
  ],
};

import { FC } from 'react';
import { Stack, Typography } from '@mui/material';

interface RecordRowProps {
  label: string;
  value: string;
}

export const RecordRow: FC<RecordRowProps> = ({ label, value }) => {
  return (
    <Stack direction="row" justifyContent="space-between">
      <Typography variant="body1" component="span" color="secondary.main">
        {label}
      </Typography>
      <Typography variant="body1" component="span" color="secondary.main" fontWeight={500}>
        {value}
      </Typography>
    </Stack>
  );
};

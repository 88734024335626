import { Box, Collapse, styled } from '@mui/material';

export const StyledSidebar = styled(Box)(({ theme }) => ({
  width: 280,
  padding: theme.spacing(2, 1.5),
  color: theme.palette.common.white,
  backgroundColor: theme.palette.secondary.dark,
}));

export const StyledCollapse = styled(Collapse)(({ theme }) => ({
  display: 'block',
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  zIndex: theme.zIndex.drawer,
  backgroundColor: theme.palette.secondary.dark,
  [theme.breakpoints.up('lg')]: {
    position: 'relative',
  },
  '&.MuiCollapse-entered': {
    flex: '0 0 280px',
  },
}));

import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionButton } from '../../../../components/action-button';
import { useEntityDelete } from '../../../entities/hooks';
import { fetchManagementDelete } from '../../thunks';
import { IconDeleteCanOutline } from '../../../../common/icons';
import { ROUTE_MANAGEMENT } from '../../../../common/constants';
import { IManagement } from '../../models';

interface ManagementActionDeleteProps {
  id: IManagement['id'];
}

export const ManagementActionDelete: FC<ManagementActionDeleteProps> = ({ id }) => {
  const { t } = useTranslation();
  const { handleDelete } = useEntityDelete({
    thunk: fetchManagementDelete,
    nextPath: ROUTE_MANAGEMENT,
    confirmationText: t('confirmation.delete.text'),
  });

  return (
    <ActionButton
      onClick={() => handleDelete(id)}
      title={t('action.delete')}
      icon={<IconDeleteCanOutline />}
    />
  );
};

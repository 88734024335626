import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionButton } from '../../../../components/action-button';
import { useEntityDelete } from '../../../entities/hooks';
import { fetchRegionsDelete } from '../../thunks';
import { IconDeleteCanOutline } from '../../../../common/icons';
import { ROUTE_REGIONS } from '../../../../common/constants';
import { IRegions } from '../../models';

interface RegionsActionDeleteProps {
  id: IRegions['id'];
}

export const RegionsActionDelete: FC<RegionsActionDeleteProps> = ({ id }) => {
  const { t } = useTranslation();
  const { handleDelete } = useEntityDelete({
    thunk: fetchRegionsDelete,
    nextPath: ROUTE_REGIONS,
    confirmationText: t('confirmation.delete.text'),
  });

  return (
    <ActionButton
      onClick={() => handleDelete(id)}
      title={t('action.delete')}
      icon={<IconDeleteCanOutline />}
    />
  );
};

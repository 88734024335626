import { AsyncThunk } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../store';
import { useSnackbar } from '../../../common/hooks';
import { get, replaceEmptyStrings } from '../../../common/utils';

interface UseEntityCreate {
  entity: string;
  thunk: AsyncThunk<any, any, any>;
  nextPath?: string;
}

export const useEntityCreate = <T>({ entity, thunk, nextPath }: UseEntityCreate) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { showMessage } = useSnackbar();
  const { isLoading } = useAppSelector((state) => get(state, [entity, 'create'], {}));

  const handleValid = (formValues: T) => {
    const payload = replaceEmptyStrings(formValues);
    dispatch(thunk(payload))
      .unwrap()
      .then(() => {
        if (nextPath) navigate(nextPath);
      })
      .catch((e: any) => {
        const errors = get(e, 'response.data');
        const message = get(errors, 'message', t('error'));
        showMessage(message, { variant: 'filled', severity: 'error' });
      });
  };

  return { isLoading, handleValid };
};

import { LOCALE_CR, LOCALE_EN, LOCALE_RU, LOCALE_UZ } from '../../../common/constants';
import {
  REGIONAL_ADMINISTRATIONS_DIRECTOR,
  REGIONAL_ADMINISTRATIONS_REGION_ID,
  REGIONAL_ADMINISTRATIONS_START_TIME,
  REGIONAL_ADMINISTRATIONS_END_TIME,
  REGIONAL_ADMINISTRATIONS_ADDRESS,
  REGIONAL_ADMINISTRATIONS_EMAIL,
  REGIONAL_ADMINISTRATIONS_PHONE_NUMBER,
} from './regional-administrations.constants';

export const REGIONAL_ADMINISTRATIONS_INITIAL_VALUES = {
  [REGIONAL_ADMINISTRATIONS_DIRECTOR]: {
    [LOCALE_UZ]: '',
    [LOCALE_CR]: '',
    [LOCALE_RU]: '',
    [LOCALE_EN]: '',
  },
  [REGIONAL_ADMINISTRATIONS_REGION_ID]: null,
  [REGIONAL_ADMINISTRATIONS_START_TIME]: null,
  [REGIONAL_ADMINISTRATIONS_END_TIME]: null,
  [REGIONAL_ADMINISTRATIONS_ADDRESS]: {
    [LOCALE_UZ]: '',
    [LOCALE_CR]: '',
    [LOCALE_RU]: '',
    [LOCALE_EN]: '',
  },
  [REGIONAL_ADMINISTRATIONS_EMAIL]: null,
  [REGIONAL_ADMINISTRATIONS_PHONE_NUMBER]: null,
};

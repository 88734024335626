import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { IconMenu } from '../../common/icons';
import { ROUTE_ADMIN } from '../../common/constants';
import { Logo } from '../../common/logo';
import { StyledCollapse, StyledSidebar } from './styled';
import { useSidebar } from '../../common/hooks';
import { MainNav } from '../main-nav';

export const Sidebar: FC = () => {
  const { t } = useTranslation();
  const { collapsed, handleToggle } = useSidebar();

  return (
    <StyledCollapse in={!collapsed} orientation="horizontal">
      <StyledSidebar>
        <Stack spacing={1} direction="row" alignItems="center" justifyContent="flex-end" mb={3}>
          <IconButton onClick={handleToggle} color="inherit">
            <IconMenu />
          </IconButton>
        </Stack>
        <Box textAlign="center" mb={6}>
          <Link to={ROUTE_ADMIN}>
            <Logo width={74} height={98} />
          </Link>
          <Typography
            component="div"
            variant="h2"
            fontWeight={400}
            textTransform="uppercase"
            mt={2}
          >
            {t('site.name')}
          </Typography>
        </Box>
        <Box component="nav">
          <MainNav />
        </Box>
      </StyledSidebar>
    </StyledCollapse>
  );
};

import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { INews } from '../../models';
import { ActionButton } from '../../../../components/action-button';
import { IconEye } from '../../../../common/icons';
import { ROUTE_NEWS_VIEW } from '../../../../common/constants';
import { url } from '../../../../common/utils';

interface NewsActionViewProps {
  id: INews['id'];
}
export const NewsActionView: FC<NewsActionViewProps> = ({ id }) => {
  const { t } = useTranslation();

  return (
    <ActionButton to={url(ROUTE_NEWS_VIEW, { id })} title={t('action.view')} icon={<IconEye />} />
  );
};

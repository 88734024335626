import { RouteObject, Outlet } from 'react-router-dom';
import {
  ROUTE_REGIONAL_ADMINISTRATIONS,
  ROUTE_REGIONAL_ADMINISTRATIONS_CREATE,
  ROUTE_REGIONAL_ADMINISTRATIONS_UPDATE,
} from '../../../common/constants';
import {
  RegionalAdministrationsPage,
  RegionalAdministrationsCreatePage,
  RegionalAdministrationsUpdatePage,
} from '../../../pages/admin/regional-administrations-page';

export const regionalAdministrationsRoutes: RouteObject = {
  path: ROUTE_REGIONAL_ADMINISTRATIONS,
  element: <Outlet />,
  caseSensitive: true,
  children: [
    { index: true, element: <RegionalAdministrationsPage />, caseSensitive: true },
    {
      path: ROUTE_REGIONAL_ADMINISTRATIONS_CREATE,
      element: <RegionalAdministrationsCreatePage />,
      caseSensitive: true,
    },
    {
      path: ROUTE_REGIONAL_ADMINISTRATIONS_UPDATE,
      element: <RegionalAdministrationsUpdatePage />,
      caseSensitive: true,
    },
  ],
};

import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import { RecordRow } from '../../../../components/record-row';
import { OPEN_INFO_TITLE, OPEN_INFO_TYPE } from '../../constants';
import { get } from '../../../../common/utils';
import { IOpenInfo } from '../../models';
import { LOCALE_CR } from '../../../../common/constants';

interface OpenInfoPreviewProps {
  data: IOpenInfo;
}

export const OpenInfoPreview: FC<OpenInfoPreviewProps> = ({ data }) => {
  const { t, i18n } = useTranslation();

  return (
    <Stack spacing={1.5}>
      <Typography variant="subtitle1" component="div">
        {t('open.info')}
      </Typography>
      <RecordRow
        label={t('open.info.field.name')}
        value={
          get(data, [OPEN_INFO_TITLE, i18n.language]) || get(data, [OPEN_INFO_TITLE, LOCALE_CR])
        }
      />
      <RecordRow label={t('open.info.field.type')} value={OPEN_INFO_TYPE} />
    </Stack>
  );
};

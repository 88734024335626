import { FC } from 'react';
import { Box, Button, Menu, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { IconChevronDown, IconChevronUp } from '../../common/icons';
import { useAnchorToggle } from '../../common/hooks';
import { LOCALES, LOCALES_MAP } from '../../common/constants';
import { get } from '../../common/utils';

export const LangSwitcher: FC = () => {
  const { i18n } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { anchorEl, open, handleClose, handleToggle } = useAnchorToggle();

  const handleSelect = (locale: string) => () => {
    const currentLocale = i18n.language;
    const pathLocale = pathname.split('/')[1];

    i18n.changeLanguage(locale).then(() => {
      const newPath =
        pathLocale === currentLocale
          ? pathname.replace(`/${currentLocale}`, `/${locale}`)
          : `/${locale}${pathname}`;
      navigate(newPath);
    });
    handleClose();
  };

  return (
    <Box>
      <Button
        onClick={handleToggle}
        endIcon={open ? <IconChevronUp /> : <IconChevronDown />}
        id="lang-button"
        aria-controls={open ? 'lang-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
      >
        {get(LOCALES_MAP, `${i18n.language}.name`, '')}
      </Button>
      <Menu
        id="lang-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'lang-button',
        }}
      >
        {LOCALES.map((locale) => (
          <MenuItem onClick={handleSelect(locale)} key={locale}>
            {get(LOCALES_MAP, `${locale}.name`, '')}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

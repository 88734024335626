import { createAsyncThunk } from '@reduxjs/toolkit';
import { AccountApi } from '../http/account-api';
import { IAccountPasswordQuery } from '../models';

const accountApi = new AccountApi();

export const fetchAccountUpdatePassword = createAsyncThunk(
  'account/updatePassword',
  async (body: IAccountPasswordQuery, { rejectWithValue }) => {
    try {
      return await accountApi.updateUserPassword(body);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

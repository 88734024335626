import { FC, ReactNode } from 'react';
import { Link, To } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IconButtonProps, Box, Tooltip, IconButton } from '@mui/material';

interface ActionButtonProps extends IconButtonProps {
  title?: string;
  icon: ReactNode;
  to?: To;
}

export const ActionButton: FC<ActionButtonProps> = ({ title, icon, ...props }) => {
  const { t } = useTranslation();

  return (
    <Box display="flex" justifyContent="center">
      <Tooltip
        title={title ? title : t('action')}
        placement="top"
        PopperProps={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -10],
              },
            },
          ],
        }}
        arrow
        key="send"
      >
        <Box component="span">
          <IconButton component={props.to ? Link : 'button'} size="small" {...props}>
            {icon}
          </IconButton>
        </Box>
      </Tooltip>
    </Box>
  );
};

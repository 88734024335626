import { createAsyncThunk } from '@reduxjs/toolkit';
import { UserApi } from '../http';
import { IUser } from '../models';

const userApi = new UserApi();

export const fetchUserUpdate = createAsyncThunk(
  'user/update',
  async (body: IUser, { rejectWithValue }) => {
    try {
      return await userApi.updateOne(body);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

import { BaseApi } from '../../../common/axios';
import { IAccount, IAccountPasswordQuery, IAccountQuery } from '../models';
import { ENDPOINT_BASE_URL } from '../../../common/constants';
import {
  ACCOUNT_ENDPOINT_GET_USER,
  ACCOUNT_ENDPOINT_UPDATE_USER,
  ACCOUNT_ENDPOINT_UPDATE_USER_PASSWORD,
} from '../endpoints/account-endpoints';

export class AccountApi extends BaseApi {
  constructor() {
    super();
    this.baseUrl = ENDPOINT_BASE_URL;
  }

  public updateUser(body: IAccountQuery): Promise<IAccount> {
    return this.httpClient
      .post(this.url(ACCOUNT_ENDPOINT_UPDATE_USER), body)
      .then((response) => response.data);
  }

  public getUser(): Promise<IAccount> {
    return this.httpClient
      .post(this.url(ACCOUNT_ENDPOINT_GET_USER))
      .then((response) => response.data);
  }

  public updateUserPassword(body: IAccountPasswordQuery): Promise<IAccount> {
    return this.httpClient
      .post(this.url(ACCOUNT_ENDPOINT_UPDATE_USER_PASSWORD), body)
      .then((response) => response.data);
  }
}

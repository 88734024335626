import { Box, styled } from '@mui/material';

export const StyledHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  position: 'sticky',
  top: 0,
  zIndex: 2,
  padding: theme.spacing(2.5, 2.5),
  color: theme.palette.secondary.contrastText,
  backgroundColor: theme.palette.secondary.main,
  backgroundImage: 'url(/assets/img/bg-header.svg)',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  [theme.breakpoints.up('xl')]: {
    padding: theme.spacing(2.5, 9),
  },
}));

import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { Box, Button, Container, Stack, Typography } from '@mui/material';
import { ROUTE_HOME } from '../../common/constants';

export const ScreenError404: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Box py={5} textAlign="center" m="auto">
      <Container>
        <Typography variant="h1" color="primary.dark" mb={2.5}>
          {t('error.404')}
        </Typography>
        <Typography variant="body2" mb={5}>
          {t('error.404.text')}
        </Typography>
        <Stack direction="row" alignItems="center" justifyContent="center" spacing={2}>
          <Button color="secondary" variant="contained" component={Link} to={ROUTE_HOME}>
            {t('home.page')}
          </Button>
          <Button onClick={() => navigate(-1)}>{t('action.back')}</Button>
        </Stack>
      </Container>
    </Box>
  );
};

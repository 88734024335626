import { OpenInfoActionDelete, OpenInfoActionEdit, OpenInfoActionView } from '../components';
import { Localize } from '../../../components/localize';
import { IColumn } from '../../../common/models';
import { IOpenInfo } from '../models';
import { OPEN_INFO_TITLE, OPEN_INFO_TYPE } from '../constants';
import { Typography } from '@mui/material';
import { Trans } from 'react-i18next';

export const OPEN_INFO_COLUMNS: IColumn[] = [
  {
    dataKey: OPEN_INFO_TITLE,
    label: 'open.info.title',
    renderItem: ({ title_json }: IOpenInfo) => <Localize data={title_json} />,
  },
  {
    dataKey: OPEN_INFO_TYPE,
    label: 'open.info.type',
    renderItem: ({ type }: IOpenInfo) => (
      <Typography component="div" variant="body2">
        {type === 'OPEN' ? (
          <Trans i18nKey="open.info.open.data" />
        ) : (
          <Trans i18nKey="open.info.needed.docs" />
        )}
      </Typography>
    ),
  },
  {
    dataKey: '',
    label: 'action.edit',
    width: '1%',
    renderItem: ({ id }: IOpenInfo) => <OpenInfoActionEdit id={id} />,
  },
  {
    dataKey: '',
    label: 'action.delete',
    width: '1%',
    renderItem: ({ id }: IOpenInfo) => <OpenInfoActionDelete id={id} />,
  },
  {
    dataKey: '',
    label: 'action.view',
    width: '1%',
    renderItem: (data: IOpenInfo) => <OpenInfoActionView data={data} />,
  },
];

import { FC } from 'react';
import { Box } from '@mui/material';
import { Logo } from '../../common/logo';

export const ScreenOnBoarding: FC = () => {
  return (
    <Box display="flex" flexDirection="column" flex={2} alignItems="center" justifyContent="center">
      <Logo width={128} height={128} />
    </Box>
  );
};

import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { IMenuItem } from '../../../common/models';
import { StyledButton } from './styled';

export const MainNavButton: FC<IMenuItem> = ({ path, label, icon, target }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const linkProps = target
    ? { component: 'a', href: path, target, rel: 'noreferrer' }
    : { component: Link, to: path };

  return (
    <StyledButton
      {...linkProps}
      className={pathname === path ? 'MuiSelected' : null}
      variant="text"
      color="inherit"
    >
      {icon}
      {t(label)}
    </StyledButton>
  );
};

import { FC } from 'react';
import { useLocation, useRoutes } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { localizeRoutes } from './common/utils';
import { routes } from './routes';
import { ScreenError404 } from './components/screens';

export const App: FC = () => {
  const { i18n } = useTranslation();
  const { pathname } = useLocation();

  return useRoutes([
    ...routes(pathname),
    ...localizeRoutes(routes(pathname), i18n.language),
    { path: '*', element: <ScreenError404 /> },
  ]);
};

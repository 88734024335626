import { RouteObject } from 'react-router-dom';
import { ROUTE_ADMIN, ROUTE_HOME, ROUTE_LOGOUT } from '../common/constants';
import { LayoutBase, LayoutCabinet } from '../layouts';
import { HomePage } from '../pages/home-page';
import { LogoutPage } from '../pages/logout-page';
import { NewsPage } from '../pages/admin/news-page';
import { emailRoutes } from '../features/email/routes';
import { newsRoutes } from '../features/news/routes';
import { regionalAdministrationsRoutes } from '../features/regional-administrations/routes';
import { regionsRoutes } from '../features/regions/routes';
import { faqRoutes } from '../features/faq/routes';
import { userRoutes } from '../features/users/routes';
import { openInfoRoutes } from '../features/open-info/routes';
import { managementRoutes } from '../features/management/routes';

export const routes = (pathname: string): RouteObject[] => {
  return [
    {
      path: ROUTE_HOME,
      element: <LayoutBase />,
      caseSensitive: true,
      children: [{ index: true, element: <HomePage />, caseSensitive: true }],
    },
    // cabinet alohida feature qilinganda o'sha feature ichiga olib otish kerak
    {
      path: ROUTE_ADMIN,
      element: <LayoutCabinet />,
      caseSensitive: true,
      children: [
        {
          index: true,
          element: <NewsPage />,
          caseSensitive: true,
        },
        emailRoutes,
        newsRoutes,
        regionalAdministrationsRoutes,
        regionsRoutes,
        managementRoutes,
        faqRoutes,
        userRoutes,
        openInfoRoutes,
      ],
    },

    { path: ROUTE_LOGOUT, element: <LogoutPage /> },
  ];
};

import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Button, Stack } from '@mui/material';
import { useEntityList } from '../../../../features/entities/hooks';
import {
  REGIONAL_ADMINISTRATIONS_COLUMNS,
  fetchRegionalAdministrationsCatalog,
} from '../../../../features/regional-administrations';
import { DataPagination, DataTableCard } from '../../../../components/data';
import { CabinetContainer } from '../../../../components/cabinet-container';
import { PageTitle } from '../../../../components/page-title';
import { ROUTE_REGIONAL_ADMINISTRATIONS_CREATE } from '../../../../common/constants';

export const RegionalAdministrationsPage: FC = () => {
  const { t } = useTranslation();
  const { data, isLoading, page, pagesCount, handleChangePage } = useEntityList({
    entity: 'regionalAdministrations',
    thunk: fetchRegionalAdministrationsCatalog,
    defaultParams: { limit: 14 },
  });

  return (
    <>
      <Helmet>
        <title>{t('regional.administrations')}</title>
      </Helmet>
      <CabinetContainer>
        <PageTitle title={t('regional.administrations.list.title')} mb={3} />
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          alignItems={{ xs: 'flex-start', sm: 'center' }}
          justifyContent={{ xs: 'flex-start', sm: 'flex-end' }}
          spacing={2}
          mb={4}
        >
          <Button
            component={Link}
            to={ROUTE_REGIONAL_ADMINISTRATIONS_CREATE}
            color="primary"
            variant="contained"
          >
            {t('regional.administrations.create')}
          </Button>
        </Stack>
        <DataTableCard
          data={data}
          columns={REGIONAL_ADMINISTRATIONS_COLUMNS}
          isLoading={isLoading}
          page={page}
          showIndex
        />
      </CabinetContainer>
      <DataPagination count={pagesCount} page={page} onChange={handleChangePage} mt={5} container />
    </>
  );
};

import { createAsyncThunk } from '@reduxjs/toolkit';
import { get } from '../../../common/utils';
import { ReportApi } from '../http';

const reportApi = new ReportApi();

export const forceFetchReportByRegion = createAsyncThunk(
  'report/forceFetchByRegion',
  async (_, { rejectWithValue }) => {
    try {
      const data = await reportApi.byRegion();
      return { data };
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const fetchReportByRegion = createAsyncThunk(
  'report/byRegion',
  async (_, { dispatch, getState }) => {
    const { result, isLoading } = get(getState(), 'report.byRegion', {});
    if (result.length > 0 || isLoading) return;
    return await dispatch(forceFetchReportByRegion());
  },
);

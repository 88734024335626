import { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useEntityCreate } from '../../../../features/entities/hooks';
import { EMAIL_INITIAL_VALUES } from '../../../../features/email';
import { fetchEmailCreate } from '../../../../features/email';
import { EmailForms } from '../../../../features/email';
import { IEmail } from '../../../../features/email';
import { CabinetContainer } from '../../../../components/cabinet-container';
import { PageTitle } from '../../../../components/page-title';
import { ROUTE_EMAIL } from '../../../../common/constants';
import sha1 from 'sha1';

export const EmailCreatePage: FC = () => {
  const { t } = useTranslation();
  const { isLoading, handleValid } = useEntityCreate<IEmail>({
    entity: 'email',
    thunk: fetchEmailCreate,
    nextPath: ROUTE_EMAIL,
  });

  const handleSubmit = (fieldValues: IEmail) => {
    const { password } = fieldValues;

    handleValid({
      ...fieldValues,
      password: sha1(password),
    });
  };

  return (
    <>
      <Helmet>
        <title>{t('email.create')}</title>
      </Helmet>
      <CabinetContainer>
        <PageTitle title={t('email.create.title')} mb={3} />
        <EmailForms
          onValid={handleSubmit}
          isLoading={isLoading}
          defaultValues={EMAIL_INITIAL_VALUES}
        />
      </CabinetContainer>
    </>
  );
};

import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IFAQ } from '../../models';
import { FAQPreview } from '../faq-preview';
import { ActionButton } from '../../../../components/action-button';
import { DialogPreview } from '../../../../components/dialogs';
import { useDialog } from '../../../../common/hooks';
import { IconEye } from '../../../../common/icons';

interface FAQActionViewProps {
  data: IFAQ;
}
export const FAQActionView: FC<FAQActionViewProps> = ({ data }) => {
  const { t } = useTranslation();
  const [openPreview] = useDialog(DialogPreview);

  const handlePreview = () => {
    openPreview({
      title: t('faq.form.title'),
      component: <FAQPreview data={data} />,
    });
  };

  return <ActionButton onClick={handlePreview} title={t('action.view')} icon={<IconEye />} />;
};

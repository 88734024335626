import { useTranslation } from 'react-i18next';
import ruLocale from 'date-fns/locale/ru';
import uzLocale from 'date-fns/locale/uz';
import enLocale from 'date-fns/locale/en-US';
import { get } from '../utils';
import { LOCALE_CR, LOCALE_EN, LOCALE_RU, LOCALE_UZ } from '../constants';

const DATEPICKER_LOCALES = {
  [LOCALE_UZ]: uzLocale,
  [LOCALE_CR]: ruLocale,
  [LOCALE_RU]: ruLocale,
  [LOCALE_EN]: enLocale,
};

export const useDateFnsAdapterLocale = () => {
  const { i18n } = useTranslation();
  const adapterLocale: Locale = get(DATEPICKER_LOCALES, i18n.language, ruLocale);

  return { adapterLocale };
};

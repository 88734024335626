import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Box, Button, ListItemIcon, Menu, MenuItem } from '@mui/material';
import { useAnchorToggle } from '../../../../common/hooks';
import { ACCOUNT_MENU } from '../../../../common/menu';
import { get } from '../../../../common/utils';
import { IconChevronDown, IconChevronUp } from '../../../../common/icons';
import { useMe } from '../../../auth/hooks';
import { UserVisa } from '../../../users/components';

interface AccountMenuProps {
  hideNameOnMobile?: boolean;
}

export const AccountMenu: FC<AccountMenuProps> = ({ hideNameOnMobile }) => {
  const { t } = useTranslation();
  const { anchorEl, open, handleClose, handleToggle } = useAnchorToggle();
  const { result } = useMe();

  const firstName = get(result, 'user.first_name');
  const lastName = get(result, 'user.last_name');

  return (
    <Box>
      <Button
        onClick={handleToggle}
        color="inherit"
        sx={{ padding: 0, textAlign: 'left' }}
        endIcon={open ? <IconChevronUp /> : <IconChevronDown />}
      >
        <UserVisa
          title={firstName + ' ' + lastName}
          caption={t('user')}
          hideNameOnMobile={hideNameOnMobile}
        />
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        {ACCOUNT_MENU.map(({ path, icon, label }, idx) => (
          <MenuItem component={Link} to={path} key={idx}>
            <ListItemIcon>{icon}</ListItemIcon>
            {t(label)}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

import { BaseApi } from '../../../common/axios';
import { INews, INewsQuery } from '../models';
import { ENDPOINT_BASE_URL } from '../../../common/constants';
import {
  ENDPOINT_NEWS_FIND_ALL,
  ENDPOINT_NEWS_FIND_ONE,
  ENDPOINT_NEWS_CREATE_ONE,
  ENDPOINT_NEWS_DELETE_ONE,
  ENDPOINT_NEWS_UPDATE_ONE,
} from '../endpoints';

export class NewsApi extends BaseApi {
  constructor() {
    super();
    this.baseUrl = ENDPOINT_BASE_URL;
  }

  public findAll(body: INewsQuery): Promise<INews[]> {
    return this.httpClient
      .post(this.url(ENDPOINT_NEWS_FIND_ALL), body)
      .then((response) => response.data);
  }

  public findOne(body: { id: INews['id'] }): Promise<INews> {
    return this.httpClient
      .post(this.url(ENDPOINT_NEWS_FIND_ONE), body)
      .then((response) => response.data);
  }

  public createOne(body: INewsQuery): Promise<INews> {
    return this.httpClient
      .post(this.url(ENDPOINT_NEWS_CREATE_ONE), body)
      .then((response) => response.data);
  }

  public removeOne(body: { id: INews['id'] }): Promise<INews> {
    return this.httpClient
      .post(this.url(ENDPOINT_NEWS_DELETE_ONE), body)
      .then((response) => response.data);
  }

  public updateOne(body: INews): Promise<INews> {
    return this.httpClient
      .post(this.url(ENDPOINT_NEWS_UPDATE_ONE), body)
      .then((response) => response.data);
  }
}

import { FC } from 'react';
import {
  SelectFieldControllerProps,
  SelectFieldController,
} from '../../../../../components/inputs';
import { useTranslation } from 'react-i18next';

interface SelectNewsControllerProps extends Omit<SelectFieldControllerProps, 'options'> {}

export const SelectNewsController: FC<SelectNewsControllerProps> = ({ type, ...props }) => {
  const { t } = useTranslation();
  const data: { type: string; label: string }[] = [
    {
      type: 'NEWS',
      label: 'news',
    },
    {
      type: 'BREAKING_NEWS',
      label: 'breaking.news',
    },
    {
      type: 'SEMINARS',
      label: 'seminars',
    },
    {
      type: 'GALLERY',
      label: 'gallery',
    },
  ];

  const options = data.map(({ type, label }: any) => ({
    value: type,
    label: t(label),
  }));

  if (!options.length) {
    return <SelectFieldController {...props} options={[]} disabled />;
  }

  return <SelectFieldController {...props} options={options} />;
};

import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Stack, Card, CardContent, Grid, CardHeader } from '@mui/material';
import { useEntityForm } from '../../../entities/hooks';
import { TextFieldController } from '../../../../components/inputs';
import { LOCALE_CR, LOCALE_UZ, LOCALE_RU, LOCALE_EN } from '../../../../common/constants';
import { FAQ_QUESTION, FAQ_ANSWER } from '../../constants';
import { IconClearAll, IconSave } from '../../../../common/icons';
import { IForm } from '../../../../common/models';
import { FAQSchema } from '../../schema';
import { IFAQ } from '../../models';

export const FAQForms: FC<IForm<IFAQ>> = ({ defaultValues, onValid, isLoading }) => {
  const { t } = useTranslation();
  const { control, onReset, onSubmit } = useEntityForm<IFAQ>({
    schema: FAQSchema,
    defaultValues,
    onValid,
  });

  return (
    <Box onSubmit={onSubmit} component="form">
      <Stack direction="row" justifyContent="flex-end" spacing={1.5} mb={2.5}>
        <Button
          type="reset"
          variant="contained"
          color="secondary"
          startIcon={<IconClearAll />}
          disabled={isLoading}
          onClick={onReset}
        >
          {t('action.clear')}
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          startIcon={<IconSave />}
          disabled={isLoading}
        >
          {t('action.save')}
        </Button>
      </Stack>
      <Card>
        <CardHeader title={t('faq.form.title')} />
        <CardContent>
          <Grid container spacing={2.5}>
            <Grid item xs={12} md={6}>
              <TextFieldController
                control={control}
                name={[FAQ_QUESTION, LOCALE_CR].join('.')}
                disabled={isLoading}
                label={t('faq.field.question.cr')}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextFieldController
                control={control}
                name={[FAQ_QUESTION, LOCALE_UZ].join('.')}
                disabled={isLoading}
                label={t('faq.field.question.uz')}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextFieldController
                control={control}
                name={[FAQ_QUESTION, LOCALE_RU].join('.')}
                disabled={isLoading}
                label={t('faq.field.question.ru')}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextFieldController
                control={control}
                name={[FAQ_ANSWER, LOCALE_CR].join('.')}
                disabled={isLoading}
                label={t('faq.field.answer.cr')}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextFieldController
                control={control}
                name={[FAQ_ANSWER, LOCALE_UZ].join('.')}
                disabled={isLoading}
                label={t('faq.field.answer.uz')}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextFieldController
                control={control}
                name={[FAQ_ANSWER, LOCALE_RU].join('.')}
                disabled={isLoading}
                label={t('faq.field.answer.ru')}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextFieldController
                control={control}
                name={[FAQ_ANSWER, LOCALE_EN].join('.')}
                disabled={isLoading}
                label={t('faq.field.answer.en')}
                fullWidth
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

import { createAsyncThunk } from '@reduxjs/toolkit';
import { UserApi } from '../http';
import { IUser } from '../models';

const userApi = new UserApi();

export const fetchUserRecord = createAsyncThunk(
  'users/record',
  async (body: { id: IUser['id'] }, { rejectWithValue }) => {
    try {
      return await userApi.findOne(body);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

import { RouteObject, Outlet } from 'react-router-dom';
import {
  ROUTE_MANAGEMENT,
  ROUTE_MANAGEMENT_CREATE,
  ROUTE_MANAGEMENT_UPDATE,
} from '../../../common/constants';
import {
  ManagementPage,
  ManagementCreatePage,
  ManagementUpdatePage,
} from '../../../pages/admin/management-page';

export const managementRoutes: RouteObject = {
  path: ROUTE_MANAGEMENT,
  element: <Outlet />,
  caseSensitive: true,
  children: [
    { index: true, element: <ManagementPage />, caseSensitive: true },
    {
      path: ROUTE_MANAGEMENT_CREATE,
      element: <ManagementCreatePage />,
      caseSensitive: true,
    },
    {
      path: ROUTE_MANAGEMENT_UPDATE,
      element: <ManagementUpdatePage />,
      caseSensitive: true,
    },
  ],
};

import { createAsyncThunk } from '@reduxjs/toolkit';
import { AccountApi } from '../http/account-api';
import { IAccountQuery } from '../models';

const accountApi = new AccountApi();

export const fetchAccountUpdate = createAsyncThunk(
  'account/update',
  async (body: IAccountQuery, { rejectWithValue }) => {
    try {
      return await accountApi.updateUser(body);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

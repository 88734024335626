import {
  EMAIL_FIELD_FULL_NAME,
  EMAIL_FIELD_EMAIL,
  EMAIL_FIELD_PHONE,
  EMAIL_FIELD_PASSWORD,
} from './email.constants';

export const EMAIL_INITIAL_VALUES = {
  [EMAIL_FIELD_FULL_NAME]: '',
  [EMAIL_FIELD_EMAIL]: '',
  [EMAIL_FIELD_PHONE]: '',
  [EMAIL_FIELD_PASSWORD]: '',
};

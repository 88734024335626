import { createAsyncThunk } from '@reduxjs/toolkit';
import { EmailApi } from '../http';

const emailApi = new EmailApi();

export const fetchEmailDelete = createAsyncThunk(
  'email/delete',
  async (id: string, { rejectWithValue }) => {
    try {
      return await emailApi.removeOne({ id });
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

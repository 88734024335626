import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Card, CardContent, CardHeader, Grid, Stack } from '@mui/material';
import { useEntityForm } from '../../../entities/hooks';
import { IconClearAll, IconSave } from '../../../../common/icons';
import { TextFieldController } from '../../../../components/inputs';
import { IUser } from '../../models';
import { IForm } from '../../../../common/models';
import { UserSchema } from '../../schema';
import {
  USER_FIELD_FIRST_NAME,
  USER_FIELD_LAST_NAME,
  USER_FIELD_MIDDLE_NAME,
  USER_FIELD_USER_NAME,
  USER_FIELD_PASSWORD,
} from '../../constants';

export const UserForm: FC<IForm<IUser>> = ({ defaultValues, onValid, isLoading }) => {
  const { t } = useTranslation();
  const { control, onSubmit, onReset } = useEntityForm<IUser>({
    schema: UserSchema,
    defaultValues,
    onValid,
  });

  return (
    <Box onSubmit={onSubmit} component="form">
      <Stack direction="row" justifyContent="flex-end" spacing={1.5} mb={2.5}>
        <Button
          type="reset"
          variant="contained"
          color="secondary"
          onClick={onReset}
          startIcon={<IconClearAll />}
          disabled={isLoading}
        >
          {t('action.clear')}
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          startIcon={<IconSave />}
          disabled={isLoading}
        >
          {t('action.save')}
        </Button>
      </Stack>
      <Box component={Card} mb={3}>
        <CardHeader title={t('users.form.title')} />
        <CardContent>
          <Grid container spacing={2.5} mb={2.5}></Grid>
          <Grid container spacing={2.5} mb={2.5}>
            <Grid item xs={12} md={4}>
              <TextFieldController
                control={control}
                name={USER_FIELD_LAST_NAME}
                disabled={isLoading}
                label={t('users.field.last.name')}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextFieldController
                control={control}
                name={USER_FIELD_FIRST_NAME}
                disabled={isLoading}
                label={t('users.field.first.name')}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextFieldController
                control={control}
                name={USER_FIELD_MIDDLE_NAME}
                disabled={isLoading}
                label={t('users.field.middle.name')}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container spacing={2.5}>
            <Grid item xs={12} md={4}>
              <TextFieldController
                control={control}
                name={USER_FIELD_USER_NAME}
                disabled={isLoading}
                label={t('users.field.user.name')}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextFieldController
                control={control}
                name={USER_FIELD_PASSWORD}
                disabled={isLoading}
                label={t('users.field.password')}
                type="password"
                fullWidth
              />
            </Grid>
          </Grid>
        </CardContent>
      </Box>
    </Box>
  );
};

import { BaseApi } from '../../../common/axios';
import { IFAQ, IFAQQuery } from '../models';
import { ENDPOINT_BASE_URL } from '../../../common/constants';
import {
  ENDPOINT_FAQ_FIND_ALL,
  ENDPOINT_FAQ_FIND_ONE,
  ENDPOINT_FAQ_CREATE_ONE,
  ENDPOINT_FAQ_DELETE_ONE,
  ENDPOINT_FAQ_UPDATE_ONE,
} from '../endpoints';

export class FAQApi extends BaseApi {
  constructor() {
    super();
    this.baseUrl = ENDPOINT_BASE_URL;
  }

  public findAll(body: IFAQQuery): Promise<IFAQ[]> {
    return this.httpClient
      .post(this.url(ENDPOINT_FAQ_FIND_ALL), body)
      .then((response) => response.data);
  }

  public findOne(body: { id: IFAQ['id'] }): Promise<IFAQ> {
    return this.httpClient
      .post(this.url(ENDPOINT_FAQ_FIND_ONE), body)
      .then((response) => response.data);
  }

  public createOne(body: IFAQQuery): Promise<IFAQ> {
    return this.httpClient
      .post(this.url(ENDPOINT_FAQ_CREATE_ONE), body)
      .then((response) => response.data);
  }

  public removeOne(body: { id: IFAQ['id'] }): Promise<IFAQ> {
    return this.httpClient
      .post(this.url(ENDPOINT_FAQ_DELETE_ONE), body)
      .then((response) => response.data);
  }

  public updateOne(body: IFAQ): Promise<IFAQ> {
    return this.httpClient
      .post(this.url(ENDPOINT_FAQ_UPDATE_ONE), body)
      .then((response) => response.data);
  }
}

export const formatNumber = (value: number, separator: string = ' '): string => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);
};

// replace all empty strings with null recursively and return the result
export const replaceEmptyStrings = (obj: any): any => {
  if (obj === null || obj === undefined) {
    return obj;
  }
  if (typeof obj === 'string') {
    return obj === '' ? null : obj;
  }
  if (Array.isArray(obj)) {
    return obj.map((item) => replaceEmptyStrings(item));
  }
  if (typeof obj === 'object') {
    return Object.keys(obj).reduce((acc: Record<string, any>, key) => {
      acc[key] = replaceEmptyStrings(obj[key]);
      return acc;
    }, {});
  }
  return obj;
};

// replace all null with empty strings recursively and return the result
export const replaceNulls = (obj: any): any => {
  if (obj === null || obj === undefined) {
    return '';
  }
  if (typeof obj === 'string') {
    return obj;
  }
  if (Array.isArray(obj)) {
    return obj.map((item) => replaceNulls(item));
  }
  if (typeof obj === 'object') {
    return Object.keys(obj).reduce((acc: Record<string, any>, key) => {
      acc[key] = replaceNulls(obj[key]);
      return acc;
    }, {});
  }
  return obj;
};

// replace all empty arrays with null recursively and return the result
export const replaceEmptyArrays = (obj: any): any => {
  if (obj === null || obj === undefined) {
    return obj;
  }
  if (typeof obj === 'string') {
    return obj;
  }
  if (Array.isArray(obj)) {
    return obj.length === 0 ? null : obj.map((item) => replaceEmptyArrays(item));
  }
  if (typeof obj === 'object') {
    return Object.keys(obj).reduce((acc: Record<string, any>, key) => {
      acc[key] = replaceEmptyArrays(obj[key]);
      return acc;
    }, {});
  }
  return obj;
};

// convert boolean values to string
export const convertBooleansToStrings = (obj: any): any => {
  if (obj === null || obj === undefined) {
    return obj;
  }
  if (typeof obj === 'string') {
    return obj;
  }
  if (Array.isArray(obj)) {
    return obj.map((item) => convertBooleansToStrings(item));
  }
  if (typeof obj === 'object') {
    return Object.keys(obj).reduce((acc: Record<string, any>, key) => {
      acc[key] = convertBooleansToStrings(obj[key]);
      return acc;
    }, {});
  }
  return typeof obj === 'boolean' ? obj.toString() : obj;
};

import { AsyncThunk } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch } from '../../../store';
import { DialogDeleteConfirm } from '../../../components/dialogs';
import { useDialog } from '../../../common/hooks';
import { get } from '../../../common/utils';

interface UseEntityDeleteProps {
  thunk: AsyncThunk<any, any, any>;
  nextPath?: string;
  confirmationText?: string;
}

export const useEntityDelete = ({ thunk, nextPath, confirmationText }: UseEntityDeleteProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const [openConfirmation] = useDialog(DialogDeleteConfirm);

  const filterParams = Object.fromEntries(searchParams);
  const page = get(filterParams, 'page', 0);

  const handleDelete = (id?: string) => {
    if (!id) return;
    openConfirmation({
      title: t('confirmation'),
      text: confirmationText ? confirmationText : t('confirmation.text'),
      resolve: () => {
        dispatch(thunk(id))
          .unwrap()
          .then(() => {
            if (nextPath) {
              navigate({
                pathname: nextPath,
                search: page === '1' ? '' : createSearchParams({ page: '1' }).toString(),
              });
            }
          });
      },
    });
  };

  return { handleDelete };
};

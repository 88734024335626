import { createAsyncThunk } from '@reduxjs/toolkit';
import { EmailApi } from '../http';
import { IEmail } from '../models';

const emailApi = new EmailApi();

export const fetchEmailUpdate = createAsyncThunk(
  'email/update',
  async (body: IEmail, { rejectWithValue }) => {
    try {
      return await emailApi.updateOne(body);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

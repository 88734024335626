import { Shadows } from '@mui/material/styles/shadows';

const DEFAULT_SHADOW = '0px 2px 6px rgba(15, 23, 42, 0.06)';

export const shadows: Shadows = [
  'none',
  DEFAULT_SHADOW, // 0 Card Shadow
  'none', // 1
  'none', // 2
  'none', // 3
  'none', // 4
  'none', // 5
  'none', // 6
  DEFAULT_SHADOW, // Dropdown Shadow
  'none', // 8
  'none', // 9
  'none', // 10
  'none', // 11
  'none', // 12
  'none', // 13
  'none', // 14
  'none', // 15
  'none', // 16
  'none', // 17
  'none', // 18
  'none', // 19
  'none', // 20
  'none', // 21
  'none', // 23
  DEFAULT_SHADOW, // 24 PopUp Shadow
];

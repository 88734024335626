import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IRegionalAdministrations } from '../../models';
import { RegionalAdministrationsPreview } from '../regional-administrations-preview';
import { ActionButton } from '../../../../components/action-button';
import { DialogPreview } from '../../../../components/dialogs';
import { useDialog } from '../../../../common/hooks';
import { IconEye } from '../../../../common/icons';

interface RegionalAdministrationsActionViewProps {
  data: IRegionalAdministrations;
}
export const RegionalAdministrationsActionView: FC<RegionalAdministrationsActionViewProps> = ({
  data,
}) => {
  const { t } = useTranslation();
  const [openPreview] = useDialog(DialogPreview);

  const handlePreview = () => {
    openPreview({
      title: t('regions.form.title'),
      component: <RegionalAdministrationsPreview data={data} />,
    });
  };

  return <ActionButton onClick={handlePreview} title={t('action.view')} icon={<IconEye />} />;
};

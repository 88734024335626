import { FC } from 'react';
import { Box, Card, CardContent, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const LogoutProcess: FC = () => {
  const { t } = useTranslation();

  return (
    <Box component={Card} m="auto">
      <CardContent>
        <Typography component="div" variant="subtitle1">
          {t('logout.text')}
        </Typography>
      </CardContent>
    </Box>
  );
};

import { FC } from 'react';
import { StyledHeader } from './styled';
import { Box, IconButton, Stack } from '@mui/material';
import { useSidebar } from '../../../common/hooks';
import { IconMenu, IconFullScreen } from '../../../common/icons';
import { LangSwitcher } from '../../lang-switcher';
import { AccountMenu } from '../../../features/account/components';

export const HeaderDefault: FC = () => {
  const { collapsed, handleToggle } = useSidebar();

  function toggleFullScreen() {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  }

  return (
    <StyledHeader component="header">
      <Stack direction="row" justifyContent="space-between" spacing={3}>
        <Box mr="auto">
          {collapsed && (
            <IconButton onClick={handleToggle} color="inherit">
              <IconMenu />
            </IconButton>
          )}
        </Box>
        <Stack direction="row" spacing={3}>
          <IconButton onClick={toggleFullScreen}>
            <IconFullScreen />
          </IconButton>
          <LangSwitcher />
          <AccountMenu hideNameOnMobile />
        </Stack>
      </Stack>
    </StyledHeader>
  );
};
